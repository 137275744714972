.JoinReferralProgramPage {
    .card {
        box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
        border: 1px solid #eeeeee;
        border-radius: 5px;
        margin-bottom: 40px;
        .card-body {
            .row {
                .card-title {
                    margin: 8px 0 8px;
                }
                .join-now {
                    .loader {
                        display: inline-block;
                        margin-right: 8px;
                        animation: spin 2s linear infinite;
                        font-family: LinearIcons, serif;
                        &::before {
                            box-sizing: border-box;
                            content: "\e8d0"
                        }
                    }
                    &:hover {
                        background-color: #03A9F4;
                        border-color: #03A9F4;
                        box-shadow: 5.7px 5.7px 16px 0 rgba(0, 0, 0, 0.1);
                        transition: box-shadow .15s ease-in-out;

                    }
                }

            }
            .error {
                color: #dc3545;
            }
        }
    }
}