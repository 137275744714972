.SearchItemsPage{
    position: relative;
    height: 100%;
}
.TableListItems {
    padding-top: 140px;
    height: calc(100% - 75px);
    overflow: hidden;

    .container{
        position: relative;
        height: 100%;
        overflow-y: auto;
    }

    &.list {
        .TableItemsWrapper .ProductInfo {
            & > a {
                display: block;
                margin-bottom: 7px;
            }
        }
    }

    .TableItemsWrapper {
        width: 100%;
        padding: 0;
        border-collapse: collapse;
        text-align: center;
        list-style: none;
        margin-bottom: 40px;

        &.row {
            margin-left: -9px;
            margin-right: -9px;
        }

        .TableHead {
            background-color: #f2fbff;
            color: #808080;
            height: 40px;
            line-height: 40px;
            font-size: 1rem;

            &.head-sticky{
                .TableCell{
                    background-color: #f56400;
                    position: sticky;
                    top: 0px;
                    z-index: 1;
                    color: #fff;
                    transition: all .3s ease 0s;
                }
            }

            .TableCell {
                font-weight: 500;
                padding: 0 10px;
            }
        }

        .TableBody {
            padding-top: 15px;
            border-style: solid;
            border-color: #f4f4f4;
            border-width: 0 1px 0 1px;
            transition: background-color .3s;

            &:last-child {
                border-width: 0 1px 1px 1px;
            }

            &:nth-child(2n + 1) {
                background-color: #fafafa;
            }

            &:hover {
                background-color: rgba(0, 0, 0, .075);
            }
        }

        .TableCell {
            border: unset;
            vertical-align: top;
            padding: 15px 10px;
            text-align: left;

            &:first-child {
                font-size: 0.9375rem;
            }

            a{
                padding: 0;
                color: #000000;
                margin-right: 10px;
                text-decoration: none;
                transition: all 0.3s ease 0s;

                &:hover{
                    color: #f56400;
                }
            }
        }

        // Grid layout
        &.grid {
            margin-top: 18px;
            width: auto;

            .TableHead {
                display: none !important;
            }
        }

        &.tableShopWrapper{
            display: table;
        }
    }
}