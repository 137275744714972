.HighlightedText {
    display: inline-block;

    &.clickable {
        cursor: pointer;
    }

    span {
        color: #ff443e;

        &.noTrade {
            color: #37af56;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}