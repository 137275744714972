.PopupSimilarProducts {
    .modal-content {
        .modal-body {
            position: relative;
            display: block;
            width: 100%;

            .Close {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;
                color: #aaa;
                transition: all 200ms ease-out;
                padding: 5px 10px;

                &:hover {
                    color: #666;
                }
            }
        }
    }
}

@media all and (min-width: 480px) {
    .PopupSimilarProducts {

        &.modal-dialog {
            max-width: 90%;
        }
    }
}