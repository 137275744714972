.ProductReviews {
    display: flex;
    align-items: center;

    .Icon {
        line-height: 1;
        position: relative;
        top: -1px;
        margin-right: 3px;
    }

    .Star {
        font-size: 14px;
        line-height: 1;
    }

    .Number {
        color: #595959;
        font-size: 12px;
        line-height: 1.6;
        font-weight: 300;
    }
}