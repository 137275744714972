.EventActions {
    background-color: #fff;
    color: #333;
    position: relative;
    z-index: 10;
    padding: 10px 10px;

    .ShowDetails {
        cursor: pointer;
    }

    .TimeLeft {
        position: absolute;
        top: -37px;
        right: 0;
        background-color: #444;
        color: #fff;
        padding: 5px 10px;
        font-size: 0.875rem;
        line-height: 27px;

        .Icon {
            margin-right: 7px;
            position: relative;
            top: 1px;
        }
    }

    .Area {
        .Icon {
            margin-right: 2px;
        }
    }
}