.PaymentMethods {
    background-color: #fff;
    padding: 1.25rem;
    border-radius: 5px;
    box-shadow: rgba(67, 90, 111, 0.416) 0 0 1px, rgba(67, 90, 111, 0.3) 0 2px 4px -2px;

    .HeaderGateway {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        label {
            cursor: pointer;
            margin: 0;
            font-size: 1.25rem;
        }

        .InputMethod {
            margin-right: 10px;
            cursor: pointer;
            position: relative;
            top: -3px;
        }
    }

    .Name {
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }

    > * {
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}