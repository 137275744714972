.StatisticOverview {
    position: relative;

    .card {
        box-shadow: none;
        border: 1px solid #f4f4f4;
        overflow: hidden;
    }

    .card-body {
        padding: 0;
    }

    .ShowDays {
        padding: 0;
        justify-content: center;
    }

    .nav-tabs {
        background-color: #f2fbff;
        height: 60px;
        line-height: 60px;
        border-bottom: 0;
        margin-bottom: 30px;

        .nav-item {
            .nav-link {
                padding: 0 20px;
                color: #999;
                font-weight: 500;
                cursor: pointer;

                &.active {
                    color: #333;
                    border-width: 0 1px;
                    border-color: #f4f4f4;
                }
            }
        }
    }

    .card-footer {
        background-color: #fff;
        border-top: none;
        padding: 24px 0 40px;
    }
}

.StatisticProduct {
    margin-bottom: 40px;
}

@media all and (max-width: 480px) {
    .StatisticOverview {
        margin-bottom: 30px;
    }
}