.PageNotFound {
    margin: 100px auto;

    .Images {
        .Number {
            color: #03A9F4;
            font-size: 210px;
            line-height: 1;
        }
    
        .Icon {
            width: 150px;
            height: auto;
            padding: 10px 20px;
        }
    }
}