.EventItem {
    margin-bottom: 30px;
    transition: all 200ms ease-out;
    position: relative;

    .FlipContainer {
        -webkit-perspective: 1000;
        -moz-perspective: 1000;
        -ms-perspective: 1000;
        perspective: 1000;
        -ms-transform: perspective(1000px);
        -moz-transform: perspective(1000px);
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;

        &.open {
            .Front {
                -webkit-transform: rotateY(180deg);
                -moz-transform: rotateY(180deg);
                -o-transform: rotateY(180deg);
                transform: rotateY(180deg);
            }

            .Back {
                -webkit-transform: rotateY(0deg);
                -moz-transform: rotateY(0deg);
                -o-transform: rotateY(0deg);
                -ms-transform: rotateY(0deg);
                transform: rotateY(0deg);
            }
        }
    }

    .Flipper {
        -webkit-transition: 0.6s;
        -webkit-transform-style: preserve-3d;
        -ms-transition: 0.6s;
        -moz-transition: 0.6s;
        -moz-transform: perspective(1000px);
        -moz-transform-style: preserve-3d;
        -ms-transform-style: preserve-3d;
        transition: 0.6s;
        transform-style: preserve-3d;
        position: relative;
        height: 100%;
    }

    .Front, .Back {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: 0.6s;
        -webkit-transform-style: preserve-3d;
        -webkit-transform: rotateY(0deg);
        -moz-transition: 0.6s;
        -moz-transform-style: preserve-3d;
        -moz-transform: rotateY(0deg);
        -o-transition: 0.6s;
        -o-transform-style: preserve-3d;
        -o-transform: rotateY(0deg);
        -ms-transition: 0.6s;
        -ms-transform-style: preserve-3d;
        -ms-transform: rotateY(0deg);
        transition: 0.6s;
        transform-style: preserve-3d;
        transform: rotateY(0deg);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .Front {
        -webkit-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        background: lightgreen;
        z-index: 2;
    }

    .Back {
        background: lightblue;
        -webkit-transform: rotateY(-180deg);
        -moz-transform: rotateY(-180deg);
        -o-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }

    &.inactive {
        opacity: 0.5;

        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
        }
    }

    .Details {
        position: relative;
        border: 1px solid #eee;
        height: 100%;
        transition: all 200ms ease-out;

        &:hover {
            box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
        }

        .Preview {
            height: 100%;
            position: relative;
            z-index: 2;
            background: transparent center center no-repeat;
            transform: translateZ(0);
            will-change: transform, opacity;
            background-size: cover;
            color: #fff;
            min-height: 300px;
            max-width: 100%;

            &:before {
                background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.5) 80%) repeat-x;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC111111', endColorstr='#00111111', GradientType=0);
                padding: 1em;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                z-index: 1;
            }
        }

        /*&:before {
            background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.5) 80%) repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC111111', endColorstr='#00111111', GradientType=0);
            padding: 1em;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;
        }*/

        .Front .Name {
            font-size: 35px;
            position: relative;
            z-index: 2;
            min-height: 100px;
            padding: 20px;
        }
    }
}

@media screen and (max-width: 480px) {
    body .EventItem {
        .Details {
            height: 300px;
            margin-bottom: 20px;
        }
    }
}