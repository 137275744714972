.FilterType {
    position: relative;

    & > i {
        position: absolute;
        left: -2px;
        top: 9px;
    }

    .SelectWrapper {
        padding-right: 33px;
        margin-left: -30px;

        optgroup {
            color: #cecece;
        }

        option {
            color: #808080;
        }
    }

    select {
        text-indent: 33px;
        padding-left: 0;
        width: calc(100% + 33px);
    }

    &.isActive {
        i {
            color: #fff;
        }
        .SelectWrapper {
            background-color: $tamz_primary_color;
            border-radius: 5px;

            select {
                background-color: transparent;
                color: #fff;
                border: none;

                //optgroup {
                //    color: #cecece;
                //}
                //
                //option {
                //    color: #808080;
                //}
            }
        }
    }
}
