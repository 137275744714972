.FormCreateTrademark {
    margin-right: 20px;
    display: inline-block;
    min-width: 295px;

    input {
        border-radius: 5px;
        border: 1px solid #eee;
        font-size: 15px;
        padding: 6px 20px;
        line-height: 21px;

        &::placeholder {
            color: #aaa;
        }
    }

    .Add {
        content: '';
        width: 39px;
        height: 100%;
        background-color: #03a9f4;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border: none;
        outline: none;

        &:disabled {
            background-color: #aaa;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 100%;
            height: 100%;
            background: url("/assets/icons/add-tm.svg") no-repeat center center;
            z-index: 9;
        }
    }

    form {
        max-width: 500px;
        position: relative;
    }
}