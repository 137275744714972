@import "bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import '../../node_modules/react-tippy/dist/tippy.css';
@import './mixins/index';
@import "variables";
@import "typography";
@import "share-components/index";
@import "app";
@import "dashboard";
@import "./settings/index";
@import "general";
