.ListCategoryFavorites {
    min-height: 300px;
    max-height: 430px;
    overflow-y: auto;
    padding: 20px;

    .FormSearchCategories {
        margin-bottom: 20px;

        .Name {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
            line-height: 24px;
            height: auto;
        }

        .BtnCreate {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            line-height: 24px;
        }

    }

    .Categories {
        list-style: none;
        padding: 0;

        .Category {
            transition: all 200ms ease-out;

            &:hover {
                color: $tamz_primary_color;
            }

            .Left {
                cursor: pointer;
                padding: 5px 0;
            }
        }
    }
}