$primary-text-color: #303238;
$secondary-text-color: #65676b;
$third-text-color: #005bbb;
$white-text-color: #ffffff;
$color-text-hover: #f42800;

.SidebarDashboard {
    width: 280px;
    background-color: #fff;
    border: 1px solid #eee;
    height: 100%;
    display: flex !important;
    flex-wrap: wrap;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    @include transition(all .3s);

    .NavTrademark {
        position: relative;

        .TMTotalWarnings {
            position: absolute;
            top: 10px;
            right: 10px;
            background-color: #ff443e;
            color: #fff;
            font-size: 10px;
            font-weight: 500;
            line-height: 15px;
            padding: 0 5px;
            border-radius: 5px;
        }
    }
    .SideBar {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .SideBarTop {
        .SidebarItem {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          height: 48px;
          text-decoration: none;
          cursor: pointer;
          color: inherit;
          img {
            &:nth-child(1) {
              display: block;
            }
            &:nth-child(2) {
              display: none;
            }
          }
          p {
            display: none;
            margin: 0;
            font-size: 16px;
            font-weight: 500;
          }
          &.Active {
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 100%;
              top: 0;
              left: 0;
              background-color: #005bbb;
              border-radius: 0 8px 8px 0;
            }
            img {
              &:nth-child(1) {
                display: none !important;
              }
              &:nth-child(2) {
                display: block !important;
              }
            }
            p {
              color: $third-text-color;
            }
          }
          &.Open {
            justify-content: flex-start;
            img {
              margin-left: 20px;
            }
            p {
              display: block;
            }
          }
          &:hover {
            &::before {
              content: "";
              position: absolute;
              width: 4px;
              height: 100%;
              top: 0;
              left: 0;
              background-color: #005bbb;
              border-radius: 0 8px 8px 0;
            }
            img {
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                display: block;
              }
            }
            p {
              color: $third-text-color;
            }
          }
        }
        .Extras {
          margin-top: 48px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;
          height: 48px;
          text-decoration: none;
          color: inherit;

          p {
            margin: 0 0 0 10px;
            color: $color-text-hover;
            font-size: 14px;
            font-weight: 700;
          }

          &.Open {
            justify-content: flex-start;
          }
          &.false{
            text-align: center;
            
          p{
          margin:0;}
          }
        }
      }
      .SideBarBottom {
        .NewFeature {
          position: relative;
          width: 48px;
          height: 48px;
          margin: 0 auto;
          padding: 8px;

          border-radius: 8px;
          display: flex;
          justify-content: center;
          background: linear-gradient(135deg, #f3dfd1 0%, #cbdafe 100%);
          &.Active {
            width: 232px;
            height: fit-content;
            justify-content: flex-start;
            flex-direction: column;
            padding: 16px 0px 0px 16px;
            padding-bottom: 50px;
            gap: 16px;
            p {
              display: inline;
            }
            .BtnInclude {
              display: flex;
            }
          }

          img {
            width: 32px;
            height: 32px;
          }
          p {
            display: none;
            font-size: 14px;
            font-weight: 500;
            margin: 0;
            span {
              color: $color-text-hover;
            }
          }
          .BtnInclude {
            display: none;
            position: absolute;
            bottom: 0;
            right: 0;
            justify-content: flex-end;
            a {
              text-align: center;
              width: 130px;
              height: 34px;
              background-color: #005bbb;
              border-radius: 8px 0px 4px 0px;
              display: flex;
              align-items: flex-start;
              padding: 5px 10px;
              margin: 0 auto;
              gap: 10px;
              font-size: 14px;
              font-weight: 400;
              color: white;
              justify-content: center;
              text-decoration: underline;
            }
          }
        }
        .LimitedOffer{
          position: fixed;
          right: 0;
          bottom:80px;
          display:flex;
          align-items: center;
          margin-left: 0;
          justify-content: center;
          background-color:none;
         .LimitedTimeOfferButton{
          img{
            width: 100px;
            height: 100px;
          }
         }
          
          &.Active{
            justify-content: flex-start;
            margin-left: 30px;
          }
          .LimitedTimeOfferButton{
            background-color: none;
          }
        }
        .GoOther {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 20px;
          margin-top:50px;
          margin-bottom: 24px;
          height: max-content;
          &.Active {
            flex-direction: row;
            justify-content: flex-start;
          }
          .GoOtherItem {
            color: $primary-text-color;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            transition: all 0.2s ease;
            position: relative;

            a {
              text-decoration: none;
              color: inherit;
              &:hover {
                color: $third-text-color;
              }
            }
            .ToolInfo {
              display: none;
              background-color: white;
              padding: 10px;
              border-radius: 8px;
              position: absolute;
              z-index: 999;
              right: -380px;
              top: -260px;
              height: fit-content;
              width: 400px;
              box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
              &.spyamz {
                top: -280px;
              }
              a {
                color: $third-text-color;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
            &:hover {
              .ToolInfo {
                display: block;
              }
            }
          }
        }
      }
    }
    .OtherApps {
      width: 100%;
      margin: auto;
      .nav{
        padding: 0;
        .nav-item.Logo{
          &.spybadass{
            background: url('/assets/images/spyads.png') no-repeat center center;
            background-size: 30%;
          }
          &.spyamz{
            background: url('/assets/images/spyamz.png') no-repeat center center;
            background-size: 30%;
          }
        }
      }
    }

    .nav {
        font-size: 1.125rem;
        width: 100%;
        padding: 0 10px;
        flex-wrap: wrap;
        align-content: flex-start;

        .Copyright {
            font-size: 1rem;
            text-align: center;
            width: 100%;
        }

        .Logo {
            background: url('/assets/images/logo.png') no-repeat center center;
            margin-bottom: 50px;
            margin-top: 30px;


            .nav-link {
                display: inline-block;
                height: 70px;
                width: 100%;

                &:not(:first-child):hover {
                    background-color: transparent;
                }
            }
        }

        .nav-item {
            width: 100%;

            &:not(.Logo) {
                height: 55px;
                line-height: 55px;
            }

            &:not(.Logo) {
                margin-bottom: 10px;
            }

            &:not(:first-child) {
                .nav-link {
                    &:hover {
                        background-color: $tamz_primary_color;
                        color: #fff;
                        box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08);
                    }
                }
            }

          &.spybadass.Logo, &.spyamz.Logo {
            background-size: 73px 40px;
            margin-top: 0;
            margin-bottom: 10px;
            position: relative;
            a {
              &:hover {
                background: transparent;
                box-shadow: none;
              }
            }
            &:hover{
              .spybadass, .spyamz {
                display: block;
              }
            }
            .ToolInfo {
              display: none;
              font-size: 14px;
              padding: 20px;
              position: absolute;
              width: 500px;
              left: 279px;
              bottom: -15px;
              height: auto;
              z-index: 9999;
              background: #fff;
              .Title{
                padding-bottom: 10px;
                border-bottom: 1px solid #eee;
                .Name {
                  &.spyamz{
                    color: #03a9f4;
                  }
                  &.spybadass{
                    color: #4267b2;
                  }
                }
                .SubTitle {
                  margin-bottom: 0;
                }
              }
              .Info {
                margin-top: 10px;
                .Description {
                  margin-bottom: 5px;
                }
              }
            }
          }
        }

        .nav-link {
            position: relative;
            padding: 0 10px 0 44px;
            transition: background-color .3s;
            color: $tamz_primary_color;

            & > i {
                position: absolute;
                top: 12px;
                font-size: 1.6rem;
            }

            &.active {
                box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08);
                background-color: $tamz_primary_color;
                color: #fff;

                .Text {
                    font-weight: 400;
                }
            }
        }

        .Text {
            margin-left: 44px;
            transition: all .3s;
        }

        .CollapseMenu {
            cursor: pointer;

            .Open {
                display: none;
            }
        }
    }

    &.collapsed {
        .nav {
            padding: 0 10px;
        }

        .OtherApps{
          width: 100px;
          .nav {
            .nav-item.Logo {
              &.spyamz, &.spybadass {
                background-size: 70%;
                .ToolInfo {
                  left: 99px;
                }
              }
            }
          }
        }

        .UserMenu {
            padding: 0 0 30px 0;
        }
    }
}

@media all and (max-width: 1440px) {
    .SidebarDashboard {
        .nav {
            .Logo {
                margin-bottom: 20px;
                margin-top: 20px;
            }

            .Copyright {
                display: none;
            }

            .nav-item {
                &:not(.Logo) {
                    line-height: 45px;
                    height: auto;
                }
            }
        }
    }
}

@media all and (max-width: 480px) {
    .Dashboard {
        .SidebarDashboard {
            display: none!important;
        }
    }
}