@import url('https://fonts.googleapis.com/css?family=Baloo+Tamma');

@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.OptInEmail {
    max-width: 600px;

    .modal-content {
        background: url("/assets/images/optin-email.jpg") no-repeat center;
        background-size: cover;
        border: none;
        min-height: 400px;
        min-width: 400px;

        .Close {
            position: absolute;
            top: 0;
            right: 0;
            color: #ddd;
            cursor: pointer;
            font-size: 1.2rem;
            padding: 4px 6px;
            line-height: 1.2rem;
            transition: all 200ms ease-out;
            z-index: 9;

            &:hover {
                color: #fff;
            }
        }

        .modal-body {
            color: #fff;
            text-transform: uppercase;
            text-align: right;
            padding: 50px 20px 80px 20px;

            .Title {
                font-family: 'Baloo Tamma', cursive;
                font-size: 4rem;
                margin: 0 0 1rem;
                line-height: 3rem;
            }

            .SubTitle {
                font-size: 2rem;
                white-space: pre-line;
            }

            .Description {
                font-size: 1rem;
            }

            .Arrow {
                position: relative;
                margin-top: 20px;
                height: 60px;

                &:before {
                    content: '';
                    display: inline-block;
                    background: url("/assets/images/optin-arrow.png") no-repeat top left;
                    background-size: cover;
                    position: absolute;
                    bottom: 5px;
                    right: 60px;
                    width: 70px;
                    height: 46px;
                    animation: MoveUpDown 1s linear infinite;
                }
            }

            .BtnSubscribe {
                color: #fff;
                position: relative;
                border: 1px solid #fff;
                border-radius: 5px;
                transition: all 200ms ease-out;
                font-family: 'Baloo Tamma', cursive;
                text-transform: uppercase;
                height: 38px;
                line-height: 38px;
                overflow: hidden;
                text-align: center;
                padding: 0;

                &:hover, &:focus, &:active {
                    text-decoration: none;
                }

                &.subscribed {
                    span {
                        top: 2px;
                    }
                }

                span {
                    display: block;
                    position: relative;
                    transition: 0.3s;
                    height: 100%;
                    top: -34px;
                    padding: 0 10px;
                }

            }
        }
    }
}

@media all and (max-width: 480px) {
    .OptInEmail {
        .modal-content {
            background-position: top right;
            min-width: 90%;

            .modal-body {
                .Title {
                    font-size: 3rem;
                }

                .SubTitle {
                    font-size: 1.5rem;
                }

                .Description {
                    font-size: 0.8rem;
                }
            }
        }
    }
}