.ButtonCheckout {
    .Cancel {
        margin-right: 10px;
        color: #999;

        &:hover {
            color: #333;
        }
    }

    .Checkout {

    }
}