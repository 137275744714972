.ItemsExplorerHeader {
    font-size: 0.9375rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    .Top {
        position: relative;
        padding: 15px 0;
        background-color: #fff;
        margin-bottom: 16px;
        box-shadow: 2.8px 2.8px 16px 0 rgba(0, 0, 0, 0.08);
        color: #808080;

        .Wrapper {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .form-control {
            color: #808080;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border-top: 20px solid $tamz_primary_color;
            border-right: 20px solid transparent;
        }
    }

    .Bottom {
        .Wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }
    }
}

@media (max-width: 1024px) {
    body .ItemsExplorerHeader {
        .Bottom {
            .Wrapper {
                display: block;
            }
        }
    }
}

@media all and (max-width: 480px) {
    body .ItemsExplorerHeader {
        .Top {
            &:before {
                display: none;
            }
        }

        .Bottom {
            .Wrapper {
                display: flex;
                align-items: center
            }
        }
    }
}