.ButtonAddFavorites {
    top: 5px;
    right: 5px;
    position: absolute;
    padding: 4px 7px;
    font-size: 21px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    transition: all 300ms ease-out;
    opacity: 0;
    display: inline-block;
    z-index: 9;

    .Text {
        display: none;
    }

    i {
        transition: all 300ms ease-out;
        filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));

        &:hover {
            transform: scale(1.15);
        }
    }
}

.Item {
    &:hover {
        .ButtonAddFavorites {
            opacity: 1;
        }
    }
}

.ItemTable {
    .ButtonAddFavorites {
        .Text {
            display: none;
        }
    }
}

@media all and (max-width: 480px) {
    .ButtonAddFavorites {
        opacity: 1;

        .Text {
            display: none;
        }
    }
}