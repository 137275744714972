.FilterTimeAvailable {
    cursor: pointer;
    border: 1px solid #eee;

    @include border-radius(5px);

    i {
        margin-right: 10px;
    }

    .Content {
        display: flex;
        align-items: baseline;

        &:hover {
            color: $tamz_primary_color;
        }
    }

    &.isActive {
        background-color: $tamz_primary_color;
        border-color: $tamz_primary_color;

        .Content, i {
            color: #fff;
        }
    }
}

.FormFilterTimeAvailable {
    display: flex;
    padding: 36px 32px;

    form {
        button {
            cursor: pointer;
        }
    }

    .Auto {
        position: relative;
        width: 160px;

        &:after {
            position: absolute;
            width: 150px;
            height: 8px;
            background-image: -webkit-radial-gradient(circle 5px, #eee 30%, transparent 30%);
            background-image: -o-radial-gradient(circle 5px, #eee 30%, transparent 30%);
            background-image: radial-gradient(circle 5px, #eee 30%, transparent 30%);
            background-size: 4px 7px;
            content: '';
            right: -73px;
            top: 70px;
            border-bottom: none;
            transform: rotateZ(90deg);
        }

        .Options {
            display: flex;
            flex-wrap: wrap;

            .CustomAuto {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                width: 100%;
                padding-right: 10px;

                input {
                    width: 70px;
                    margin-right: 10px;
                }

                .Unit {
                }
            }

            button {
                background-color: #fff;
                color: #808080;
                border-color: #eee;
                line-height: 28px;
                padding: 0 20px;
                font-size: 0.9375rem;

                &.active {
                    background-color: $tamz_primary_color;
                    color: #fff;
                    border-color: $tamz_primary_color;
                    box-shadow: none !important;
                    cursor: auto;
                }

                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .Custom {
        margin-left: 30px;

        .DateRangePicker {
            .DateRangePickerInput {
                border-color: #eee;
            }

            .DateInput_input {
                font-size: 0.9rem;
            }

            .DateRangePickerInput_arrow_svg {
                fill: #ddd;
            }
        }
    }
}

@media all and (max-width: 480px) {
    .FormFilterTimeAvailable {
        flex-direction: column !important;

        .Auto {
            width: 100%;

            &:after {
                display: none;
            }

            .Options {
                justify-content: center;

                .CustomAuto {
                    justify-content: center;
                }

                button {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }
        }

        .Custom {
            width: 100%;
            margin-left: 0;

            .DateRangePicker {
                width: 100%;
                text-align: center;

                .DateInput {
                    width: 100px;

                    input {
                        text-align: center;
                        padding: 6px 10px 3px;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}
