html {
    position: relative;
    min-height: 100%;
}

body {
    padding-bottom: 100px;
}

#footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #fff;
    z-index: 10;
}