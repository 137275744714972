.NotifyModal {
    font-size: 1rem;

    .modal-body {
        white-space: pre-line;
    }

    .modal-footer {
        button {
            cursor: pointer;
        }
    }
}