.Item {
    td {
        vertical-align: middle;
    }

    &.dead {
        opacity: 0.5;
    }

    .RankHistory {
        img {
            cursor: pointer;
            border: 1px solid #999;
            max-width: 100px;
        }
    }

    .updated {
        position: relative;
    }

    .Thumbnail {
        position: relative;
        cursor: pointer;

        &.noThumbnail {
            background: url("/assets/images/no-image.svg") center center no-repeat;
            background-size: 80%;

            a {
                border: 1px solid #ddd;
            }
        }

        &:hover {
            .hover {
                display: block;
            }
        }

        a {
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100px;
            position: relative;

            img {
                width: 100%;
                height: auto;
                opacity: 0;
            }
        }

        .hover {
            display: none;
            position: absolute;
            top: 0;
            left: 150px;
            z-index: 20;
            width: 300px;
            height: 300px;
            overflow: hidden;

            img {
                width: 590px;
                height: auto;
                position: relative;
                bottom: 106px;
                right: 140px;
            }
        }
    }

    .Updated {
        color: #aaa;
        font-size: 0.75rem;
        margin-top: 14px;
    }

    .availableAt {
        font-size: 15px;

        .Title {
            color: #aaa;
        }

        .Value {
            color: #333;
        }

        .Value a {
            color: #333;
        }
    }

}

.ItemGrid {
    padding-left: 9px;
    padding-right: 9px;
    margin-bottom: 35px;
    list-style: none;

    &:hover {
        .Thumbnail {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
        }
    }

    &:not(.cropped) {
        .Thumbnail {
            a {
                background-position: center;
                height: 0;
                position: relative;
                background-size: 100%;
                padding-bottom: 79.412%;
                border-radius: 2px;
            }
        }
    }

    &.trending {
        .Top {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                width: 50px;
                height: 50px;
                background: url("/assets/images/trending.svg") center center no-repeat;
                z-index: 1;
                background-size: cover;
            }
        }
    }

    .Thumbnail {
        transition: all 200ms ease-out;
        margin-bottom: 6px;

        a {
            display: block;
            width: 100%;
            height: 0;
            position: relative;
            padding-bottom: 79.412%;
            background-color: #eee;
            overflow: hidden;
        }

        &:after {
            content: none;
        }
    }

    .Top {
        position: relative;

        .Price {
            position: absolute;
            bottom: 5px;
            left: 5px;
            background-color: rgba(0, 0, 0, .4);
            color: #fff;
            font-size: .75rem;
            padding: 5px;
            border-radius: 5px;
        }
    }

    .FavoriteCount {
        position: absolute;
        bottom: 5px;
        right: 5px;
        display: flex;
        font-size: .75rem;

        .Inner {
            background-color: #fff;
            border-radius: 5px;
            padding: 14px 5px 4px;
            //padding: 4px 5px;
            border: 1px solid #eee;
            display: flex;

            .Count {
                display: inline-flex;
                align-items: center;
                margin-right: 10px;
                position: relative;

                &:last-child {
                    margin-right: 0;
                }
                .BadgeCount {
                    position: absolute;
                    top: -15px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-weight: 700;

                    &.Up {
                        color: #37af56;
                    }

                    &.Down {
                        color: #e74c3c;
                    }
                }
            }
        }

        .Icon {
            margin-right: 5px;
            position: relative;
            top: 1px;
            color: $tamz_red_color;
        }

        .Current {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            img {
                transition: all 200ms ease-out;
                width: auto;
                min-width: 8px;
                max-height: 12px;
            }
        }
    }

    .Bottom {
        text-align: left;

        .ShopUpdated,
        .ShopDate {
            display: flex;
            justify-content: space-between;
            align-items: center;
            i {
                margin-right: 5px;
            }
        }
    }

    .Name {
        display: block;
        font-size: 14px;
        line-height: 1.6;
        font-weight: 300;
        color: #222;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .Shop {
        color: #595959;
        font-size: 0.75rem;

        .ShopName {
            color: #595959;

            &:hover {
                color: $tamz_primary_color;
            }
        }
    }

    .Updated {
        margin-top: 0;
    }

    &.fuzzy{
        .Top{
            .UpgradePlan{
                position: absolute;
                top:50%;
                left: 0;
                width: 100%;
                z-index: 999;
                transform: translate(0, -50%);

                .InnerUpgrade{
                    .Actions{
                        .Upgrade{
                            background-color: #f56400;
                            color: #ffffff;
                            transition: all 0.3s ease 0s;

                            &:hover{
                                background-color: darken(#f56400, 15%);
                            }
                        }

                        .Hide{
                            font-size: 16px;
                            margin-top: 10px;

                            &:hover{
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .Thumbnail{
                a{
                    &:hover{
                        cursor: not-allowed;
                    }
                }
            }
        }

        .Bottom{
            position: relative;
            filter: blur(2px);

            &:after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
            }
        }
    }
}

.TableListItems {
    &.list {
        .Item .ASIN {
            margin-bottom: 3px;
        }

        .Item .brand {
            margin-bottom: 3px;
        }
    }
}

@media (max-width: 768px) {
    body .Item .Updated {
        font-size: 13px;
    }
}

@media all and (max-width: 576px) {
    .ItemGrid {
        .Top {
            .Price {
                top: 5px;
                bottom: auto;
            }
        }
    }
}

@media all and (max-width: 480px) {
    .ItemGrid {
        .Bottom {
            .rank {
                > div {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .ItemGrid {
        &.col-xl-2 {
            flex: 0 0 20%;
            max-width: 20%;
        }
    }
}
