$blue: #03A9F4;
$green: #4CAF50;
$yellow: #FFEB3B;
$cyan: #00BCD4;
$red: #F44336;
$teal: #009688;
$orange: #FF9800;
$pink: #E91E63;
$purple: #9C27B0;
$indigo: #3F51B5;
$body-color: #37474F;