.modal-content {
    border: none;
    overflow: hidden;
    border-radius: 6px;
    width: 672px;
    .modal-body{
        padding: 0;

        .btn-close-modal{
            padding: 8px 16px;
            text-transform: uppercase;
            border-radius: 6px;
            background-color: #fff;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1.6px;
            color: #3F3F46;
            cursor: pointer;
            border: 1px solid rgba(212,212,216,1);
            transition-duration: .15s;
            outline: none;
        }
        .btn-close-modal:hover {
            color: #71717A;
        }
    }
}

