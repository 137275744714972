.NicheEventsPage {
    margin-bottom: 50px;

    .PageTitle {
        font-size: 20px;
        color: #333;
        margin: 0;
    }
}

@media screen and (max-width: 480px) {
    body .NicheEventsPage {
        .PageTitle {
            margin: 0;
            padding: 15px 0 10px 0;
        }

        .ProductItem {
            margin-bottom: 20px;
        }
    }
}