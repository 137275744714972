.EventDetails {
    position: relative;
    text-align: left;

    .Close {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 12px;
        cursor: pointer;
        color: #ff443e;
        z-index: 2;
    }

    .Body {
        max-height: 100%;
        overflow: auto;
        padding: 30px;
        color: #333;
        font-size: 0.9375rem;

        .Name {
            font-size: 1.375rem;
            color: $tamz_primary_color;
            margin-bottom: 20px;
        }

        .Meta {
            margin-bottom: 10px;

            .Icon {
                display: inline-block;
                margin-right: 5px;
                min-width: 16px;
            }
        }

        .Description {
            color: #666;
        }
    }
}