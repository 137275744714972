.SearchTerm {
    position: relative;

    .Remove {
        display: none;
        position: absolute;
        top: 50%;
        right: 5px;
        font-size: 0.8rem;
        transform: translateY(-50%);
        cursor: pointer;

        &:before {
            content: "\f12a";
            font-family: "Ionicons", serif;
        }

        &:hover {
            color: #ff443e;
        }
    }

    .IconSearch {
        position: absolute;
        color: $tamz_primary_color;
        font-size: 1.25rem;
        right: 10px;
        top: 3px;
        transition: all .3s;
        cursor: pointer;
    }

    input {
        border-radius: 0 5px 5px 0;
    }

    &.hasWord {
        input {
            padding-right: 20px;
        }

        .Remove {
            display: inline-block;
        }

        .IconSearch {
            opacity: 0;
            visibility: hidden;
        }
    }
}


