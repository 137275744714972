.ButtonCheckTMProduct {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #fff;
    padding: 1px 14px;
    z-index: 2;
    border-radius: 3px;
    cursor: pointer;

    .Icon {
        color: #37af56;
        display: inline-flex;
        min-width: 24px;
        align-items: center;
        justify-content: center;

        .linear-ellipsis {
            position: relative;
            top: 1px;
        }
    }

    &.checked {
        opacity: 0.5;
        cursor: auto;
    }

    &:not(.checked) {
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    }

    &.loading {
        cursor: auto;

        .Icon {
            animation: _dotLoading 1s linear infinite;
        }
    }
}

@media all and (max-width: 480px) {
    .ModalShowItemDetailBody {
        .ButtonCheckTMProduct {
            top: auto;
            left: 5px;
            bottom: 5px;
        }
    }
}

@keyframes _dotLoading {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}