.PendingPage {
    .card {
        box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
        border: 1px solid #eeeeee;
        border-radius: 5px;
        color: #A0A0A0;
        .status {
            color: #535353;
            font-weight: bold;
        }
    }
}