.OrderTotal {
    margin-bottom: 20px;

    .Discount {
        font-size: 1rem;
        color: #666;
    }

    .Total {
        font-size: 1.2rem;
        font-weight: 500;
    }

    .Label {
        margin-right: 10px;
    }
}