.FilterControl {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    padding-left: 30px;

    & > div {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-right: 5px;
        }
    }

    i {
        color: #ccc;
        position: relative;
        top: 1px;
    }

    .Content {
        padding: 0 10px;
        transition: all 200ms ease-out;
    }
}

@media (max-width: 1024px) {
    body .FilterControl {
        padding-left: 0;
    }
}

@media (max-width: 768px) {
    body .FilterControl {
        margin-bottom: 10px;
    }
}

@media all and (max-width: 480px) {
    body .FilterControl {

        flex-wrap: wrap;

          >div {
              min-height: 34px;
              margin-bottom: 10px;
          }

        .FilterBrandType {
            margin-right: 0;
        }

        .FilterType {
            display: none;
        }

        .FilterPrice {
            display: none;
        }

        //.FilterStatus {
        //    display: none;
        //}
    }

  .FormFilterRank{
    button{
      &:last-child{
        margin-bottom: 10px;
      }
    }

  }
}