.PreloadBox {
    width: 100%;

    .Item {
        width: 100%;
        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-name: placeHolderShimmer;
        -webkit-animation-timing-function: linear;
        background: #f6f7f9;
        background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
        background-repeat: no-repeat;
        background-size: 800px auto;
        background-position: 100px 0;
    }

    .Item:not(:last-child) {
        margin-bottom: 10px;
    }
}