.LicenceCode {
    padding:16px 32px 32px;
    border-top: 1px solid #e4e4e7;
    background-color: rgba(244,244,245, 1);

    .Welcome {
        margin-bottom: 16px;

        span {
            color: rgba(15,118,110,1);
            text-decoration: underline;
        }
    }

    .LicenceCodeBody {
        background-color: #f4f4f500;
        display: flex;

        .title{
            flex: 1;
            h3{
                font-size: 1.125rem;
            }
        }

        .CreateCode {
            background-color: #fff;
            flex: 2;
            border-radius: 6px;
            box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);
            overflow: hidden;

            .CreateCodeInput{
                padding: 24px;

                label{
                    font-size: 14px;
                    color: rgba(63,63,70,1);
                    line-height: 20px;
                    margin: 0;
                }

                input {
                    width: 70%;
                    border-radius: 6px;
                    padding: 8px 12px;
                    background-color: #fff;
                    border: 1px solid #71717a;
                    font-size: 16px;
                    margin-top: 6px;
                    display: block;
                    outline: none;
                }

                .RequiredText{
                    font-size: 14px;
                    color: #DC2626;
                    margin-top: 8px;
                    line-height: 20px;
                }
            }

            .CreateCodeSubmit{
                padding: 12px 24px;
                text-align: right;
                background-color: rgba(250,250,250,1);

                .BtnSubmit{
                    padding: 8px 16px;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 1.6px;
                    background-color: rgba(39,39,42,1);
                    text-transform: uppercase;
                }
            }
        }
    }
}

.ManageLicence{
    padding:32px;
    border-top: 1px solid #e4e4e7;
    background-color: rgba(244,244,245, 1);
    display: flex;

    .left{
        flex: 1;
        .title{
            font-size: 18px;
            color: #18181B;
        }
        .content {
            padding-right: 20px;
            margin-top: 4px;
            font-size: 14px;
            color: #52525B;
        }
    }

    .right{
        border-radius: 6px;
        background-color: #fff; 
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);
        flex: 2;

        .LicenseName{
            font-size: 16px;
            padding: 24px;
            display: flex; 
            justify-content: space-between;

            .BtnDelete{
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
}

.ModalLicenceCodeBody {
    padding: 16px 24px;

    .note{
        padding-top: 16px;
        font-size: 16px;
    }

    .LicenseCode {
        font-size: 14px;
        color: #71717A;
        background-color: rgba(244,244,245,1);
        padding: 8px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon {
            cursor: pointer;
        }
    }
}

.ModalLicenceCodeFooter {
    padding: 16px 24px;
    background-color: #F4F4F5;
    text-align: right;
}