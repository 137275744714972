.ListProductsByCategory {
    display: flex;
    margin: 0 -5px 10px;

    .Product {
        padding: 0 5px;

        &.noBackground {
            .Preview {
                &:after {
                    content: '';
                    display: inline-block;
                    max-width: 100%;
                    min-height: 100px;
                }
            }
        }

        .Preview {
            background-repeat: no-repeat;
            background-color: #eee;
            background-position: center;
            background-size: cover;
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 100%;

            img {
                max-width: 100%;
                height: auto;
                opacity: 0;
            }
        }

        &.c- {
            &popsockets {
                .Preview {
                    background-size: 100%;
                }
            }
        }
    }
}