.ProductDetails {
    margin-top: 20px;

    &.trending {
        .Body {
            .ProductImagePreview {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 50px;
                    height: 50px;
                    background: url("/assets/images/trending.svg") center center no-repeat;
                    z-index: 1;
                    background-size: cover;
                }
            }
        }
    }

    .Body {
        margin-bottom: 20px;
    }

    .Left {
        .WrapperLeft {
            position: relative;

            .ButtonAddFavorites {
                top: 10px;
                right: 10px;
            }

            .Price {
                position: absolute;
                bottom: 10px;
                left: 10px;
                background-color: rgba(0, 0, 0, .5);
                color: #fff;
                font-size: .875rem;
                padding: 5px;
                border-radius: 5px;
            }
        }
    }

    .Right {
        color: #808080;
        max-height: 500px;
        overflow-y: auto;
        font-size: 16px;
        font-weight: 300;

        .Link {
            margin-left: 5px;
        }

        .Description {
            white-space: pre-wrap;
        }

        > div {
            margin-bottom: 5px;

            > span:first-child {
                font-weight: 500;
                color: #333333;

            }
        }

        > span {
            font-weight: 500;
        }
    }

    .TitleItem h1 {
        font-size: 2rem;
        font-weight: 500;
        color: #333;
        padding-bottom: 30px;
        padding-top: 10px;

    }

    .ASIN {
        font-weight: 500;
    }

    .Actions {
        > div span:first-child {
            color: #03A9F4 !important;
            font-weight: 500;
        }
        .linear-link {
            color: #03A9F4 !important;
        }
    }

    .ButtonAddFavorites {
        display: inline-block;
        transition: all 200ms ease-out;

        &.loading {
            opacity: 0.5;
        }
    }

    .HistoryChart{
        font-size: 14px;

        .recharts-wrapper{
            display: inline-block;
            float: left;
        }
        .SelectTimeWrapper{
            display: inline-block;
            padding-left: 20px;
        }

        &:after{
            content: '';
            display: block;
            clear: both;
        }
    }

    .Histories {
        padding-bottom: 30px;
    }

    &.c- {
        &popsockets {
            .Body {
                .Left {
                    .WrapperLeft {
                        .ProductImagePreview {
                            background-size: 100%;
                            padding-bottom: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width: 1024px) {
    .ItemDetail {
        .Right {
            max-height: 300px;
        }
    }
}

@media all and (max-width: 576px) {
    .ItemDetail {
        .Left {
            .Preview {
                margin-bottom: 20px;
            }
        }
    }
}

@media all and (max-width: 480px) {
    .ItemDetail {
        .Header {
            .TitleItem {
                h1 {
                    font-size: 1.5rem;
                    padding-bottom: 20px;
                }
            }
        }

        .Body {
            .Left {
                margin-bottom: 20px;
            }

            .Right {
                overflow-y: visible;
                max-height: 100%;

                .HistoryChart{
                    .recharts-wrapper{
                        max-width: 100%;
                        > * {
                            max-width: 96%;
                        }
                    }
                }
            }
        }
    }
}