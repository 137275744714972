.RedeemCoupon {
    .Text {
        color: #9e9e9e;
        cursor: pointer;
        font-size: 0.9rem;
        margin-bottom: 10px;
    }

    form {
        margin-bottom: 10px;
        flex-flow: row nowrap;

        input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: none;
        }

        button {
            height: 35px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.open {
        form {
            display: flex;
        }
    }

    .CouponCode {
        background-color: #666;
        border-radius: 5px;
        padding: 0 15px 0 10px;
        display: inline-block;
        margin-bottom: 10px;
        position: relative;

        .Code {
            color: #fff;
            margin-right: 7px;
            font-size: 1.2rem;
            vertical-align: middle;

            .Text {
                cursor: auto;
                font-size: 0.9rem;
                border-left: 1px solid #a9a9a9;
                color: #a9a9a9;
                margin-left: 10px;
                padding-left: 5px;
            }
        }

        .Close {
            color: #eee;
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            cursor: pointer;
            padding: 0 9px;

            &:hover {
                color: #ed4f32;
            }
        }
    }

    .Error {
        color: #ed4f32;
        border-left: 2px solid #ed4f32;
        padding-left: 10px;

        .Message {
            font-size: 0.9rem;
        }
    }
}