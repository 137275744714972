.ShowPerPage {
    font-size: 0.9375rem;
    color: #808080;
    padding-right: 5px;

    & > span {
        min-width: 50px;
    }

    select.form-control {
        color: #333;
        margin: 0 9px;
    }
}
