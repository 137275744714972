.CannotUseFeature {
    min-height: 100vh;
    background-size: cover !important;

    &.show {
        .Wrapper {
            .Inner {
                transform: translateY(0);
            }
        }
    }

    .Wrapper {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(170, 170, 170, 0.5);
            z-index: 2;
        }

        .Inner {
            padding: 70px 5%;
            max-width: 90%;
            height: auto;
            position: relative;
            top: 65px;
            z-index: 10;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 5px;
            box-shadow: 0 0 40px rgba(0, 0, 0, .5);
            display: inline-block;
            transition: all 300ms ease-out;
            transform: translateY(-100%);

            h2 {
                font-size: 1.5rem;
                margin-bottom: 10px;
            }

            .Description {
                font-size: 0.9rem;
                margin-bottom: 20px;
                color: #444;
                font-weight: 300;
            }
        }
    }
}

@media all and (max-width: 480px) {
    .CannotUseFeature {
        min-height: calc(100vh - 66px);
    }
}