.ProfileSettings {
    .title {
        font-size: 1.625rem;
        font-weight: 400;
        text-align: center;
        margin-bottom: 60px;
    }

    .Info {
        display: flex;
        width: 614px;
        max-width: 100%;
        margin: 0 auto 30px;
        justify-content: space-between;
        padding: 40px;
        box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
        border: solid 1px #eee;
        border-radius: 5px;
    }

    .Settings {
        width: 614px;
        max-width: 100%;
        margin: auto;
        padding: 40px;
        box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
        border: solid 1px #eee;
        border-radius: 5px;

        h3 {
            margin-bottom: 2rem;
        }

        form {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
        }

        .Save {
            position: relative;
            height: 30px;

            button {
                position: absolute;
                right: 0;
            }
        }

        .alert {
            font-size: 14px;
            color: $tamz_success_color
        }
    }

    .Basic {
        .form-group {
            label {
                font-size: 0.9375rem;
                color: #808080;
            }

            input {
                width: 280px;
                height: 50px;
                font-size: 1rem;
                font-weight: 500;
                padding-left: 20px;
            }
        }
    }

    .Avatar {
        width: 200px;
        border-radius: 5px;
        overflow: hidden;
    }
}

@media all and (max-width: 480px) {
    .ProfileSettings {
        .Info {
            .Avatar {
                display: none;
            }

            .Basic {
                width: 100%;

                .form-group {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        width: auto;
                    }
                }
            }
        }

        .Settings {
            form {
                display: flex;
                align-items: center;
                justify-content: space-between;

                select {
                    width: auto;
                }
            }
        }
    }
}