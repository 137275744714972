.FilterStatus {
    &.isActive{
        .SelectWrapper{
            background-color: $tamz_primary_color;
            border-radius: 5px;

            select{
                background-color: transparent;
                color: #fff;
                border: none;

                option{
                    color: #808080;
                }
            }
        }
    }
}