.FilterPrice {
    border: 1px solid #eee;
    @include border-radius(5px);

    .Label {
        font-weight: 300;
    }

    .Content {
        position: relative;
        color: #808080;

        .Editable {
            position: absolute;
            top: 0;
            right: -16px;
            bottom: 0;
            display: none;
            font-size: 0.8em;
        }

        &:focus, &:active, &:hover {
            text-decoration: none;
            outline: none;
        }

        &:hover {
            color: $tamz_primary_color;
            cursor: pointer;

            .Editable {
                display: inline-block;
            }
        }

        .Icon{
            margin-right: 10px;
        }
    }

    &.isActive{
        background-color: $tamz_primary_color;
        border-color: $tamz_primary_color;

        .Content{
            color: #fff;

            .Icon{
                display: none;
            }
        }
    }
}

.FormFilterPrice{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 30px;

    .Label{
        min-width: 50px;
    }

    .form-group:not(:last-child){
        margin-bottom: 10px;
    }
}