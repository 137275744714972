.SettingsPage {
    display: flex;
    flex-grow: 1;
    min-height: 100vh;

    .Wrapper {
        width: 870px;
        max-width: 100%;
        margin: auto;
    }
}

@media all and (max-width: 480px) {
    .SettingsPage {
        display: block;

        .SettingsMobileMenu {
            .Menu {
                margin-top: 20px;
            }
        }

        .MainSettings {
            overflow: visible;
            padding: 20px 15px 50px;
            height: auto;

            .Wrapper {
                width: auto;
                max-width: 100%;
            }
        }
    }
}