@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
@import url(https://use.fontawesome.com/releases/v5.3.1/css/all.css);
@import url(/assets/css/ionicons.css);
@import url(/assets/css/linearicons.css);
@import url(https://fonts.googleapis.com/css?family=Baloo+Tamma);
/*!
 * Bootstrap v4.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #03A9F4;
  --indigo: #3F51B5;
  --purple: #9C27B0;
  --pink: #E91E63;
  --red: #F44336;
  --orange: #FF9800;
  --yellow: #FFEB3B;
  --green: #4CAF50;
  --teal: #009688;
  --cyan: #00BCD4;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #03A9F4;
  --secondary: #6c757d;
  --success: #4CAF50;
  --info: #00BCD4;
  --warning: #FFEB3B;
  --danger: #F44336;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #37474F;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #03A9F4;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0275a8;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014   \A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #E91E63;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8e7fc; }

.table-hover .table-primary:hover {
  background-color: #a0dffb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a0dffb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cde9ce; }

.table-hover .table-success:hover {
  background-color: #bbe1bd; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #bbe1bd; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #b8ecf3; }

.table-hover .table-info:hover {
  background-color: #a2e6ef; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a2e6ef; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff9c8; }

.table-hover .table-warning:hover {
  background-color: #fff6af; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fff6af; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccac7; }

.table-hover .table-danger:hover {
  background-color: #fbb3af; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb3af; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #79d4fd;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #37474F;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4CAF50; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #4CAF50; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #4CAF50; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #4CAF50; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #a3d7a5; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #6ec071; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #4CAF50; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #F44336; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #F44336; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #F44336;
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #F44336; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #F44336; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fbb4af; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #f77066; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #F44336; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4; }
  .btn-primary:hover {
    color: #fff;
    background-color: #038fce;
    border-color: #0286c2; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #03A9F4;
    border-color: #03A9F4; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0286c2;
    border-color: #027db5; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #fff;
  background-color: #4CAF50;
  border-color: #4CAF50; }
  .btn-success:hover {
    color: #fff;
    background-color: #409444;
    border-color: #3d8b40; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #3d8b40;
    border-color: #39833c; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-info {
  color: #fff;
  background-color: #00BCD4;
  border-color: #00BCD4; }
  .btn-info:hover {
    color: #fff;
    background-color: #009aae;
    border-color: #008fa1; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #00BCD4;
    border-color: #00BCD4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008fa1;
    border-color: #008394; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #FFEB3B;
  border-color: #FFEB3B; }
  .btn-warning:hover {
    color: #212529;
    background-color: #ffe715;
    border-color: #ffe608; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FFEB3B;
    border-color: #FFEB3B; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffe608;
    border-color: #fae100; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #F44336;
  border-color: #F44336; }
  .btn-danger:hover {
    color: #fff;
    background-color: #f22112;
    border-color: #ea1c0d; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ea1c0d;
    border-color: #de1b0c; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #03A9F4;
  background-color: transparent;
  background-image: none;
  border-color: #03A9F4; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #03A9F4;
    border-color: #03A9F4; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #03A9F4;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #03A9F4;
    border-color: #03A9F4; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #4CAF50;
  background-color: transparent;
  background-image: none;
  border-color: #4CAF50; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #4CAF50;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #4CAF50;
    border-color: #4CAF50; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5); }

.btn-outline-info {
  color: #00BCD4;
  background-color: transparent;
  background-image: none;
  border-color: #00BCD4; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #00BCD4;
    border-color: #00BCD4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #00BCD4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00BCD4;
    border-color: #00BCD4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5); }

.btn-outline-warning {
  color: #FFEB3B;
  background-color: transparent;
  background-image: none;
  border-color: #FFEB3B; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #FFEB3B;
    border-color: #FFEB3B; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFEB3B;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FFEB3B;
    border-color: #FFEB3B; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 235, 59, 0.5); }

.btn-outline-danger {
  color: #F44336;
  background-color: transparent;
  background-image: none;
  border-color: #F44336; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #F44336;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #F44336;
    border-color: #F44336; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #03A9F4;
  background-color: transparent; }
  .btn-link:hover {
    color: #0275a8;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #37474F;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #03A9F4; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #03A9F4; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #ace4fe; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #03A9F4; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #03A9F4; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(3, 169, 244, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(3, 169, 244, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #03A9F4; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(3, 169, 244, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #79d4fd;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(121, 212, 253, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #79d4fd;
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #79d4fd; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #03A9F4;
    border: 0;
    border-radius: 1rem;
    -webkit-appearance: none;
            appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #ace4fe; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #03A9F4;
    border: 0;
    border-radius: 1rem;
    -moz-appearance: none;
         appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #ace4fe; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #03A9F4;
    border: 0;
    border-radius: 1rem;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #ace4fe; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #03A9F4; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #03A9F4;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0275a8;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(3, 169, 244, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #03A9F4;
  border-color: #03A9F4; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #03A9F4; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0286c2; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #4CAF50; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #3d8b40; }

.badge-info {
  color: #fff;
  background-color: #00BCD4; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #008fa1; }

.badge-warning {
  color: #212529;
  background-color: #FFEB3B; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ffe608; }

.badge-danger {
  color: #fff;
  background-color: #F44336; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #ea1c0d; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #02587f;
  background-color: #cdeefd;
  border-color: #b8e7fc; }
  .alert-primary hr {
    border-top-color: #a0dffb; }
  .alert-primary .alert-link {
    color: #01354d; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce; }
  .alert-success hr {
    border-top-color: #bbe1bd; }
  .alert-success .alert-link {
    color: #18381a; }

.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3; }
  .alert-info hr {
    border-top-color: #a2e6ef; }
  .alert-info .alert-link {
    color: #00353b; }

.alert-warning {
  color: #857a1f;
  background-color: #fffbd8;
  border-color: #fff9c8; }
  .alert-warning hr {
    border-top-color: #fff6af; }
  .alert-warning .alert-link {
    color: #5c5415; }

.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7; }
  .alert-danger hr {
    border-top-color: #fbb3af; }
  .alert-danger .alert-link {
    color: #551713; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #03A9F4;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #37474F;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #03A9F4;
    border-color: #03A9F4; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #02587f;
  background-color: #b8e7fc; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #02587f;
    background-color: #a0dffb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #02587f;
    border-color: #02587f; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #285b2a;
    background-color: #bbe1bd; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #285b2a;
    border-color: #285b2a; }

.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #00626e;
    background-color: #a2e6ef; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00626e;
    border-color: #00626e; }

.list-group-item-warning {
  color: #857a1f;
  background-color: #fff9c8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #857a1f;
    background-color: #fff6af; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #857a1f;
    border-color: #857a1f; }

.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7f231c;
    background-color: #fbb3af; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7f231c;
    border-color: #7f231c; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -25%);
            transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #37474F; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-next,
    .active.carousel-item-right {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-item-prev,
    .active.carousel-item-left {
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  @supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #03A9F4 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0286c2 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #4CAF50 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important; }

.bg-info {
  background-color: #00BCD4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important; }

.bg-warning {
  background-color: #FFEB3B !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffe608 !important; }

.bg-danger {
  background-color: #F44336 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #03A9F4 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #4CAF50 !important; }

.border-info {
  border-color: #00BCD4 !important; }

.border-warning {
  border-color: #FFEB3B !important; }

.border-danger {
  border-color: #F44336 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #03A9F4 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0286c2 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important; }

.text-success {
  color: #4CAF50 !important; }

a.text-success:hover, a.text-success:focus {
  color: #3d8b40 !important; }

.text-info {
  color: #00BCD4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #008fa1 !important; }

.text-warning {
  color: #FFEB3B !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ffe608 !important; }

.text-danger {
  color: #F44336 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #ea1c0d !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important; }

.text-body {
  color: #37474F !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.ModalAddToFavorites .ProductPreview .Wrapper .Name, .FeaturedItems .Items .FeaturedItem .Detail .Brand, .ProductItem .Bottom .Brand, .FavoriteCategory .Wrapper .Header .Title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%; }

body {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif;
  color: #333;
  font-weight: 400;
  font-size: 1.125rem;
  padding-bottom: 0 !important; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.overflow {
  overflow: auto; }

.btn-primary {
  border-color: rgba(245, 100, 0, 0.8);
  background-color: rgba(245, 100, 0, 0.8); }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled) .active {
    border-color: #f56400;
    background-color: #f56400; }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 100, 0, 0.5); }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    border-color: #f56400;
    background-color: #f56400; }
  .btn-primary:disabled {
    border-color: rgba(245, 100, 0, 0.5);
    background-color: rgba(245, 100, 0, 0.5); }

.PageTitle {
  font-size: 20px;
  color: #333;
  padding: 25px 0 20px 0; }

.Spinner {
  width: 50px;
  height: 50px;
  margin: 100px auto;
  background-color: #f56400;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out; }

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    opacity: 0; } }

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0; } }

.UserMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 274px;
  background-color: #fff;
  padding-left: 30px;
  padding-bottom: 30px;
  transition: all .3s; }
  .UserMenu .Avatar {
    width: 40px;
    height: 40px;
    margin-right: 4px;
    border-radius: 50%;
    transition: all .3s; }
    .UserMenu .Avatar:hover {
      box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.1); }
  .UserMenu .Notify {
    position: absolute;
    top: 2px;
    left: 32px;
    display: inline-flex;
    background-color: #ff443e;
    border-radius: 50%;
    color: #fff;
    -webkit-animation: sonar 1.5s infinite;
            animation: sonar 1.5s infinite;
    font-size: 10px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    border: 2px solid #fff;
    padding: 1px 3px;
    line-height: 11px; }
  .UserMenu .dropdown {
    cursor: pointer;
    display: inline-block; }
    .UserMenu .dropdown .Badge {
      background-color: #ff443e;
      color: #fff;
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 10px;
      position: relative;
      top: -5px;
      left: 3px; }
  .UserMenu .dropdown-menu {
    left: 100% !important;
    -webkit-transform: none !important;
            transform: none !important;
    top: unset !important;
    bottom: 10px !important;
    border-radius: 5px;
    box-shadow: 4.2px 4.2px 18px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px #eeeeee;
    color: #212529; }
    .UserMenu .dropdown-menu a {
      color: inherit; }
  .UserMenu .dropdown-toggle::after {
    border-top: 0.25em solid transparent;
    border-left: 0.3em solid #333;
    border-bottom: 0.25em solid transparent;
    vertical-align: baseline;
    margin-left: 8px; }

@media all and (max-width: 480px) {
  .UserMenu {
    width: auto; } }

@-webkit-keyframes sonar {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes sonar {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  20% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2); }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  80% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

html {
  position: relative;
  min-height: 100%; }

body {
  padding-bottom: 100px; }

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  z-index: 10; }

.LoadingBar {
  background-color: #f56400 !important; }

.ProductImagePreview {
  display: block;
  background: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 0;
  position: relative;
  padding-bottom: 79.412%;
  overflow: hidden;
  background-color: #ddd; }
  .ProductImagePreview.cropped {
    background-size: 100%;
    background-position: top center; }
  .ProductImagePreview.noThumbnail {
    background: url("/assets/images/no-image.svg") center center no-repeat !important;
    background-size: 80%;
    border: 1px solid #ddd; }
  .ProductImagePreview img {
    min-width: 100%;
    opacity: 0;
    min-height: 100%;
    height: auto; }

@media all and (max-width: 480px) {
  #fb-root {
    display: none !important; } }

.MobileMenu {
  padding-bottom: 66px;
  background: #fdfdfd; }
  .MobileMenu .bm-burger-button {
    display: none; }
  .MobileMenu .bm-cross-button {
    height: 24px;
    width: 24px;
    background: #000;
    opacity: 0.55; }
    .MobileMenu .bm-cross-button > span {
      right: 13px; }
      .MobileMenu .bm-cross-button > span > span {
        width: 2px;
        height: 13px; }
  .MobileMenu .bm-cross {
    background: #bdc3c7; }
  .MobileMenu .bm-menu {
    padding: 20px;
    font-size: 1.125rem;
    background-color: #fff; }
  .MobileMenu .bm-morph-shape {
    fill: #373a47; }
  .MobileMenu .bm-item-list {
    color: #b8b7ad; }
  .MobileMenu .bm-overlay {
    background: rgba(0, 0, 0, 0.3); }
  .MobileMenu .Header {
    background-color: #fff;
    min-height: 55px;
    width: 100%;
    box-shadow: 2.8px 2.8px 16px 0 rgba(0, 0, 0, 0.08);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    transition: all 300ms ease-out; }
    .MobileMenu .Header.menu-hidden {
      transform: translateY(-100%);
      -webkit-transform: translateY(-100%); }
    .MobileMenu .Header > button {
      width: 30px;
      height: 25px;
      padding: 0;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      background-color: transparent;
      border: none;
      margin-left: 15px;
      outline: none;
      cursor: pointer; }
      .MobileMenu .Header > button > span {
        position: absolute;
        width: 100%;
        height: 5px;
        left: 0;
        background-color: #f56400;
        border-radius: 3px; }
        .MobileMenu .Header > button > span:nth-child(1) {
          top: 0; }
        .MobileMenu .Header > button > span:nth-child(2) {
          top: 10px; }
        .MobileMenu .Header > button > span:nth-child(3) {
          top: 20px; }
    .MobileMenu .Header .Logo {
      background: url(/assets/images/logo.png) no-repeat center center;
      background-size: 70%;
      width: 101px;
      height: 66px;
      margin: auto;
      display: block; }
  .MobileMenu ul.Menu.nav .nav-link {
    position: relative;
    height: 55px;
    line-height: 55px;
    padding: 0 10px 0 105px;
    border-radius: 5px; }
    .MobileMenu ul.Menu.nav .nav-link.active {
      background-color: #f56400;
      color: #fff; }
    .MobileMenu ul.Menu.nav .nav-link i {
      position: absolute;
      left: -50px;
      top: 12px;
      font-size: 1.75rem; }
  .MobileMenu ul.Menu.nav .Text {
    position: relative;
    display: inline-block; }
    .MobileMenu ul.Menu.nav .Text > span {
      position: absolute;
      right: -20px;
      top: 12px;
      font-size: 0.625rem;
      color: #fff;
      background-color: red;
      border-radius: 5px;
      width: 15px;
      line-height: 15px;
      text-align: center;
      font-weight: bold; }

body.mobile-menu-open {
  overflow: hidden; }

@media all and (min-width: 480px) {
  body .MobileMenu {
    display: none; } }

@media (max-width: 480px) {
  body .MobileMenu .bm-menu {
    font-size: 17px; }
  body .MobileMenu ul.Menu.nav .nav-link {
    height: 50px;
    line-height: 50px;
    padding: 0 10px 0 80px; } }

.CannotUseFeature {
  min-height: 100vh;
  background-size: cover !important; }
  .CannotUseFeature.show .Wrapper .Inner {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  .CannotUseFeature .Wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .CannotUseFeature .Wrapper:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(170, 170, 170, 0.5);
      z-index: 2; }
    .CannotUseFeature .Wrapper .Inner {
      padding: 70px 5%;
      max-width: 90%;
      height: auto;
      position: relative;
      top: 65px;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.9);
      border-radius: 5px;
      box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
      display: inline-block;
      transition: all 300ms ease-out;
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%); }
      .CannotUseFeature .Wrapper .Inner h2 {
        font-size: 1.5rem;
        margin-bottom: 10px; }
      .CannotUseFeature .Wrapper .Inner .Description {
        font-size: 0.9rem;
        margin-bottom: 20px;
        color: #444;
        font-weight: 300; }

@media all and (max-width: 480px) {
  .CannotUseFeature {
    min-height: calc(100vh - 66px); } }

.CheckboxAnimated input {
  position: absolute;
  display: none; }
  .CheckboxAnimated input:checked + label:after {
    box-sizing: border-box;
    width: 6px;
    height: 12px;
    border: 0 solid #fff;
    content: '';
    position: absolute;
    display: block;
    top: 10px;
    left: 4px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 0 100%;
            transform-origin: 0 100%;
    border-right-width: 2px;
    border-bottom-width: 2px;
    -webkit-animation: CheckboxCheck 400ms cubic-bezier(0.4, 0, 0.23, 1) forwards;
            animation: CheckboxCheck 400ms cubic-bezier(0.4, 0, 0.23, 1) forwards; }
  .CheckboxAnimated input:checked + label:before {
    border: #f56400;
    background: #f56400;
    -webkit-animation: ShrinkBounce 250ms cubic-bezier(0.4, 0, 0.23, 1);
            animation: ShrinkBounce 250ms cubic-bezier(0.4, 0, 0.23, 1); }

.CheckboxAnimated label {
  position: relative;
  display: block;
  padding-left: 30px;
  cursor: pointer;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin: 0; }
  .CheckboxAnimated label:before {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    content: '';
    border: 1px solid #c0c0c0;
    transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    border-radius: 1px; }

@-webkit-keyframes CheckboxCheck {
  0% {
    width: 0;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: 6px;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: 6px;
    height: 12px;
    -webkit-transform: translate3d(0, -12px, 0) rotate(45deg);
            transform: translate3d(0, -12px, 0) rotate(45deg); } }

@keyframes CheckboxCheck {
  0% {
    width: 0;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  33% {
    width: 6px;
    height: 0;
    -webkit-transform: translate3d(0, 0, 0) rotate(45deg);
            transform: translate3d(0, 0, 0) rotate(45deg); }
  100% {
    width: 6px;
    height: 12px;
    -webkit-transform: translate3d(0, -12px, 0) rotate(45deg);
            transform: translate3d(0, -12px, 0) rotate(45deg); } }

@-webkit-keyframes ShrinkBounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes ShrinkBounce {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1); }
  33% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85); }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes MoveUpDown {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@keyframes MoveUpDown {
  0%, 100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

.OptInEmail {
  max-width: 600px; }
  .OptInEmail .modal-content {
    background: url("/assets/images/optin-email.jpg") no-repeat center;
    background-size: cover;
    border: none;
    min-height: 400px;
    min-width: 400px; }
    .OptInEmail .modal-content .Close {
      position: absolute;
      top: 0;
      right: 0;
      color: #ddd;
      cursor: pointer;
      font-size: 1.2rem;
      padding: 4px 6px;
      line-height: 1.2rem;
      transition: all 200ms ease-out;
      z-index: 9; }
      .OptInEmail .modal-content .Close:hover {
        color: #fff; }
    .OptInEmail .modal-content .modal-body {
      color: #fff;
      text-transform: uppercase;
      text-align: right;
      padding: 50px 20px 80px 20px; }
      .OptInEmail .modal-content .modal-body .Title {
        font-family: 'Baloo Tamma', cursive;
        font-size: 4rem;
        margin: 0 0 1rem;
        line-height: 3rem; }
      .OptInEmail .modal-content .modal-body .SubTitle {
        font-size: 2rem;
        white-space: pre-line; }
      .OptInEmail .modal-content .modal-body .Description {
        font-size: 1rem; }
      .OptInEmail .modal-content .modal-body .Arrow {
        position: relative;
        margin-top: 20px;
        height: 60px; }
        .OptInEmail .modal-content .modal-body .Arrow:before {
          content: '';
          display: inline-block;
          background: url("/assets/images/optin-arrow.png") no-repeat top left;
          background-size: cover;
          position: absolute;
          bottom: 5px;
          right: 60px;
          width: 70px;
          height: 46px;
          -webkit-animation: MoveUpDown 1s linear infinite;
                  animation: MoveUpDown 1s linear infinite; }
      .OptInEmail .modal-content .modal-body .BtnSubscribe {
        color: #fff;
        position: relative;
        border: 1px solid #fff;
        border-radius: 5px;
        transition: all 200ms ease-out;
        font-family: 'Baloo Tamma', cursive;
        text-transform: uppercase;
        height: 38px;
        line-height: 38px;
        overflow: hidden;
        text-align: center;
        padding: 0; }
        .OptInEmail .modal-content .modal-body .BtnSubscribe:hover, .OptInEmail .modal-content .modal-body .BtnSubscribe:focus, .OptInEmail .modal-content .modal-body .BtnSubscribe:active {
          text-decoration: none; }
        .OptInEmail .modal-content .modal-body .BtnSubscribe.subscribed span {
          top: 2px; }
        .OptInEmail .modal-content .modal-body .BtnSubscribe span {
          display: block;
          position: relative;
          transition: 0.3s;
          height: 100%;
          top: -34px;
          padding: 0 10px; }

@media all and (max-width: 480px) {
  .OptInEmail .modal-content {
    background-position: top right;
    min-width: 90%; }
    .OptInEmail .modal-content .modal-body .Title {
      font-size: 3rem; }
    .OptInEmail .modal-content .modal-body .SubTitle {
      font-size: 1.5rem; }
    .OptInEmail .modal-content .modal-body .Description {
      font-size: 0.8rem; } }

.HighlightedText {
  display: inline-block; }
  .HighlightedText.clickable {
    cursor: pointer; }
  .HighlightedText span {
    color: #ff443e; }
    .HighlightedText span.noTrade {
      color: #37af56; }
    .HighlightedText span:last-child {
      margin-right: 0; }

.ButtonCheckTMProduct {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #fff;
  padding: 1px 14px;
  z-index: 2;
  border-radius: 3px;
  cursor: pointer; }
  .ButtonCheckTMProduct .Icon {
    color: #37af56;
    display: inline-flex;
    min-width: 24px;
    align-items: center;
    justify-content: center; }
    .ButtonCheckTMProduct .Icon .linear-ellipsis {
      position: relative;
      top: 1px; }
  .ButtonCheckTMProduct.checked {
    opacity: 0.5;
    cursor: auto; }
  .ButtonCheckTMProduct:not(.checked) {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.22); }
  .ButtonCheckTMProduct.loading {
    cursor: auto; }
    .ButtonCheckTMProduct.loading .Icon {
      -webkit-animation: _dotLoading 1s linear infinite;
              animation: _dotLoading 1s linear infinite; }

@media all and (max-width: 480px) {
  .ModalShowItemDetailBody .ButtonCheckTMProduct {
    top: auto;
    left: 5px;
    bottom: 5px; } }

@-webkit-keyframes _dotLoading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes _dotLoading {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.LoadingComponent {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  display: none;
  opacity: 0;
  transition: all 200ms ease-out;
  z-index: 9; }
  .LoadingComponent .Middle {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center; }
  .LoadingComponent.loading {
    display: block;
    opacity: 1; }

.Home .jumbotron {
  background: transparent;
  padding: 20px 1rem; }

@media all and (max-width: 480px) {
  .Home .jumbotron h1 {
    font-size: 2rem; } }

.CountUp {
  font-size: 30px;
  font-weight: 500; }

.NotifyModal {
  font-size: 1rem; }
  .NotifyModal .modal-body {
    white-space: pre-line; }
  .NotifyModal .modal-footer button {
    cursor: pointer; }

.PageNotFound {
  margin: 100px auto; }
  .PageNotFound .Images .Number {
    color: #03A9F4;
    font-size: 210px;
    line-height: 1; }
  .PageNotFound .Images .Icon {
    width: 150px;
    height: auto;
    padding: 10px 20px; }

.ServerError .Images .Number {
  color: #f56400; }

.ServerError .Images .Number {
  font-size: 12rem;
  line-height: 1; }

.ServerError .Images .Icon {
  width: 180px;
  height: auto;
  padding: 10px 20px; }

.ServerError .Message {
  font-size: 0.8rem;
  margin-bottom: 20px; }
  .ServerError .Message code {
    max-height: 150px;
    overflow-y: auto;
    display: inline-block; }

.ServerError .Help {
  font-size: 1.2rem;
  font-weight: 500; }

@media all and (max-width: 480px) {
  .ServerError .Images .Number {
    font-size: 8rem; }
  .ServerError .Images .Icon {
    width: 140px; } }

#header {
  border-bottom: 1px solid #d5dee6; }
  #header > nav {
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 15px; }

.LoginPage {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh; }
  .LoginPage.noError .Header {
    margin-bottom: 30px; }
  .LoginPage .Background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -10;
    background: url("/assets/demos/screen-desktop.jpg") top center no-repeat;
    background-size: cover; }
    .LoginPage .Background:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2; }
    .LoginPage .Background video {
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: auto;
      position: relative;
      z-index: 1; }
  .LoginPage .WrapperBtnSocial .ButtonSocial {
    position: relative;
    color: #fff;
    border-color: rgba(0, 0, 0, 0.2);
    cursor: pointer; }
    .LoginPage .WrapperBtnSocial .ButtonSocial:before {
      font-family: Font Awesome\ 5 Brands, serif;
      position: absolute;
      left: 0;
      top: 8px;
      width: 36px;
      height: 25px;
      line-height: 25px;
      font-size: 0.9375rem;
      border-right: 1px solid rgba(255, 255, 255, 0.1); }
  .LoginPage .WrapperBtnSocial .facebook {
    background-color: #3b5998; }
    .LoginPage .WrapperBtnSocial .facebook:before {
      content: "\F39E"; }
  .LoginPage .WrapperBtnSocial .google {
    background-color: #dd4b39; }
    .LoginPage .WrapperBtnSocial .google:before {
      content: "\F1A0"; }
  .LoginPage .WrapperBtnFb {
    border-top: 1px solid #eee;
    width: 60%;
    margin: 20px auto;
    padding-top: 20px; }
    .LoginPage .WrapperBtnFb a {
      display: inline-block;
      color: #03A9F4;
      font-size: 16px; }
      .LoginPage .WrapperBtnFb a:not(:last-child) {
        margin-right: 40px; }
  .LoginPage .Wrapper {
    width: 610px;
    max-width: 100%;
    text-align: center;
    margin: auto;
    transition: 200ms all ease-out;
    position: relative; }
  .LoginPage .loginTool {
    border: 1px solid #eee;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px; }
  .LoginPage .Header {
    font-size: 1.625rem;
    font-weight: 400;
    margin-bottom: 20px;
    padding-top: 30px; }
  .LoginPage .LoginMethod {
    padding-bottom: 40px;
    border-radius: 5px; }
    .LoginPage .LoginMethod .Error {
      font-size: 0.875rem;
      color: #ff443e;
      background-color: rgba(255, 68, 62, 0.2);
      padding: 10px 20px;
      margin-bottom: 20px; }
    .LoginPage .LoginMethod .Description {
      font-size: 1rem;
      color: #808080; }
    .LoginPage .LoginMethod .WrapperBtnSocial {
      display: flex;
      justify-content: center;
      flex-wrap: wrap; }
      .LoginPage .LoginMethod .WrapperBtnSocial .ButtonSocial {
        flex: 0 0 164px;
        margin-top: 0;
        font-size: 0.9375rem;
        padding: 9px;
        border: unset;
        border-radius: 5px; }
        .LoginPage .LoginMethod .WrapperBtnSocial .ButtonSocial:not(:last-child) {
          margin-right: 20px; }
  .LoginPage .WrapperOtherTools {
    width: 60%;
    margin: 20px auto;
    padding-top: 20px;
    text-align: center; }
    .LoginPage .WrapperOtherTools .tool-item {
      display: inline-block;
      margin: 0 15px;
      width: 120px; }
      .LoginPage .WrapperOtherTools .tool-item img {
        max-width: 100%; }

@media (max-width: 480px) {
  .LoginPage .Background {
    background: url("/assets/demos/screen-mobile.jpg") top center no-repeat; }
  .LoginPage .Wrapper .LoginMethod .WrapperBtnSocial {
    display: block;
    text-align: center;
    margin: 0 30px; }
    .LoginPage .Wrapper .LoginMethod .WrapperBtnSocial .ButtonSocial {
      margin-right: 0;
      margin-bottom: 10px;
      display: inline-block;
      max-width: 200px; }
  .LoginPage .Wrapper .LoginMethod .WrapperBtnFb {
    margin: 0 auto; }
    .LoginPage .Wrapper .LoginMethod .WrapperBtnFb a:not(:last-child) {
      margin-right: 0; } }

.PricingPage {
  padding-top: 30px; }
  .PricingPage .Title {
    font-size: 1.625rem;
    margin-bottom: 33px; }
  .PricingPage .PlanCard {
    text-align: center;
    border: 1px solid #eee;
    border-radius: 5px;
    padding-top: 40px;
    padding-bottom: 45px;
    transition: box-shadow .3s;
    margin-bottom: 30px; }
    .PricingPage .PlanCard:hover {
      box-shadow: 8.5px 8.5px 24px 0 rgba(0, 0, 0, 0.06); }
    .PricingPage .PlanCard .FeaturedImg {
      width: 139px;
      height: 113px;
      background-size: contain;
      margin: auto; }
    .PricingPage .PlanCard.isFree .FeaturedImg {
      background-image: url("/assets/images/pricing-icon-1.png"); }
    .PricingPage .PlanCard:not(.isFree) .FeaturedImg {
      background-image: url("/assets/images/pricing-icon-2.png"); }
    .PricingPage .PlanCard .PricingHeader {
      position: relative;
      padding-bottom: 7px;
      margin-bottom: 25px; }
      .PricingPage .PlanCard .PricingHeader:after {
        content: "";
        width: 120px;
        height: 1px;
        position: absolute;
        background-color: #eee;
        bottom: 0;
        margin-left: -60px; }
      .PricingPage .PlanCard .PricingHeader .Name {
        font-size: 1.125rem;
        font-weight: 700;
        margin-top: 30px;
        margin-bottom: 12px; }
      .PricingPage .PlanCard .PricingHeader .Price {
        color: #f56400;
        font-weight: 700;
        margin-bottom: 5px;
        min-height: 60px; }
        .PricingPage .PlanCard .PricingHeader .Price .Number {
          font-size: 2.3125rem; }
          .PricingPage .PlanCard .PricingHeader .Price .Number .Origin {
            color: #adadad;
            text-decoration: line-through;
            font-size: 1.2rem;
            position: relative;
            left: 10px; }
        .PricingPage .PlanCard .PricingHeader .Price .PerMonth {
          font-size: 0.875rem; }
      .PricingPage .PlanCard .PricingHeader .Description {
        font-size: 1rem;
        color: #aaa; }
    .PricingPage .PlanCard .Features {
      font-size: 1rem;
      color: #808080;
      text-align: left;
      min-height: 120px; }
      .PricingPage .PlanCard .Features ul {
        list-style: none;
        margin-bottom: 23px;
        padding: 0 20px 0 40px; }
      .PricingPage .PlanCard .Features li:before {
        content: "\F120";
        font-family: Ionicons;
        color: #f56400;
        font-size: 0.875rem;
        margin-right: 10px; }
    .PricingPage .PlanCard .PricingButton a.btn {
      padding: 7px 12px;
      border: 1px solid #f56400;
      min-width: 122px;
      transition: box-shadow .3s; }
      .PricingPage .PlanCard .PricingButton a.btn:hover {
        box-shadow: 5.7px 5.7px 16px 0 rgba(0, 0, 0, 0.1); }
    .PricingPage .PlanCard .PricingButton > a:hover {
      color: #f56400; }
    .PricingPage .PlanCard .PricingButton .ButtonSubscription a:hover {
      background-color: #f56400; }

@media (min-width: 1200px) {
  .PricingPage .container {
    width: 1130px;
    max-width: 100%; } }

@media all and (max-width: 480px) {
  .PricingPage .GoHome {
    margin-bottom: 30px; } }

.ShellBankTransfer .TextTransfer {
  font-weight: 420;
  font-size: 21px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Open Sans", sans-serif; }

.ImgTransfer {
  height: 70px;
  width: 70px;
  float: right;
  margin-top: -20px; }

.EmptyCart {
  text-align: center;
  margin: 0 auto;
  padding: 50px; }

.CheckoutPage {
  margin: 30px 0;
  padding: 10px 0; }
  .CheckoutPage .CurrentMembership {
    padding: 20px;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #808080;
    font-size: 1rem;
    text-align: center; }
    .CheckoutPage .CurrentMembership .Title {
      color: #333;
      font-size: 1.4rem; }
    .CheckoutPage .CurrentMembership .Icon {
      color: #37af56;
      font-size: 5rem; }
    .CheckoutPage .CurrentMembership strong {
      color: #333; }
    .CheckoutPage .CurrentMembership .Membership {
      margin-bottom: 20px; }
  .CheckoutPage .StripeAddCard {
    position: relative; }
    .CheckoutPage .StripeAddCard:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.01);
      opacity: 0.2;
      z-index: 9;
      transition: all 300ms ease-in-out; }
    .CheckoutPage .StripeAddCard.ready:after {
      z-index: -1;
      display: none; }
    .CheckoutPage .StripeAddCard form {
      max-width: 500px; }
      .CheckoutPage .StripeAddCard form legend {
        display: block;
        margin-bottom: .5rem;
        position: relative;
        width: 100%;
        letter-spacing: .06rem;
        font-size: .8rem;
        color: #bbb;
        text-transform: uppercase;
        overflow: hidden; }
        .CheckoutPage .StripeAddCard form legend:after {
          content: "";
          width: 100%;
          position: absolute;
          height: 1px;
          top: .5rem;
          margin-left: .25rem;
          background: #dfdfdf; }
      .CheckoutPage .StripeAddCard form .Error {
        color: #ed4f32;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 21px;
        min-height: 21px;
        margin-top: 1rem; }
      .CheckoutPage .StripeAddCard form .Name {
        margin-bottom: 0;
        font-size: 1rem; }
    .CheckoutPage .StripeAddCard .StripeElement, .CheckoutPage .StripeAddCard .Name {
      margin: 10px 0 0 0;
      max-width: 500px;
      border-radius: 4px;
      background: white;
      padding: 10px 14px;
      width: 100%;
      border: 1px solid #dfdfdf;
      font-size: 1rem;
      color: #444;
      transition: all .2s ease-in-out;
      outline: 0;
      display: inline-block;
      text-align: left; }
      .CheckoutPage .StripeAddCard .StripeElement::-webkit-input-placeholder, .CheckoutPage .StripeAddCard .Name::-webkit-input-placeholder {
        color: #aab7c4; }
      .CheckoutPage .StripeAddCard .StripeElement::-ms-input-placeholder, .CheckoutPage .StripeAddCard .Name::-ms-input-placeholder {
        color: #aab7c4; }
      .CheckoutPage .StripeAddCard .StripeElement::placeholder, .CheckoutPage .StripeAddCard .Name::placeholder {
        color: #aab7c4; }
    .CheckoutPage .StripeAddCard .StripeElement--focus {
      transition: all 150ms ease; }
    .CheckoutPage .StripeAddCard .InputElement {
      font-size: 16px;
      height: 50px; }
  .CheckoutPage .StripeCard {
    background-color: #eee;
    border-radius: 5px;
    padding: 20px;
    max-width: 350px;
    position: relative; }
    .CheckoutPage .StripeCard .Brand {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 10px; }
    .CheckoutPage .StripeCard .Number {
      margin-bottom: 20px; }
    .CheckoutPage .StripeCard .Expires {
      font-size: 0.8rem;
      color: #666; }
      .CheckoutPage .StripeCard .Expires .Label {
        color: #999;
        margin-right: 10px; }
    .CheckoutPage .StripeCard .Remove {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 10px;
      display: none; }
    .CheckoutPage .StripeCard:hover .Remove {
      display: inline-block; }
  .CheckoutPage .CurrentPlan {
    background-color: #fff;
    padding: 1.25rem;
    border-radius: 5px;
    box-shadow: rgba(67, 90, 111, 0.416) 0 0 1px, rgba(67, 90, 111, 0.3) 0 2px 4px -2px;
    margin-bottom: 30px; }
    .CheckoutPage .CurrentPlan .Title {
      margin-bottom: 1rem; }
    .CheckoutPage .CurrentPlan .Table {
      width: 100%;
      margin-bottom: 30px; }
      .CheckoutPage .CurrentPlan .Table .Header {
        color: #585f65;
        font-size: 0.9rem; }
      .CheckoutPage .CurrentPlan .Table .Main .Price {
        font-weight: 500; }
  .CheckoutPage .PaymentMethods {
    background-color: #fff;
    padding: 1.25rem;
    border-radius: 5px;
    box-shadow: rgba(67, 90, 111, 0.416) 0 0 1px, rgba(67, 90, 111, 0.3) 0 2px 4px -2px; }
    .CheckoutPage .PaymentMethods .HeaderGateway {
      display: flex;
      align-items: center;
      margin-bottom: 20px; }
      .CheckoutPage .PaymentMethods .HeaderGateway label {
        cursor: pointer;
        margin: 0;
        font-size: 1.25rem; }
      .CheckoutPage .PaymentMethods .HeaderGateway .InputMethod {
        margin-right: 10px;
        cursor: pointer;
        position: relative;
        top: -3px; }
    .CheckoutPage .PaymentMethods .Name {
      font-size: 1.2rem;
      margin-bottom: 1rem; }
    .CheckoutPage .PaymentMethods > *:last-child {
      border-bottom: none;
      padding-bottom: 0; }
  .CheckoutPage .ButtonCheckout .Cancel {
    margin-right: 10px;
    color: #999; }
    .CheckoutPage .ButtonCheckout .Cancel:hover {
      color: #333; }
  .CheckoutPage .OrderTotal {
    margin-bottom: 20px; }
    .CheckoutPage .OrderTotal .Discount {
      font-size: 1rem;
      color: #666; }
    .CheckoutPage .OrderTotal .Total {
      font-size: 1.2rem;
      font-weight: 500; }
    .CheckoutPage .OrderTotal .Label {
      margin-right: 10px; }
  .CheckoutPage .RedeemCoupon .Text {
    color: #9e9e9e;
    cursor: pointer;
    font-size: 0.9rem;
    margin-bottom: 10px; }
  .CheckoutPage .RedeemCoupon form {
    margin-bottom: 10px;
    flex-flow: row nowrap; }
    .CheckoutPage .RedeemCoupon form input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none; }
    .CheckoutPage .RedeemCoupon form button {
      height: 35px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .CheckoutPage .RedeemCoupon.open form {
    display: flex; }
  .CheckoutPage .RedeemCoupon .CouponCode {
    background-color: #666;
    border-radius: 5px;
    padding: 0 15px 0 10px;
    display: inline-block;
    margin-bottom: 10px;
    position: relative; }
    .CheckoutPage .RedeemCoupon .CouponCode .Code {
      color: #fff;
      margin-right: 7px;
      font-size: 1.2rem;
      vertical-align: middle; }
      .CheckoutPage .RedeemCoupon .CouponCode .Code .Text {
        cursor: auto;
        font-size: 0.9rem;
        border-left: 1px solid #a9a9a9;
        color: #a9a9a9;
        margin-left: 10px;
        padding-left: 5px; }
    .CheckoutPage .RedeemCoupon .CouponCode .Close {
      color: #eee;
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      cursor: pointer;
      padding: 0 9px; }
      .CheckoutPage .RedeemCoupon .CouponCode .Close:hover {
        color: #ed4f32; }
  .CheckoutPage .RedeemCoupon .Error {
    color: #ed4f32;
    border-left: 2px solid #ed4f32;
    padding-left: 10px; }
    .CheckoutPage .RedeemCoupon .Error .Message {
      font-size: 0.9rem; }
  .CheckoutPage .StripeGateway {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    background: url("/assets/images/powered_by_stripe.svg") right 0 top 0 no-repeat; }
  .CheckoutPage .PayPalGateway {
    background: url("/assets/images/paypal-badges.png") no-repeat top right;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee; }
    .CheckoutPage .PayPalGateway .HeaderGateway {
      padding-bottom: 20px; }
    .CheckoutPage .PayPalGateway .Description {
      color: #515151; }
  @media all and (max-width: 1400px) {
    .CheckoutPage .PayPalGateway {
      background-size: 50%; } }
  .CheckoutPage .PayoneerGateway a {
    margin-left: 4px; }
  .CheckoutPage h1 {
    font-size: 1.6rem;
    margin: 1rem 0 2rem; }
  .CheckoutPage .CheckoutFailed {
    color: #ed4f32;
    font-size: 1rem;
    margin-bottom: 20px;
    line-height: 21px;
    min-height: 21px;
    background-color: #ffcdd2;
    border-left: 3px solid #ed4f32;
    padding: 10px 20px;
    border-radius: 5px; }
    .CheckoutPage .CheckoutFailed .Goto {
      margin-top: 20px; }
      .CheckoutPage .CheckoutFailed .Goto .Pricing {
        margin-right: 10px; }
      .CheckoutPage .CheckoutFailed .Goto .Billing {
        color: #333; }
  .CheckoutPage .Title {
    font-size: 1.4rem;
    margin-bottom: 1rem; }
  .CheckoutPage .GoHome {
    font-size: 0.875rem;
    margin: 30px auto; }
    .CheckoutPage .GoHome a {
      color: #999; }

.NotificationBar {
  background-color: #f56400;
  color: #fff;
  text-align: center;
  font-size: 16px;
  line-height: 28px;
  z-index: 99999999;
  -webkit-animation: 200ms all ease-in-out;
          animation: 200ms all ease-in-out;
  padding: 7px 10px; }
  .NotificationBar .Close {
    display: none; }
  .NotificationBar.hidden {
    height: 0;
    display: none !important; }
  .NotificationBar .Text {
    margin-right: 20px; }
  .NotificationBar .Action {
    font-size: 14px;
    line-height: 15px;
    color: #333;
    font-weight: 600;
    background-color: #fff;
    border-radius: 6px;
    border: none;
    padding: 10px 30px;
    margin-right: 10px; }
  .NotificationBar .Ignore {
    color: #ccc;
    font-size: 14px;
    text-decoration: none; }
    .NotificationBar .Ignore:hover {
      color: #fff; }

@media all and (max-width: 480px) {
  body .NotificationBar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    color: #333;
    border-top: 1px solid #eee;
    padding: 12px;
    transition: all 300ms ease-out; }
    body .NotificationBar.hidden {
      display: block !important;
      height: unset;
      -webkit-transform: translateY(100%);
              transform: translateY(100%); }
    body .NotificationBar .Inner {
      display: block !important;
      position: relative; }
      body .NotificationBar .Inner:before {
        position: absolute;
        top: 5px;
        left: 0;
        content: "\E8AE";
        font-family: LinearIcons, serif;
        z-index: 1;
        color: #f56400;
        font-size: 1.2rem; }
    body .NotificationBar .Close {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -10px;
      z-index: 9;
      padding: 0 10px;
      font-size: 1.2rem; }
    body .NotificationBar .Actions {
      display: flex;
      flex-direction: row-reverse; }
      body .NotificationBar .Actions .Ignore {
        display: none; }
      body .NotificationBar .Actions .Action {
        margin-right: 0;
        background-color: #f56400;
        color: #fff;
        border-radius: 3px;
        font-size: 0.9rem;
        line-height: 1.5;
        padding: 6px 10px 4px;
        font-weight: 400; }
        body .NotificationBar .Actions .Action:focus {
          box-shadow: none !important; }
    body .NotificationBar .Text {
      font-size: 0.875rem;
      margin-right: 0;
      margin-bottom: 10px;
      padding: 0 30px;
      text-align: left; } }

.ModalAddToFavorites .modal-title {
  position: relative;
  display: block;
  width: 100%; }
  .ModalAddToFavorites .modal-title .Close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer; }

.ModalAddToFavorites .modal-body {
  padding: 0 20px; }

.ModalAddToFavorites .ProductPreview {
  height: 100%;
  padding: 20px;
  min-height: 300px; }
  .ModalAddToFavorites .ProductPreview .Wrapper {
    max-width: 90%;
    margin: 0 auto; }
    .ModalAddToFavorites .ProductPreview .Wrapper .Name {
      margin-top: 20px;
      text-align: center; }

@media all and (max-width: 480px) {
  .ModalAddToFavorites .modal-body {
    padding: 0 15px; }
  .ModalAddToFavorites .ProductPreview {
    display: none; } }

.ButtonAddFavorites {
  top: 5px;
  right: 5px;
  position: absolute;
  padding: 4px 7px;
  font-size: 21px;
  line-height: 24px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  transition: all 300ms ease-out;
  opacity: 0;
  display: inline-block;
  z-index: 9; }
  .ButtonAddFavorites .Text {
    display: none; }
  .ButtonAddFavorites i {
    transition: all 300ms ease-out;
    -webkit-filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4));
            filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.4)); }
    .ButtonAddFavorites i:hover {
      -webkit-transform: scale(1.15);
              transform: scale(1.15); }

.Item:hover .ButtonAddFavorites {
  opacity: 1; }

.ItemTable .ButtonAddFavorites .Text {
  display: none; }

@media all and (max-width: 480px) {
  .ButtonAddFavorites {
    opacity: 1; }
    .ButtonAddFavorites .Text {
      display: none; } }

.ListCategoryFavorites {
  min-height: 300px;
  max-height: 430px;
  overflow-y: auto;
  padding: 20px; }
  .ListCategoryFavorites .FormSearchCategories {
    margin-bottom: 20px; }
    .ListCategoryFavorites .FormSearchCategories .Name {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      line-height: 24px;
      height: auto; }
    .ListCategoryFavorites .FormSearchCategories .BtnCreate {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      line-height: 24px; }
  .ListCategoryFavorites .Categories {
    list-style: none;
    padding: 0; }
    .ListCategoryFavorites .Categories .Category {
      transition: all 200ms ease-out; }
      .ListCategoryFavorites .Categories .Category:hover {
        color: #f56400; }
      .ListCategoryFavorites .Categories .Category .Left {
        cursor: pointer;
        padding: 5px 0; }

.dropdown-menu {
  max-height: 500px;
  overflow: auto;
  margin: 0;
  padding: 0; }

.AdsAnnouncement {
  margin-right: 15px; }
  .AdsAnnouncement button {
    position: relative;
    background: none;
    color: #000000;
    border: none;
    padding: 0; }
    .AdsAnnouncement button i {
      font-size: 25px;
      line-height: 36px; }
    .AdsAnnouncement button .count {
      background: red;
      border-radius: 3px;
      position: absolute;
      top: -3px;
      left: 12px;
      color: #ffffff;
      font-size: 13px;
      padding: 0px 5px; }
    .AdsAnnouncement button:hover, .AdsAnnouncement button:active, .AdsAnnouncement button:focus {
      background: none !important;
      color: #000000 !important;
      border: none !important;
      box-shadow: none !important; }
    .AdsAnnouncement button:hover i {
      color: #4267b2; }
  .AdsAnnouncement .AdsAnnouncementList {
    width: 350px;
    margin: 0;
    padding: 0; }
    .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem {
      list-style: none;
      padding: 20px 15px 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15); }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem.read {
        opacity: 0.5; }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem .AnnouncementItemBox .title {
        font-size: 18px;
        font-weight: 600;
        color: #000000;
        margin: 0 0 15px; }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem .AnnouncementItemBox .description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        /* autoprefixer: off */
        overflow: hidden;
        font-size: 14px;
        margin: 0 0 5px; }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem .AnnouncementItemBox .time {
        font-size: 12px; }
        .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem .AnnouncementItemBox .time i {
          margin-right: 5px; }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem .AnnouncementItemBox .NoNotifications i {
        font-size: 2rem; }
      .AdsAnnouncement .AdsAnnouncementList .AdsAnnouncementItem:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05); }

.popup-announcement .modal-closer {
  position: absolute;
  top: 0px;
  right: 10px;
  text-align: center; }
  .popup-announcement .modal-closer i {
    font-size: 25px;
    font-weight: bold;
    line-height: 40px;
    transition: all 0.3s ease 0s; }
  .popup-announcement .modal-closer:hover {
    cursor: pointer; }
    .popup-announcement .modal-closer:hover i {
      color: red; }

.Wrapper .AdsAnnouncement {
  display: none; }

.CopyLicenseCode {
  position: relative; }
  .CopyLicenseCode i {
    transition: all 0.2s ease; }
  .CopyLicenseCode:hover i {
    scale: 1.2; }
  .CopyLicenseCode .CopiedNoti {
    padding: 5px;
    position: absolute;
    top: -150%;
    left: -100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    color: #005bbb;
    display: none; }
    .CopyLicenseCode .CopiedNoti.Active {
      display: block; }

.TableItemsFooter {
  margin-bottom: 40px; }

.SearchItemsPage {
  height: unset !important; }
  .SearchItemsPage .ItemsExplorerHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 72px;
    background-color: white;
    padding-bottom: 10px; }
    .SearchItemsPage .ItemsExplorerHeader .Top::before {
      border-top: 20px solid white; }
  .SearchItemsPage .TableListItems {
    padding-top: 0; }

.DashboardIcon {
  display: inline-block;
  position: relative; }

.MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) {
  line-height: unset !important; }
  .MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) span {
    line-height: 55px; }
  .MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) img {
    margin-top: -5px;
    margin-left: -50px; }
  .MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) .nav-link .DashboardIcon.Blue {
    display: none; }
  .MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) .nav-link:hover .DashboardIcon.Blue {
    display: inline-block !important; }
  .MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item:nth-child(1) .nav-link:hover .DashboardIcon.Orange {
    display: none !important; }

.MobileMenu .bm-menu-wrap .bm-menu .bm-item-list .Menu .nav-item .Text.NewDashboard {
  margin-left: 25px; }

.SidebarDashboard .nav .Text.NewDashboard {
  margin-left: 20px; }

.SidebarDashboard .nav .nav-item:nth-child(2) {
  line-height: unset !important; }
  .SidebarDashboard .nav .nav-item:nth-child(2) span {
    line-height: 55px; }
  .SidebarDashboard .nav .nav-item:nth-child(2) img {
    margin-top: -5px; }
  .SidebarDashboard .nav .nav-item:nth-child(2) .nav-link:hover .DashboardIcon:nth-child(1) {
    display: inline-block !important; }
  .SidebarDashboard .nav .nav-item:nth-child(2) .nav-link:hover .DashboardIcon:nth-child(2) {
    display: none !important; }

.NewHomePageHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99999;
  padding: 0 24px;
  height: 72px;
  background-color: #f56400; }
  .NewHomePageHeader p {
    margin-bottom: 0; }
  .NewHomePageHeader .NewHomePageMenuWrap {
    grid-gap: 40px;
    gap: 40px; }
    .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageMenuIcon {
      height: 100%;
      line-height: 72px;
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      cursor: pointer;
      -webkit-transform: all 0.5s ease;
              transform: all 0.5s ease; }
      .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageMenuIcon.Active {
        -webkit-transform: rotate(0);
                transform: rotate(0); }
    .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails {
      grid-gap: 30px;
      gap: 30px; }
      .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails .NewHomePageDeTailsText {
        padding-top: 10px; }
        .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails .NewHomePageDeTailsText p {
          margin: 0;
          color: #ffffff;
          font-weight: 600;
          font-size: 14px; }
        .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails .NewHomePageDeTailsText span {
          color: #ffbcaf;
          font-size: 24px;
          font-weight: 600; }
      .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails .NewHomePageDeTailsBtn {
        background-color: #ffffff;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 32px;
        padding: 0 20px;
        line-height: 32px;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: #ffebc6;
        border: none;
        transition: all 0.2s ease; }
        .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails .NewHomePageDeTailsBtn:hover {
          background-color: white;
          color: #005bbb; }
  .NewHomePageHeader .NewHomePageUser {
    grid-gap: 24px;
    gap: 24px;
    color: #ffffff; }
    .NewHomePageHeader .NewHomePageUser .AdsAnnouncement .dropdown .btn .linear-alarm {
      color: white; }
    .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap {
      position: relative; }
      .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .NewHomePageUserInfo {
        cursor: pointer;
        grid-gap: 10px;
        gap: 10px; }
        .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .NewHomePageUserInfo .NewHomePageAccount {
          grid-gap: 10px;
          gap: 10px; }
          .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .NewHomePageUserInfo .NewHomePageAccount img {
            display: block;
            width: auto;
            height: 40px;
            object-fit: cover;
            border-radius: 50%; }
          .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .NewHomePageUserInfo .NewHomePageAccount .NewHomePageAccountName p {
            margin: auto 0; }
      .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu {
        display: none;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; }
        .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active {
          display: block;
          position: absolute;
          top: calc(100% + 4px);
          width: 360px;
          right: 0;
          color: #303238;
          background-color: #ffffff;
          box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.08), 0px 6px 16px rgba(0, 0, 0, 0.06), 0px 9px 32px 8px rgba(0, 0, 0, 0.04);
          padding: 16px;
          border-radius: 8px;
          z-index: 999999; }
          .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail {
            position: relative; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail h4 {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail .UserInfoEmail {
              display: flex;
              grid-gap: 4px;
              gap: 4px; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail .UserInfoEmail p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #65676b; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail .UserInfoEmail span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f56400;
                text-decoration: underline;
                cursor: pointer;
                transition: all 0.2s ease; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail .UserInfoEmail span:hover {
                  color: #005bbb; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .UserInfoNameEmail::after {
              content: "";
              position: absolute;
              width: 100%;
              top: calc(100% + 16px);
              left: 0;
              height: 1px;
              background-color: #b0b7bf; }
          .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription {
            margin-top: 32px; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription h4 p {
                margin-left: 20px;
                font-weight: 400;
                display: inline;
                color: #65676b; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription h4 span {
                display: inline-block;
                padding: 0 8px;
                width: -webkit-fit-content;
                width: -moz-fit-content;
                width: fit-content;
                height: 32px;
                text-align: center;
                line-height: 32px;
                font-weight: 500;
                font-size: 14px;
                color: #f56400;
                background-color: #e5f0fd; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade {
              display: flex;
              justify-content: space-between;
              align-items: center; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .TimeToRenews p {
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #65676b; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink {
                text-decoration: none;
                display: flex;
                align-items: center;
                grid-gap: 4px;
                gap: 4px; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink:hover .ImageUpgradeOne {
                  display: block; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink:hover .ImageUpgradeTwo {
                  display: none; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink:hover p {
                  color: #005bbb; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink .ImageUpgradeOne {
                  display: none; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink p {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #f56400; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .Upgrade .UpgradePlanLink img {
                  width: 16px;
                  height: 16px;
                  object-fit: cover; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .TimeToRenewsProgress {
              margin-top: 8px;
              width: 100%;
              height: 8px;
              border-radius: 8px;
              background-color: #dee2e6;
              position: relative; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .TimeToRenewsProgress .Progress {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #f56400;
                border-radius: 8px; }
            .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList {
              margin-top: 16px; }
              .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList .UserInfoPageItem {
                color: inherit;
                text-decoration: none;
                display: flex;
                margin-bottom: 16px;
                grid-gap: 8px;
                gap: 8px;
                align-items: center;
                transition: all 0.3s ease; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList .UserInfoPageItem:hover {
                  color: #f56400; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList .UserInfoPageItem:nth-last-child(1) {
                  margin-bottom: unset; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList .UserInfoPageItem img {
                  width: 20px;
                  height: 20px; }
                .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserInfoMenu.Active .Subscription .UserInfoPageList .UserInfoPageItem p {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px; }
      .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserMenuOverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 99999; }
        .NewHomePageHeader .NewHomePageUser .NewHomePageUserInfoWrap .UserMenuOverlay.Active {
          display: block; }

.NewDashboard {
  height: 100%; }
  .NewDashboard .NewDashboardContainer {
    margin: 50px 24px;
    height: 100%;
    background-color: white;
    display: flex;
    grid-gap: 50px;
    gap: 50px; }
    .NewDashboard .NewDashboardContainer .LicenseCodeContainer {
      background-color: white;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      border: 3px solid rgba(210, 105, 30, 0.719);
      border-radius: 10px;
      padding: 30px; }
      .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader {
        padding: 20px;
        padding-top: 50px;
        text-align: center; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader p {
          font-size: 20px;
          font-weight: 600; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader img {
          width: 40px;
          height: 40px;
          object-fit: contain; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader .LicenseNoti {
          display: flex;
          align-items: center;
          justify-content: center;
          grid-gap: 10px;
          gap: 10px; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader .LicenseNoti span {
            display: inline-block;
            color: #005bbb;
            font-size: 24px;
            line-height: 40px;
            height: 100%;
            font-weight: 500; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseHeader .LicenseNoti span.ColorRed {
              color: #e21717; }
      .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode {
        border-radius: 10px; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode {
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          grid-gap: 10px;
          gap: 10px;
          padding: 0 30px; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            grid-gap: 5px;
            gap: 5px;
            flex: 1 1; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .RequiredText {
              display: block;
              margin-top: 10px;
              color: red; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: center; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel {
                display: flex; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel label {
                  font-weight: 600;
                  margin-right: 5px;
                  margin-bottom: 0;
                  opacity: 0.8; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel .MoreInfo {
                  position: relative;
                  margin: 0 5px; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel .MoreInfo img {
                    width: 20px;
                    height: 20px;
                    object-fit: cover;
                    opacity: 0.6;
                    cursor: pointer; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel .MoreInfo .MoreInfoPopUp {
                    display: none;
                    position: absolute;
                    bottom: 100%;
                    height: -webkit-fit-content;
                    height: -moz-fit-content;
                    height: fit-content;
                    width: 220px;
                    right: 0;
                    font-size: 14px;
                    border: 2px solid #83d5f5;
                    padding: 5px 20px;
                    border-radius: 8px;
                    background-color: white; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputLabel .MoreInfo:hover .MoreInfoPopUp {
                    display: block; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit {
                flex: 1 1;
                display: flex;
                grid-gap: 5px;
                gap: 5px; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit .CreateCodeInputContent {
                  flex: 1 1;
                  display: flex;
                  flex-direction: column; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit .CreateCodeInputContent input {
                    flex: 1 1;
                    outline: none;
                    border: none;
                    padding: 5px;
                    border-radius: 5px;
                    border: 2px solid rgba(0, 0, 0, 0.137); }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit .CreateCodeSubmit {
                  height: 100%; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit .CreateCodeSubmit button {
                    min-width: 100px;
                    height: 41px;
                    background-color: rgba(0, 0, 255, 0.726);
                    border: none;
                    opacity: 0.8;
                    font-weight: 500;
                    transition: all 0.2s ease; }
                    .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit .CreateCodeSubmit button:hover {
                      opacity: 1; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateMoreCode {
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            border-radius: 5px 5px 0 0;
            position: relative;
            transition: all 0.2s ease; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateMoreCode:hover {
              background-color: rgba(0, 0, 0, 0.164);
              border-radius: 5px; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateMoreCode.Active {
              background-color: rgba(0, 0, 0, 0.164); }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateMoreCode img {
              width: 35px;
              height: 35px;
              object-fit: contain;
              cursor: pointer; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap {
          position: relative;
          top: 100%;
          min-height: 400px;
          margin: 0 auto;
          margin-bottom: 60px;
          margin-top: 30px;
          border-radius: 10px 10px 10px 10px;
          background-color: #f4f4f5;
          transition: all 0.2s ease;
          padding: 0 10px; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap.Active {
            margin-top: -10px; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap.Active .ManageKeys {
              opacity: 1; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys {
            padding: 20px;
            width: 100%;
            background-color: #f4f4f5;
            opacity: 0.6; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys.Active {
              opacity: 1; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys p {
              font-weight: 600;
              color: blue; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey {
              display: flex;
              align-items: center;
              grid-gap: 10px;
              gap: 10px;
              flex-wrap: wrap; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey label {
                margin-bottom: 0;
                font-weight: 600;
                opacity: 0.8;
                display: block; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey .CustomKeyInput {
                flex: 1 1;
                display: flex;
                grid-gap: 5px;
                gap: 5px;
                align-items: center;
                flex-wrap: wrap; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey .CustomKeyInput input {
                  flex: 1 1;
                  outline: none;
                  border: none;
                  padding: 5px;
                  border-radius: 5px;
                  border: 2px solid rgba(0, 0, 0, 0.137); }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey .CustomKeyBtn button {
                min-width: 100px;
                height: 40px;
                background-color: #007bff;
                border: none;
                opacity: 0.6;
                font-weight: 600;
                transition: all 0.2s ease; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey .CustomKeyBtn button:hover {
                  opacity: 1; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .RequiredText {
              display: block;
              margin-top: 10px;
              color: red; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList {
              padding: 50px 30px 30px 30px; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem {
                display: flex;
                grid-gap: 5px;
                gap: 5px;
                align-items: center; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem img {
                  margin-top: 5px;
                  width: 20px;
                  height: 20px; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem .CustomKeyInfoWrap {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  grid-gap: 20px;
                  gap: 20px; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem .CustomKeyInfoWrap .CustomKeyInfo {
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    text-overflow: ellipsis; }
                    .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem .CustomKeyInfoWrap .CustomKeyInfo .CustomKeyName {
                      margin-top: 5px;
                      max-width: 100%;
                      font-size: 15px;
                      font-weight: 600;
                      opacity: 0.8;
                      color: #333;
                      margin-bottom: 0; }
                    .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem .CustomKeyInfoWrap .CustomKeyInfo .CustomKeyTimeCreated p {
                      font-size: 14px;
                      font-weight: 600;
                      opacity: 0.6;
                      color: #333;
                      margin-bottom: 0; }
                  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKeyList .CustomKeyItem .CustomKeyInfoWrap .CustomKeyInfoDelete {
                    display: flex;
                    align-items: center;
                    cursor: pointer; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeysOverlay {
            position: absolute;
            bottom: 0;
            right: 0;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.315);
            border-radius: 10px 10px 10px 10px; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeysOverlay .ManageKeysOverlayContent {
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transform: translateY(-50%) translateX(-50%);
                      transform: translateY(-50%) translateX(-50%);
              padding: 20px 40px;
              background-color: white;
              text-align: center;
              border-radius: 10px; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeysOverlay .ManageKeysOverlayContent p {
                margin-bottom: 0;
                font-weight: 500; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeysOverlay .ManageKeysOverlayContent a {
                text-decoration: underline;
                font-weight: 500; }
                .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeysOverlay .ManageKeysOverlayContent a:hover {
                  color: blue; }
      .NewDashboard .NewDashboardContainer .LicenseCodeContainer .ManageLicence {
        width: 70%;
        margin: 0 auto;
        border: none;
        border-radius: 10px; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .ManageLicence .right .LicenseName {
          height: 100%;
          display: flex;
          align-items: center; }
      .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide {
        margin: 0 auto;
        color: white;
        position: relative;
        border-radius: 10px;
        margin-top: 50px; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide input {
          display: none; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide input:checked ~ .LicenseGuideHeader {
            border-radius: 10px 10px 0 0;
            border: 4px solid gray;
            padding: 0 20px; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide input:checked ~ .LicenseGuideHeader label .plus {
              display: none; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide input:checked ~ .LicenseGuideHeader label .minus {
              display: block; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide input:checked ~ .LicenseGuideContent {
            display: block; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader {
          background-color: rgba(0, 0, 255, 0.5);
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          z-index: 999; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader label {
            margin-bottom: 0; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader label .minus {
              display: none; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            cursor: pointer; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader span {
            margin-left: 20px;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: white; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideHeader span b {
              color: orange; }
        .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideContent {
          display: none;
          position: relative;
          z-index: 1;
          padding: 20px;
          background-color: rgba(0, 0, 255, 0.664);
          font-size: 14px;
          font-weight: 600;
          border-radius: 0 0 10px 10px; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideContent p {
            line-height: 20px; }
            .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideContent p a {
              color: inherit;
              text-decoration: underline; }
              .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideContent p a:hover {
                color: orange; }
          .NewDashboard .NewDashboardContainer .LicenseCodeContainer .LicenseGuide .LicenseGuideContent img {
            width: 15px;
            height: 15px;
            object-fit: cover; }
    .NewDashboard .NewDashboardContainer .TrialDownload {
      width: 40%;
      padding: 50px; }
      .NewDashboard .NewDashboardContainer .TrialDownload a {
        color: #333;
        text-decoration: underline;
        font-size: 24px;
        font-weight: 500;
        transition: all 0.2s ease; }
        .NewDashboard .NewDashboardContainer .TrialDownload a:hover {
          color: #f56400; }
      .NewDashboard .NewDashboardContainer .TrialDownload p {
        margin-top: 30px;
        opacity: 0.6;
        font-weight: 500;
        transition: all 0.2s ease; }
        .NewDashboard .NewDashboardContainer .TrialDownload p:hover {
          opacity: 1; }
      .NewDashboard .NewDashboardContainer .TrialDownload h3 {
        margin-top: 100px;
        font-size: 24px;
        font-weight: 500; }
      .NewDashboard .NewDashboardContainer .TrialDownload .DownloadList {
        margin-top: 30px;
        display: flex;
        grid-gap: 20px;
        gap: 20px; }
        .NewDashboard .NewDashboardContainer .TrialDownload .DownloadList .DownloadItem {
          opacity: 0.4;
          cursor: not-allowed;
          transition: all 0.2s ease; }
          .NewDashboard .NewDashboardContainer .TrialDownload .DownloadList .DownloadItem:nth-child(1) {
            opacity: 1;
            cursor: pointer; }
            .NewDashboard .NewDashboardContainer .TrialDownload .DownloadList .DownloadItem:nth-child(1):hover {
              scale: 1.1; }
          .NewDashboard .NewDashboardContainer .TrialDownload .DownloadList .DownloadItem img {
            width: 50px;
            height: 50px; }

@media (max-width: 479px) {
  .NewHomePageHeader {
    display: none !important; }
  .Wrapper .AdsAnnouncement {
    display: block; } }

@media (max-width: 990px) {
  .NewHomePageHeader .NewHomePageMenuWrap .NewHomePageDeTails {
    display: none !important; }
  .NewDashboard .NewDashboardContainer {
    flex-wrap: wrap; }
    .NewDashboard .NewDashboardContainer .LicenseCodeContainer {
      order: 2;
      width: 100%; }
      .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit {
        padding: 0;
        flex-wrap: wrap; }
    .NewDashboard .NewDashboardContainer .TrialDownload {
      width: 100%;
      order: 1; } }

@media (max-width: 777px) {
  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput {
    flex-direction: column;
    align-items: flex-start; }
    .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .CreateCode .CreateCodeLeft .CreateCodeInput .CreateCodeInputContentSubmit {
      width: 100%;
      justify-content: center; }
  .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey {
    flex-direction: column;
    align-items: flex-start; }
    .NewDashboard .NewDashboardContainer .LicenseCodeContainer .NewLicenseCode .NewLicenseCodeBody .ManageKeysWrap .ManageKeys .CustomKey .CustomKeyInput {
      width: 100%;
      justify-content: center; } }

.Dashboard {
  display: flex;
  flex: 1 1;
  overflow-y: auto; }
  .Dashboard.collapsed .SidebarDashboard {
    width: 100px;
    padding: 0; }
    .Dashboard.collapsed .SidebarDashboard .nav .nav-link {
      padding: 10px 0;
      text-align: center; }
    .Dashboard.collapsed .SidebarDashboard .nav .Logo {
      background-size: 73px 40px; }
    .Dashboard.collapsed .SidebarDashboard .nav .Text, .Dashboard.collapsed .SidebarDashboard .nav .Copyright {
      display: none; }
    .Dashboard.collapsed .SidebarDashboard .nav i {
      display: block;
      position: static; }
    .Dashboard.collapsed .SidebarDashboard .nav .CollapseMenu .Close {
      display: none; }
    .Dashboard.collapsed .SidebarDashboard .nav .CollapseMenu .Open {
      display: block; }
    .Dashboard.collapsed .SidebarDashboard .UserMenu {
      width: 94px;
      text-align: center;
      font-size: 0;
      left: 0; }
      .Dashboard.collapsed .SidebarDashboard .UserMenu .avatar {
        margin: 0; }

@media all and (max-width: 1440px) {
  .Dashboard.collapsed .SidebarDashboard .nav .Logo {
    background-size: 73px 40px;
    margin-top: 0;
    margin-bottom: 20px; } }

@media all and (max-width: 480px) {
  .Dashboard {
    display: block; } }

.MainDashboard {
  background-color: #fdfdfd;
  transition: all 0.3s;
  height: 100vh;
  overflow-y: auto;
  flex-grow: 1; }

@media only screen and (min-width: 1800px) {
  .container {
    width: 1560px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .row {
    margin-left: -20px;
    margin-right: -20px; }
  [class*="col-"] {
    padding-left: 20px;
    padding-right: 20px; } }

@media all and (max-width: 480px) {
  .MainDashboard {
    height: auto;
    min-height: calc(100vh - 66px);
    overflow-y: visible;
    position: relative;
    z-index: 0; } }

.SidebarDashboard {
  width: 280px;
  background-color: #fff;
  border: 1px solid #eee;
  height: 100%;
  display: flex !important;
  flex-wrap: wrap;
  flex-shrink: 0;
  position: relative;
  z-index: 10;
  transition: all 0.3s; }
  @media screen and (prefers-reduced-motion: reduce) {
    .SidebarDashboard {
      transition: none; } }
  .SidebarDashboard .NavTrademark {
    position: relative; }
    .SidebarDashboard .NavTrademark .TMTotalWarnings {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #ff443e;
      color: #fff;
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      padding: 0 5px;
      border-radius: 5px; }
  .SidebarDashboard .SideBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .SidebarDashboard .SideBar .SideBarTop .SidebarItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      gap: 10px;
      width: 100%;
      height: 48px;
      text-decoration: none;
      cursor: pointer;
      color: inherit; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem img:nth-child(1) {
        display: block; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem img:nth-child(2) {
        display: none; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem p {
        display: none;
        margin: 0;
        font-size: 16px;
        font-weight: 500; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Active::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #005bbb;
        border-radius: 0 8px 8px 0; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Active img:nth-child(1) {
        display: none !important; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Active img:nth-child(2) {
        display: block !important; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Active p {
        color: #005bbb; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Open {
        justify-content: flex-start; }
        .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Open img {
          margin-left: 20px; }
        .SidebarDashboard .SideBar .SideBarTop .SidebarItem.Open p {
          display: block; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem:hover::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #005bbb;
        border-radius: 0 8px 8px 0; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem:hover img:nth-child(1) {
        display: none; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem:hover img:nth-child(2) {
        display: block; }
      .SidebarDashboard .SideBar .SideBarTop .SidebarItem:hover p {
        color: #005bbb; }
    .SidebarDashboard .SideBar .SideBarTop .Extras {
      margin-top: 48px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      grid-gap: 10px;
      gap: 10px;
      width: 100%;
      height: 48px;
      text-decoration: none;
      color: inherit; }
      .SidebarDashboard .SideBar .SideBarTop .Extras p {
        margin: 0 0 0 10px;
        color: #f42800;
        font-size: 14px;
        font-weight: 700; }
      .SidebarDashboard .SideBar .SideBarTop .Extras.Open {
        justify-content: flex-start; }
      .SidebarDashboard .SideBar .SideBarTop .Extras.false {
        text-align: center; }
        .SidebarDashboard .SideBar .SideBarTop .Extras.false p {
          margin: 0; }
    .SidebarDashboard .SideBar .SideBarBottom .NewFeature {
      position: relative;
      width: 48px;
      height: 48px;
      margin: 0 auto;
      padding: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      background: linear-gradient(135deg, #f3dfd1 0%, #cbdafe 100%); }
      .SidebarDashboard .SideBar .SideBarBottom .NewFeature.Active {
        width: 232px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        justify-content: flex-start;
        flex-direction: column;
        padding: 16px 0px 0px 16px;
        padding-bottom: 50px;
        grid-gap: 16px;
        gap: 16px; }
        .SidebarDashboard .SideBar .SideBarBottom .NewFeature.Active p {
          display: inline; }
        .SidebarDashboard .SideBar .SideBarBottom .NewFeature.Active .BtnInclude {
          display: flex; }
      .SidebarDashboard .SideBar .SideBarBottom .NewFeature img {
        width: 32px;
        height: 32px; }
      .SidebarDashboard .SideBar .SideBarBottom .NewFeature p {
        display: none;
        font-size: 14px;
        font-weight: 500;
        margin: 0; }
        .SidebarDashboard .SideBar .SideBarBottom .NewFeature p span {
          color: #f42800; }
      .SidebarDashboard .SideBar .SideBarBottom .NewFeature .BtnInclude {
        display: none;
        position: absolute;
        bottom: 0;
        right: 0;
        justify-content: flex-end; }
        .SidebarDashboard .SideBar .SideBarBottom .NewFeature .BtnInclude a {
          text-align: center;
          width: 130px;
          height: 34px;
          background-color: #005bbb;
          border-radius: 8px 0px 4px 0px;
          display: flex;
          align-items: flex-start;
          padding: 5px 10px;
          margin: 0 auto;
          grid-gap: 10px;
          gap: 10px;
          font-size: 14px;
          font-weight: 400;
          color: white;
          justify-content: center;
          text-decoration: underline; }
    .SidebarDashboard .SideBar .SideBarBottom .LimitedOffer {
      position: fixed;
      right: 0;
      bottom: 80px;
      display: flex;
      align-items: center;
      margin-left: 0;
      justify-content: center;
      background-color: none; }
      .SidebarDashboard .SideBar .SideBarBottom .LimitedOffer .LimitedTimeOfferButton img {
        width: 100px;
        height: 100px; }
      .SidebarDashboard .SideBar .SideBarBottom .LimitedOffer.Active {
        justify-content: flex-start;
        margin-left: 30px; }
      .SidebarDashboard .SideBar .SideBarBottom .LimitedOffer .LimitedTimeOfferButton {
        background-color: none; }
    .SidebarDashboard .SideBar .SideBarBottom .GoOther {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      grid-gap: 20px;
      gap: 20px;
      margin-top: 50px;
      margin-bottom: 24px;
      height: -webkit-max-content;
      height: -moz-max-content;
      height: max-content; }
      .SidebarDashboard .SideBar .SideBarBottom .GoOther.Active {
        flex-direction: row;
        justify-content: flex-start; }
      .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem {
        color: #303238;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        transition: all 0.2s ease;
        position: relative; }
        .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem a {
          text-decoration: none;
          color: inherit; }
          .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem a:hover {
            color: #005bbb; }
        .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem .ToolInfo {
          display: none;
          background-color: white;
          padding: 10px;
          border-radius: 8px;
          position: absolute;
          z-index: 999;
          right: -380px;
          top: -260px;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          width: 400px;
          box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05); }
          .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem .ToolInfo.spyamz {
            top: -280px; }
          .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem .ToolInfo a {
            color: #005bbb; }
            .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem .ToolInfo a:hover {
              text-decoration: underline; }
        .SidebarDashboard .SideBar .SideBarBottom .GoOther .GoOtherItem:hover .ToolInfo {
          display: block; }
  .SidebarDashboard .OtherApps {
    width: 100%;
    margin: auto; }
    .SidebarDashboard .OtherApps .nav {
      padding: 0; }
      .SidebarDashboard .OtherApps .nav .nav-item.Logo.spybadass {
        background: url("/assets/images/spyads.png") no-repeat center center;
        background-size: 30%; }
      .SidebarDashboard .OtherApps .nav .nav-item.Logo.spyamz {
        background: url("/assets/images/spyamz.png") no-repeat center center;
        background-size: 30%; }
  .SidebarDashboard .nav {
    font-size: 1.125rem;
    width: 100%;
    padding: 0 10px;
    flex-wrap: wrap;
    align-content: flex-start; }
    .SidebarDashboard .nav .Copyright {
      font-size: 1rem;
      text-align: center;
      width: 100%; }
    .SidebarDashboard .nav .Logo {
      background: url("/assets/images/logo.png") no-repeat center center;
      margin-bottom: 50px;
      margin-top: 30px; }
      .SidebarDashboard .nav .Logo .nav-link {
        display: inline-block;
        height: 70px;
        width: 100%; }
        .SidebarDashboard .nav .Logo .nav-link:not(:first-child):hover {
          background-color: transparent; }
    .SidebarDashboard .nav .nav-item {
      width: 100%; }
      .SidebarDashboard .nav .nav-item:not(.Logo) {
        height: 55px;
        line-height: 55px; }
      .SidebarDashboard .nav .nav-item:not(.Logo) {
        margin-bottom: 10px; }
      .SidebarDashboard .nav .nav-item:not(:first-child) .nav-link:hover {
        background-color: #f56400;
        color: #fff;
        box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08); }
      .SidebarDashboard .nav .nav-item.spybadass.Logo, .SidebarDashboard .nav .nav-item.spyamz.Logo {
        background-size: 73px 40px;
        margin-top: 0;
        margin-bottom: 10px;
        position: relative; }
        .SidebarDashboard .nav .nav-item.spybadass.Logo a:hover, .SidebarDashboard .nav .nav-item.spyamz.Logo a:hover {
          background: transparent;
          box-shadow: none; }
        .SidebarDashboard .nav .nav-item.spybadass.Logo:hover .spybadass, .SidebarDashboard .nav .nav-item.spybadass.Logo:hover .spyamz, .SidebarDashboard .nav .nav-item.spyamz.Logo:hover .spybadass, .SidebarDashboard .nav .nav-item.spyamz.Logo:hover .spyamz {
          display: block; }
        .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo {
          display: none;
          font-size: 14px;
          padding: 20px;
          position: absolute;
          width: 500px;
          left: 279px;
          bottom: -15px;
          height: auto;
          z-index: 9999;
          background: #fff; }
          .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Title, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Title {
            padding-bottom: 10px;
            border-bottom: 1px solid #eee; }
            .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Title .Name.spyamz, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Title .Name.spyamz {
              color: #03a9f4; }
            .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Title .Name.spybadass, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Title .Name.spybadass {
              color: #4267b2; }
            .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Title .SubTitle, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Title .SubTitle {
              margin-bottom: 0; }
          .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Info, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Info {
            margin-top: 10px; }
            .SidebarDashboard .nav .nav-item.spybadass.Logo .ToolInfo .Info .Description, .SidebarDashboard .nav .nav-item.spyamz.Logo .ToolInfo .Info .Description {
              margin-bottom: 5px; }
    .SidebarDashboard .nav .nav-link {
      position: relative;
      padding: 0 10px 0 44px;
      transition: background-color .3s;
      color: #f56400; }
      .SidebarDashboard .nav .nav-link > i {
        position: absolute;
        top: 12px;
        font-size: 1.6rem; }
      .SidebarDashboard .nav .nav-link.active {
        box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08);
        background-color: #f56400;
        color: #fff; }
        .SidebarDashboard .nav .nav-link.active .Text {
          font-weight: 400; }
    .SidebarDashboard .nav .Text {
      margin-left: 44px;
      transition: all .3s; }
    .SidebarDashboard .nav .CollapseMenu {
      cursor: pointer; }
      .SidebarDashboard .nav .CollapseMenu .Open {
        display: none; }
  .SidebarDashboard.collapsed .nav {
    padding: 0 10px; }
  .SidebarDashboard.collapsed .OtherApps {
    width: 100px; }
    .SidebarDashboard.collapsed .OtherApps .nav .nav-item.Logo.spyamz, .SidebarDashboard.collapsed .OtherApps .nav .nav-item.Logo.spybadass {
      background-size: 70%; }
      .SidebarDashboard.collapsed .OtherApps .nav .nav-item.Logo.spyamz .ToolInfo, .SidebarDashboard.collapsed .OtherApps .nav .nav-item.Logo.spybadass .ToolInfo {
        left: 99px; }
  .SidebarDashboard.collapsed .UserMenu {
    padding: 0 0 30px 0; }

@media all and (max-width: 1440px) {
  .SidebarDashboard .nav .Logo {
    margin-bottom: 20px;
    margin-top: 20px; }
  .SidebarDashboard .nav .Copyright {
    display: none; }
  .SidebarDashboard .nav .nav-item:not(.Logo) {
    line-height: 45px;
    height: auto; } }

@media all and (max-width: 480px) {
  .Dashboard .SidebarDashboard {
    display: none !important; } }

.ChartHistory {
  min-height: 260px; }
  .ChartHistory .ShowDays button {
    border-radius: 5px;
    background-color: #fff;
    border-color: #eeeeee;
    font-size: 15px;
    margin: 10px 5px 25px 0;
    color: #333; }
  .ChartHistory .ShowDays .active {
    background-color: #f56400 !important;
    color: #fff !important;
    border-color: #f56400 !important; }
  .ChartHistory .ShowDays .btn-secondary:focus {
    box-shadow: none !important; }

.NoHistory h4 {
  line-height: 100px; }

@media all and (max-width: 480px) {
  .ChartHistoryItem .ChartRankItem {
    margin-bottom: -50px; } }

.ProductDetails {
  margin-top: 20px; }
  .ProductDetails.trending .Body .ProductImagePreview {
    position: relative; }
    .ProductDetails.trending .Body .ProductImagePreview:before {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      background: url("/assets/images/trending.svg") center center no-repeat;
      z-index: 1;
      background-size: cover; }
  .ProductDetails .Body {
    margin-bottom: 20px; }
  .ProductDetails .Left .WrapperLeft {
    position: relative; }
    .ProductDetails .Left .WrapperLeft .ButtonAddFavorites {
      top: 10px;
      right: 10px; }
    .ProductDetails .Left .WrapperLeft .Price {
      position: absolute;
      bottom: 10px;
      left: 10px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      font-size: .875rem;
      padding: 5px;
      border-radius: 5px; }
  .ProductDetails .Right {
    color: #808080;
    max-height: 500px;
    overflow-y: auto;
    font-size: 16px;
    font-weight: 300; }
    .ProductDetails .Right .Link {
      margin-left: 5px; }
    .ProductDetails .Right .Description {
      white-space: pre-wrap; }
    .ProductDetails .Right > div {
      margin-bottom: 5px; }
      .ProductDetails .Right > div > span:first-child {
        font-weight: 500;
        color: #333333; }
    .ProductDetails .Right > span {
      font-weight: 500; }
  .ProductDetails .TitleItem h1 {
    font-size: 2rem;
    font-weight: 500;
    color: #333;
    padding-bottom: 30px;
    padding-top: 10px; }
  .ProductDetails .ASIN {
    font-weight: 500; }
  .ProductDetails .Actions > div span:first-child {
    color: #03A9F4 !important;
    font-weight: 500; }
  .ProductDetails .Actions .linear-link {
    color: #03A9F4 !important; }
  .ProductDetails .ButtonAddFavorites {
    display: inline-block;
    transition: all 200ms ease-out; }
    .ProductDetails .ButtonAddFavorites.loading {
      opacity: 0.5; }
  .ProductDetails .HistoryChart {
    font-size: 14px; }
    .ProductDetails .HistoryChart .recharts-wrapper {
      display: inline-block;
      float: left; }
    .ProductDetails .HistoryChart .SelectTimeWrapper {
      display: inline-block;
      padding-left: 20px; }
    .ProductDetails .HistoryChart:after {
      content: '';
      display: block;
      clear: both; }
  .ProductDetails .Histories {
    padding-bottom: 30px; }
  .ProductDetails.c-popsockets .Body .Left .WrapperLeft .ProductImagePreview {
    background-size: 100%;
    padding-bottom: 100%; }

@media all and (max-width: 1024px) {
  .ItemDetail .Right {
    max-height: 300px; } }

@media all and (max-width: 576px) {
  .ItemDetail .Left .Preview {
    margin-bottom: 20px; } }

@media all and (max-width: 480px) {
  .ItemDetail .Header .TitleItem h1 {
    font-size: 1.5rem;
    padding-bottom: 20px; }
  .ItemDetail .Body .Left {
    margin-bottom: 20px; }
  .ItemDetail .Body .Right {
    overflow-y: visible;
    max-height: 100%; }
    .ItemDetail .Body .Right .HistoryChart .recharts-wrapper {
      max-width: 100%; }
      .ItemDetail .Body .Right .HistoryChart .recharts-wrapper > * {
        max-width: 96%; } }

.ProductDetails .SimilarProducts {
  margin-bottom: 40px; }

.KeywordAnalytics {
  margin-bottom: 20px; }

.KeywordFiltering {
  font-size: 0.9375rem; }
  .KeywordFiltering form {
    flex-wrap: wrap; }
  .KeywordFiltering .form-control {
    min-width: 200px; }
  .KeywordFiltering .Filter {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .KeywordFiltering .Filter:last-child {
      margin-left: 20px; }
    .KeywordFiltering .Filter label {
      margin-bottom: 0;
      color: #808080;
      margin-right: 10px; }
    .KeywordFiltering .Filter input {
      color: #333;
      font-size: 0.9375rem; }
    .KeywordFiltering .Filter .SingleDatePickerInput .DateInput input {
      line-height: 25px;
      padding: 6px 10px 2px;
      border: 1px solid #eee;
      border-radius: 5px;
      outline: none; }
  .KeywordFiltering .Options {
    display: flex;
    flex-wrap: wrap; }
  .KeywordFiltering .Selects {
    display: none; }
  .KeywordFiltering .Option {
    padding: 0 20px;
    height: 35px;
    border: 1px solid #eee;
    border-radius: 5px;
    line-height: 35px;
    cursor: pointer; }
    .KeywordFiltering .Option:not(:last-child) {
      margin-right: 5px; }
    .KeywordFiltering .Option:hover {
      background-color: #eee;
      border-color: #eee; }
    .KeywordFiltering .Option.active {
      color: #fff;
      background-color: #4cbff5;
      border-color: #4cbff5; }

@media (max-width: 480px) {
  body .KeywordFiltering > form {
    display: block !important; }
  body .KeywordFiltering .Filter {
    justify-content: space-between; }
    body .KeywordFiltering .Filter:last-child {
      margin-left: 0; }
      body .KeywordFiltering .Filter:last-child .form-control {
        min-width: 130px; }
    body .KeywordFiltering .Filter:nth-child(2) .form-control {
      min-width: 240px;
      text-align: right; }
    body .KeywordFiltering .Filter .SearchTermKeywords {
      width: 100%; }
    body .KeywordFiltering .Filter .react-datepicker-wrapper, body .KeywordFiltering .Filter .react-datepicker__input-container {
      display: block; }
  body .KeywordFiltering .Options {
    display: none; }
  body .KeywordFiltering .Selects {
    display: block; }
  body .KeywordFiltering .Option {
    width: calc(50% - 10px);
    margin-right: 0 !important;
    margin-bottom: 10px; }
    body .KeywordFiltering .Option:nth-child(even) {
      margin-left: 20px; } }

.KeywordPagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .KeywordPagination .pagination .page-item {
    cursor: pointer; }
  .KeywordPagination .PaginationItems {
    margin-right: 0; }

@media (max-width: 480px) {
  body .KeywordPagination .Left {
    width: 100%;
    display: flex;
    justify-content: center; }
  body .KeywordPagination select {
    width: auto;
    display: inline-block; }
  body .KeywordPagination .PaginationItems {
    display: flex;
    justify-content: center; }
    body .KeywordPagination .PaginationItems ul.pagination {
      flex-wrap: wrap;
      display: inline-flex; }
      body .KeywordPagination .PaginationItems ul.pagination > li {
        margin-bottom: 10px; } }

.ShowKeywordPerPage select {
  margin: 0 5px; }

.KeywordResults {
  margin-bottom: 40px; }
  .KeywordResults .Wrapper {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem; }
    .KeywordResults .Wrapper > div {
      border-left: 1px dotted #eee;
      border-right: 1px dotted #eee;
      border-bottom: 1px solid #eee; }
      .KeywordResults .Wrapper > div.OrderColumn {
        border-left: 1px solid #eee; }
      .KeywordResults .Wrapper > div:last-child {
        border-right: 1px solid #eee; }
  .KeywordResults .Index {
    justify-content: center; }
  .KeywordResults .Item, .KeywordResults .Index {
    min-height: 50px;
    display: flex;
    align-items: center; }
    .KeywordResults .Item:nth-child(even), .KeywordResults .Index:nth-child(even) {
      background-color: #fafafa; }
  .KeywordResults .Text, .KeywordResults .Number {
    display: inline-block;
    overflow: hidden; }
  .KeywordResults .Text {
    padding-left: 100px;
    width: 60%; }
  .KeywordResults .Number {
    text-align: right;
    padding-right: 100px;
    width: 40%; }
  .KeywordResults .Header {
    height: 40px;
    line-height: 40px;
    color: #808080;
    font-weight: 500; }
  .KeywordResults .Body .Text {
    font-weight: 300; }
    .KeywordResults .Body .Text > a {
      position: relative;
      padding-right: 5px;
      display: inline-block; }
      .KeywordResults .Body .Text > a:after {
        position: absolute;
        width: 250px;
        height: 3px;
        left: 100%;
        background-image: radial-gradient(circle 5px, #aaa 30%, transparent 0);
        background-size: 4px 7px;
        content: "";
        top: 50%;
        border-bottom: none;
        -ms-transform: rotate(90deg); }
  .KeywordResults .Body .Number > span {
    position: relative;
    padding-left: 5px; }
    .KeywordResults .Body .Number > span:before {
      position: absolute;
      width: 250px;
      height: 3px;
      right: 100%;
      background-image: radial-gradient(circle 5px, #aaa 30%, transparent 0);
      background-size: 4px 7px;
      content: "";
      top: 50%;
      border-bottom: none;
      -ms-transform: rotate(90deg); }
  .KeywordResults .KeywordColumn {
    flex-grow: 1;
    margin-left: -1px; }
    .KeywordResults .KeywordColumn .Header {
      background-color: #f2fbff; }
  .KeywordResults .OrderColumn {
    width: 60px;
    text-align: center; }
    .KeywordResults .OrderColumn .Header {
      background-color: #d9f3ff; }
    .KeywordResults .OrderColumn .Body {
      font-size: 0.9375rem; }

@media (max-width: 1400px) {
  body .KeywordResults .Text {
    padding-left: 40px; }
  body .KeywordResults .Number {
    padding-right: 40px; } }

@media (max-width: 480px) {
  body .KeywordResults .OrderColumn {
    display: none; }
  body .KeywordResults .Body .Text > a {
    font-size: 1.125rem;
    font-weight: 500; }
  body .KeywordResults .KeywordColumn {
    width: 100%;
    margin-left: 0; }
    body .KeywordResults .KeywordColumn:not(:last-child) {
      margin-bottom: 20px; }
  body .KeywordResults .Text {
    padding-left: 10px; }
  body .KeywordResults .Number {
    padding-right: 10px; } }

.modal-content {
  border: none;
  overflow: hidden;
  border-radius: 6px;
  width: 672px; }
  .modal-content .modal-body {
    padding: 0; }
    .modal-content .modal-body .btn-close-modal {
      padding: 8px 16px;
      text-transform: uppercase;
      border-radius: 6px;
      background-color: #fff;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1.6px;
      color: #3F3F46;
      cursor: pointer;
      border: 1px solid #d4d4d8;
      transition-duration: .15s;
      outline: none; }
    .modal-content .modal-body .btn-close-modal:hover {
      color: #71717A; }

.SimilarProductsLink {
  margin-left: 5px; }
  .SimilarProductsLink:after {
    display: none; }

.FormFilterRank {
  display: flex;
  padding: 32px 31px; }
  .FormFilterRank .InputNumber {
    position: relative; }
    .FormFilterRank .InputNumber .Remove {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      display: none; }
      .FormFilterRank .InputNumber .Remove.Removable {
        display: inline-block; }
      .FormFilterRank .InputNumber .Remove:after {
        content: "\F00D";
        display: block;
        font-weight: 900;
        font-family: Font Awesome\ 5 Free, serif;
        padding: 8px 13px;
        cursor: pointer; }
  .FormFilterRank .Auto {
    position: relative;
    width: 160px; }
    .FormFilterRank .Auto:after {
      position: absolute;
      width: 190px;
      height: 8px;
      background-image: radial-gradient(circle 5px, #eee 30%, transparent 30%);
      background-size: 4px 7px;
      content: '';
      right: -93px;
      top: 90px;
      border-bottom: none;
      -webkit-transform: rotateZ(90deg);
      transform: rotateZ(90deg); }
    .FormFilterRank .Auto .Options {
      display: flex;
      flex-wrap: wrap; }
      .FormFilterRank .Auto .Options button {
        background-color: #fff;
        color: #808080;
        border-color: #eee;
        line-height: 28px;
        padding: 0 20px;
        font-size: 0.9375rem; }
        .FormFilterRank .Auto .Options button.active {
          background-color: #f56400;
          color: #fff;
          border-color: #f56400;
          box-shadow: none !important; }
        .FormFilterRank .Auto .Options button:not(:last-child) {
          margin-bottom: 10px; }
  .FormFilterRank .Custom {
    width: 160px;
    margin-left: 10px; }
    .FormFilterRank .Custom .InputNumber {
      display: flex;
      align-items: center;
      margin: 0; }
      .FormFilterRank .Custom .InputNumber .Label {
        flex: 0 0 50px; }
    .FormFilterRank .Custom .form-group:not(:last-child) {
      margin-bottom: 5px; }
    .FormFilterRank .Custom .form-group:last-child {
      margin-top: 10px; }

@media all and (max-width: 480px) {
  .FormFilterRank {
    flex-direction: column !important; }
    .FormFilterRank .Auto {
      width: auto; }
      .FormFilterRank .Auto:after {
        display: none; }
      .FormFilterRank .Auto .Options {
        justify-content: center;
        margin-bottom: 10px; }
        .FormFilterRank .Auto .Options button {
          margin-right: 10px; }
          .FormFilterRank .Auto .Options button:last-child {
            margin-right: 0; }
    .FormFilterRank .Custom {
      margin-left: 0;
      width: auto; }
      .FormFilterRank .Custom .form-group:last-child {
        margin-bottom: 0; } }

.ModalShowItemDetail {
  max-width: 80%; }
  .ModalShowItemDetail .Title a:hover, .ModalShowItemDetail .Title a:focus {
    text-decoration: none; }
  .ModalShowItemDetail .modal-body {
    min-height: 600px;
    padding-left: 30px; }
    .ModalShowItemDetail .modal-body .ChartRankItem .ShowDays button {
      margin-bottom: 0; }
    .ModalShowItemDetail .modal-body .Title {
      font-size: 22px;
      padding-bottom: 20px;
      font-weight: normal; }
    .ModalShowItemDetail .modal-body .BodyLeft .WrapperLeft {
      position: relative; }
    .ModalShowItemDetail .modal-body .BodyLeft a {
      display: block; }
      .ModalShowItemDetail .modal-body .BodyLeft a img {
        max-width: 100%;
        max-height: 300px; }
    .ModalShowItemDetail .modal-body .BodyRight {
      position: relative; }
      .ModalShowItemDetail .modal-body .BodyRight .WrapperBodyRight {
        overflow-y: auto; }
      .ModalShowItemDetail .modal-body .BodyRight .ProductReviews {
        margin-bottom: 10px; }
      .ModalShowItemDetail .modal-body .BodyRight .Listed {
        margin-bottom: 5px;
        margin-left: 4px;
        color: #808080;
        font-size: 15px; }
        .ModalShowItemDetail .modal-body .BodyRight .Listed > span:first-child {
          font-weight: 500;
          color: #333333; }
        .ModalShowItemDetail .modal-body .BodyRight .Listed.Favorites .Link {
          margin-left: 5px; }
        .ModalShowItemDetail .modal-body .BodyRight .Listed .link-to-etsy {
          margin-left: 5px; }
      .ModalShowItemDetail .modal-body .BodyRight .HistoryChart {
        margin-top: 10px;
        font-size: 14px; }
        .ModalShowItemDetail .modal-body .BodyRight .HistoryChart .recharts-wrapper {
          display: inline-block;
          float: left; }
        .ModalShowItemDetail .modal-body .BodyRight .HistoryChart .SelectTimeWrapper {
          display: inline-block;
          padding-left: 20px; }
  .ModalShowItemDetail .previous-item {
    margin-right: 15px; }
  .ModalShowItemDetail .CloseButton .btn {
    background: none;
    color: #fff;
    border: none;
    margin: -40px -40px 0 0;
    font-size: 25px; }
  .ModalShowItemDetail .Next .btn {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 1.4px 1.4px 16px 0 rgba(0, 0, 0, 0.1);
    color: #aaaaaa;
    font-size: 25px;
    position: absolute;
    top: 50%;
    right: -25px; }
    .ModalShowItemDetail .Next .btn:disabled {
      cursor: auto; }
  .ModalShowItemDetail .Previous .btn {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 1.4px 1.4px 16px 0 rgba(0, 0, 0, 0.1);
    color: #aaaaaa;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: -25px; }
    .ModalShowItemDetail .Previous .btn:disabled {
      cursor: auto; }
  .ModalShowItemDetail .ModalShowItemDetailBody.c-popsockets .BodyLeft .WrapperLeft .ProductImagePreview {
    background-size: 100%;
    padding-bottom: 100%; }

@media all and (min-width: 1280px) {
  .ModalShowItemDetail {
    max-width: 1000px; } }

@media all and (max-width: 480px) {
  body .ModalShowItemDetail {
    max-width: 100%; }
    body .ModalShowItemDetail .ranks {
      display: none; }
    body .ModalShowItemDetail .CloseButton {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 9; }
      body .ModalShowItemDetail .CloseButton button.btn {
        margin: 0;
        background-color: #ff443e;
        padding: 0;
        width: 36px;
        height: 36px;
        line-height: 36px;
        border-radius: unset; }
      body .ModalShowItemDetail .CloseButton span:before {
        font-size: 20px; }
    body .ModalShowItemDetail .modal-body {
      padding-left: 16px; }
      body .ModalShowItemDetail .modal-body .BodyLeft .WrapperLeft .ButtonAddFavorites {
        left: 5px;
        right: auto; }
      body .ModalShowItemDetail .modal-body .BodyRight .Description {
        display: none; }
    body .ModalShowItemDetail .Next span:before, body .ModalShowItemDetail .Previous span:before {
      font-size: 18px;
      color: #000;
      font-weight: 500; }
    body .ModalShowItemDetail .Next .btn, body .ModalShowItemDetail .Previous .btn {
      width: 40px;
      height: 40px;
      opacity: 0.9;
      line-height: 40px;
      padding: 0;
      top: 150px; }
    body .ModalShowItemDetail .Next .btn {
      right: -5px; }
    body .ModalShowItemDetail .Previous .btn {
      left: -5px; }
    body .ModalShowItemDetail .modal-body .Title {
      font-size: 20px;
      line-height: 1.3;
      margin-top: 10px;
      padding-bottom: 10px; }
    body .ModalShowItemDetail .HistoryChart .recharts-wrapper {
      max-width: 100%; }
      body .ModalShowItemDetail .HistoryChart .recharts-wrapper > * {
        max-width: 96%; } }

.TableItemsMeta .TotalItems {
  margin-left: 20px;
  font-weight: 500; }

.Item td {
  vertical-align: middle; }

.Item.dead {
  opacity: 0.5; }

.Item .RankHistory img {
  cursor: pointer;
  border: 1px solid #999;
  max-width: 100px; }

.Item .updated {
  position: relative; }

.Item .Thumbnail {
  position: relative;
  cursor: pointer; }
  .Item .Thumbnail.noThumbnail {
    background: url("/assets/images/no-image.svg") center center no-repeat;
    background-size: 80%; }
    .Item .Thumbnail.noThumbnail a {
      border: 1px solid #ddd; }
  .Item .Thumbnail:hover .hover {
    display: block; }
  .Item .Thumbnail a {
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100px;
    position: relative; }
    .Item .Thumbnail a img {
      width: 100%;
      height: auto;
      opacity: 0; }
  .Item .Thumbnail .hover {
    display: none;
    position: absolute;
    top: 0;
    left: 150px;
    z-index: 20;
    width: 300px;
    height: 300px;
    overflow: hidden; }
    .Item .Thumbnail .hover img {
      width: 590px;
      height: auto;
      position: relative;
      bottom: 106px;
      right: 140px; }

.Item .Updated {
  color: #aaa;
  font-size: 0.75rem;
  margin-top: 14px; }

.Item .availableAt {
  font-size: 15px; }
  .Item .availableAt .Title {
    color: #aaa; }
  .Item .availableAt .Value {
    color: #333; }
  .Item .availableAt .Value a {
    color: #333; }

.ItemGrid {
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 35px;
  list-style: none; }
  .ItemGrid:hover .Thumbnail {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2); }
  .ItemGrid:not(.cropped) .Thumbnail a {
    background-position: center;
    height: 0;
    position: relative;
    background-size: 100%;
    padding-bottom: 79.412%;
    border-radius: 2px; }
  .ItemGrid.trending .Top {
    position: relative; }
    .ItemGrid.trending .Top:before {
      content: '';
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      background: url("/assets/images/trending.svg") center center no-repeat;
      z-index: 1;
      background-size: cover; }
  .ItemGrid .Thumbnail {
    transition: all 200ms ease-out;
    margin-bottom: 6px; }
    .ItemGrid .Thumbnail a {
      display: block;
      width: 100%;
      height: 0;
      position: relative;
      padding-bottom: 79.412%;
      background-color: #eee;
      overflow: hidden; }
    .ItemGrid .Thumbnail:after {
      content: none; }
  .ItemGrid .Top {
    position: relative; }
    .ItemGrid .Top .Price {
      position: absolute;
      bottom: 5px;
      left: 5px;
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      font-size: .75rem;
      padding: 5px;
      border-radius: 5px; }
  .ItemGrid .FavoriteCount {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    font-size: .75rem; }
    .ItemGrid .FavoriteCount .Inner {
      background-color: #fff;
      border-radius: 5px;
      padding: 14px 5px 4px;
      border: 1px solid #eee;
      display: flex; }
      .ItemGrid .FavoriteCount .Inner .Count {
        display: inline-flex;
        align-items: center;
        margin-right: 10px;
        position: relative; }
        .ItemGrid .FavoriteCount .Inner .Count:last-child {
          margin-right: 0; }
        .ItemGrid .FavoriteCount .Inner .Count .BadgeCount {
          position: absolute;
          top: -15px;
          left: 50%;
          -webkit-transform: translateX(-50%);
                  transform: translateX(-50%);
          font-weight: 700; }
          .ItemGrid .FavoriteCount .Inner .Count .BadgeCount.Up {
            color: #37af56; }
          .ItemGrid .FavoriteCount .Inner .Count .BadgeCount.Down {
            color: #e74c3c; }
    .ItemGrid .FavoriteCount .Icon {
      margin-right: 5px;
      position: relative;
      top: 1px;
      color: #ff443e; }
    .ItemGrid .FavoriteCount .Current {
      position: relative; }
      .ItemGrid .FavoriteCount .Current:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      .ItemGrid .FavoriteCount .Current img {
        transition: all 200ms ease-out;
        width: auto;
        min-width: 8px;
        max-height: 12px; }
  .ItemGrid .Bottom {
    text-align: left; }
    .ItemGrid .Bottom .ShopUpdated,
    .ItemGrid .Bottom .ShopDate {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .ItemGrid .Bottom .ShopUpdated i,
      .ItemGrid .Bottom .ShopDate i {
        margin-right: 5px; }
  .ItemGrid .Name {
    display: block;
    font-size: 14px;
    line-height: 1.6;
    font-weight: 300;
    color: #222;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .ItemGrid .Shop {
    color: #595959;
    font-size: 0.75rem; }
    .ItemGrid .Shop .ShopName {
      color: #595959; }
      .ItemGrid .Shop .ShopName:hover {
        color: #f56400; }
  .ItemGrid .Updated {
    margin-top: 0; }
  .ItemGrid.fuzzy .Top .UpgradePlan {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 999;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }
    .ItemGrid.fuzzy .Top .UpgradePlan .InnerUpgrade .Actions .Upgrade {
      background-color: #f56400;
      color: #ffffff;
      transition: all 0.3s ease 0s; }
      .ItemGrid.fuzzy .Top .UpgradePlan .InnerUpgrade .Actions .Upgrade:hover {
        background-color: #a94500; }
    .ItemGrid.fuzzy .Top .UpgradePlan .InnerUpgrade .Actions .Hide {
      font-size: 16px;
      margin-top: 10px; }
      .ItemGrid.fuzzy .Top .UpgradePlan .InnerUpgrade .Actions .Hide:hover {
        cursor: pointer;
        text-decoration: underline; }
  .ItemGrid.fuzzy .Top .Thumbnail a:hover {
    cursor: not-allowed; }
  .ItemGrid.fuzzy .Bottom {
    position: relative;
    -webkit-filter: blur(2px);
            filter: blur(2px); }
    .ItemGrid.fuzzy .Bottom:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9; }

.TableListItems.list .Item .ASIN {
  margin-bottom: 3px; }

.TableListItems.list .Item .brand {
  margin-bottom: 3px; }

@media (max-width: 768px) {
  body .Item .Updated {
    font-size: 13px; } }

@media all and (max-width: 576px) {
  .ItemGrid .Top .Price {
    top: 5px;
    bottom: auto; } }

@media all and (max-width: 480px) {
  .ItemGrid .Bottom .rank > div:last-child {
    display: none; } }

@media (min-width: 1200px) {
  .ItemGrid.col-xl-2 {
    flex: 0 0 20%;
    max-width: 20%; } }

.PaginationItems {
  font-size: 1rem; }
  .PaginationItems .pagination {
    margin-bottom: 0; }
  .PaginationItems .page-item {
    min-width: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer; }
    .PaginationItems .page-item.disabled {
      opacity: 0.5; }
    .PaginationItems .page-item.active .page-link {
      color: #fff; }
    .PaginationItems .page-item:first-child, .PaginationItems .page-item:last-child {
      position: relative; }
      .PaginationItems .page-item:first-child .page-link, .PaginationItems .page-item:last-child .page-link {
        font-size: 0;
        height: 38px; }
        .PaginationItems .page-item:first-child .page-link:before, .PaginationItems .page-item:last-child .page-link:before {
          position: absolute;
          font-family: Ionicons, serif;
          width: 35px;
          height: 35px;
          color: #aaa;
          background-color: #fff;
          z-index: 9;
          font-size: 1.25rem;
          line-height: 36px;
          border-radius: 5px; }
        .PaginationItems .page-item:first-child .page-link:hover:before, .PaginationItems .page-item:last-child .page-link:hover:before {
          background-color: #eee; }
    .PaginationItems .page-item:first-child .page-link:before {
      content: "\F3CF";
      top: 0;
      left: 0; }
    .PaginationItems .page-item:last-child .page-link:before {
      content: "\F3D1";
      top: 0;
      right: 0; }
    .PaginationItems .page-item .page-link {
      border-color: #eee;
      border-radius: 5px;
      color: #aaa; }
      .PaginationItems .page-item .page-link:focus {
        box-shadow: none; }
  .PaginationItems li:not(:last-child) {
    margin-right: 5px; }
  .PaginationItems .break {
    width: 40px;
    height: 40px;
    text-align: center;
    border: 1px solid #eee;
    border-radius: 5px;
    line-height: 35px;
    color: #aaa; }

@media all and (max-width: 480px) {
  .PaginationItems {
    width: 100%; }
    .PaginationItems .pagination .page-item.Previous, .PaginationItems .pagination .page-item.Next {
      display: inline-block; } }

@media all and (max-width: 320px) {
  .PaginationItems {
    width: 100%; }
    .PaginationItems .pagination .page-item.Previous, .PaginationItems .pagination .page-item.Next {
      display: none; } }

.ItemsExplorerHeader {
  font-size: 0.9375rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999; }
  .ItemsExplorerHeader .Top {
    position: relative;
    padding: 15px 0;
    background-color: #fff;
    margin-bottom: 16px;
    box-shadow: 2.8px 2.8px 16px 0 rgba(0, 0, 0, 0.08);
    color: #808080; }
    .ItemsExplorerHeader .Top .Wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
    .ItemsExplorerHeader .Top .form-control {
      color: #808080; }
    .ItemsExplorerHeader .Top:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 20px solid #f56400;
      border-right: 20px solid transparent; }
  .ItemsExplorerHeader .Bottom .Wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }

@media (max-width: 1024px) {
  body .ItemsExplorerHeader .Bottom .Wrapper {
    display: block; } }

@media all and (max-width: 480px) {
  body .ItemsExplorerHeader .Top:before {
    display: none; }
  body .ItemsExplorerHeader .Bottom .Wrapper {
    display: flex;
    align-items: center; } }

.SearchTerm {
  position: relative; }
  .SearchTerm .Remove {
    display: none;
    position: absolute;
    top: 50%;
    right: 5px;
    font-size: 0.8rem;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    cursor: pointer; }
    .SearchTerm .Remove:before {
      content: "\F12A";
      font-family: "Ionicons", serif; }
    .SearchTerm .Remove:hover {
      color: #ff443e; }
  .SearchTerm .IconSearch {
    position: absolute;
    color: #f56400;
    font-size: 1.25rem;
    right: 10px;
    top: 3px;
    transition: all .3s;
    cursor: pointer; }
  .SearchTerm input {
    border-radius: 0 5px 5px 0; }
  .SearchTerm.hasWord input {
    padding-right: 20px; }
  .SearchTerm.hasWord .Remove {
    display: inline-block; }
  .SearchTerm.hasWord .IconSearch {
    opacity: 0;
    visibility: hidden; }

.FilterPrice {
  border: 1px solid #eee;
  border-radius: 5px; }
  .FilterPrice .Label {
    font-weight: 300; }
  .FilterPrice .Content {
    position: relative;
    color: #808080; }
    .FilterPrice .Content .Editable {
      position: absolute;
      top: 0;
      right: -16px;
      bottom: 0;
      display: none;
      font-size: 0.8em; }
    .FilterPrice .Content:focus, .FilterPrice .Content:active, .FilterPrice .Content:hover {
      text-decoration: none;
      outline: none; }
    .FilterPrice .Content:hover {
      color: #f56400;
      cursor: pointer; }
      .FilterPrice .Content:hover .Editable {
        display: inline-block; }
    .FilterPrice .Content .Icon {
      margin-right: 10px; }
  .FilterPrice.isActive {
    background-color: #f56400;
    border-color: #f56400; }
    .FilterPrice.isActive .Content {
      color: #fff; }
      .FilterPrice.isActive .Content .Icon {
        display: none; }

.FormFilterPrice {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 30px; }
  .FormFilterPrice .Label {
    min-width: 50px; }
  .FormFilterPrice .form-group:not(:last-child) {
    margin-bottom: 10px; }

.FilterType {
  position: relative; }
  .FilterType > i {
    position: absolute;
    left: -2px;
    top: 9px; }
  .FilterType .SelectWrapper {
    padding-right: 33px;
    margin-left: -30px; }
    .FilterType .SelectWrapper optgroup {
      color: #cecece; }
    .FilterType .SelectWrapper option {
      color: #808080; }
  .FilterType select {
    text-indent: 33px;
    padding-left: 0;
    width: calc(100% + 33px); }
  .FilterType.isActive i {
    color: #fff; }
  .FilterType.isActive .SelectWrapper {
    background-color: #f56400;
    border-radius: 5px; }
    .FilterType.isActive .SelectWrapper select {
      background-color: transparent;
      color: #fff;
      border: none; }

.FilterBrandType .SelectWrapper {
  height: 100%; }
  .FilterBrandType .SelectWrapper select.form-control.selectBrandType {
    height: 100% !important; }

.FilterBrandType.isActive .SelectWrapper {
  background-color: #f56400;
  border-radius: 5px; }
  .FilterBrandType.isActive .SelectWrapper select {
    background-color: transparent;
    color: #fff;
    border: none; }
    .FilterBrandType.isActive .SelectWrapper select option {
      color: #808080; }

.FilterRank {
  border: 1px solid #eee;
  min-height: 34px;
  border-radius: 5px; }
  .FilterRank .Content {
    color: #808080; }
    .FilterRank .Content .Editable {
      position: absolute;
      top: 0;
      right: -16px;
      bottom: 0;
      display: none;
      font-size: 0.8em; }
    .FilterRank .Content:focus, .FilterRank .Content:active, .FilterRank .Content:hover {
      text-decoration: none;
      outline: none; }
    .FilterRank .Content:hover {
      color: #f56400;
      cursor: pointer; }
      .FilterRank .Content:hover .Editable {
        display: inline-block; }
    .FilterRank .Content i {
      margin-right: 10px; }
  .FilterRank.isActive {
    background-color: #f56400;
    border-color: #f56400; }
    .FilterRank.isActive .Content, .FilterRank.isActive i {
      color: #fff; }

.FilterTimeAvailable {
  cursor: pointer;
  border: 1px solid #eee;
  border-radius: 5px; }
  .FilterTimeAvailable i {
    margin-right: 10px; }
  .FilterTimeAvailable .Content {
    display: flex;
    align-items: baseline; }
    .FilterTimeAvailable .Content:hover {
      color: #f56400; }
  .FilterTimeAvailable.isActive {
    background-color: #f56400;
    border-color: #f56400; }
    .FilterTimeAvailable.isActive .Content, .FilterTimeAvailable.isActive i {
      color: #fff; }

.FormFilterTimeAvailable {
  display: flex;
  padding: 36px 32px; }
  .FormFilterTimeAvailable form button {
    cursor: pointer; }
  .FormFilterTimeAvailable .Auto {
    position: relative;
    width: 160px; }
    .FormFilterTimeAvailable .Auto:after {
      position: absolute;
      width: 150px;
      height: 8px;
      background-image: radial-gradient(circle 5px, #eee 30%, transparent 30%);
      background-size: 4px 7px;
      content: '';
      right: -73px;
      top: 70px;
      border-bottom: none;
      -webkit-transform: rotateZ(90deg);
              transform: rotateZ(90deg); }
    .FormFilterTimeAvailable .Auto .Options {
      display: flex;
      flex-wrap: wrap; }
      .FormFilterTimeAvailable .Auto .Options .CustomAuto {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;
        padding-right: 10px; }
        .FormFilterTimeAvailable .Auto .Options .CustomAuto input {
          width: 70px;
          margin-right: 10px; }
      .FormFilterTimeAvailable .Auto .Options button {
        background-color: #fff;
        color: #808080;
        border-color: #eee;
        line-height: 28px;
        padding: 0 20px;
        font-size: 0.9375rem; }
        .FormFilterTimeAvailable .Auto .Options button.active {
          background-color: #f56400;
          color: #fff;
          border-color: #f56400;
          box-shadow: none !important;
          cursor: auto; }
        .FormFilterTimeAvailable .Auto .Options button:not(:last-child) {
          margin-bottom: 10px; }
  .FormFilterTimeAvailable .Custom {
    margin-left: 30px; }
    .FormFilterTimeAvailable .Custom .DateRangePicker .DateRangePickerInput {
      border-color: #eee; }
    .FormFilterTimeAvailable .Custom .DateRangePicker .DateInput_input {
      font-size: 0.9rem; }
    .FormFilterTimeAvailable .Custom .DateRangePicker .DateRangePickerInput_arrow_svg {
      fill: #ddd; }

@media all and (max-width: 480px) {
  .FormFilterTimeAvailable {
    flex-direction: column !important; }
    .FormFilterTimeAvailable .Auto {
      width: 100%; }
      .FormFilterTimeAvailable .Auto:after {
        display: none; }
      .FormFilterTimeAvailable .Auto .Options {
        justify-content: center; }
        .FormFilterTimeAvailable .Auto .Options .CustomAuto {
          justify-content: center; }
        .FormFilterTimeAvailable .Auto .Options button {
          margin-bottom: 10px;
          margin-right: 10px; }
    .FormFilterTimeAvailable .Custom {
      width: 100%;
      margin-left: 0; }
      .FormFilterTimeAvailable .Custom .DateRangePicker {
        width: 100%;
        text-align: center; }
        .FormFilterTimeAvailable .Custom .DateRangePicker .DateInput {
          width: 100px; }
          .FormFilterTimeAvailable .Custom .DateRangePicker .DateInput input {
            text-align: center;
            padding: 6px 10px 3px;
            border-radius: 0; } }

.FilterStatus.isActive .SelectWrapper {
  background-color: #f56400;
  border-radius: 5px; }
  .FilterStatus.isActive .SelectWrapper select {
    background-color: transparent;
    color: #fff;
    border: none; }
    .FilterStatus.isActive .SelectWrapper select option {
      color: #808080; }

.SortControl {
  display: flex;
  align-items: center;
  flex-grow: 1; }
  .SortControl label {
    font-weight: 600;
    margin-bottom: 0;
    color: #808080; }
  .SortControl .Selects {
    margin-left: 5px; }
  .SortControl .ButtonOptions .Option:before {
    margin-right: 4px; }
  .SortControl .ButtonOptions .Option.trends:before {
    content: "\E7B9";
    font-family: 'Linearicons', serif; }

@media (max-width: 1024px) {
  body .SortControl {
    margin-bottom: 10px; } }

@media (max-width: 768px) {
  body .SortControl {
    display: flex;
    align-items: baseline; }
    body .SortControl label {
      width: 64px; }
    body .SortControl .ButtonOptions {
      padding-left: 10px; } }

@media all and (min-width: 480px) {
  .SortControl .Selects {
    display: none; } }

@media all and (max-width: 480px) {
  body .SortControl {
    margin-bottom: 0; }
    body .SortControl .Selects {
      margin-left: 0; }
    body .SortControl label {
      display: none; }
    body .SortControl .Options {
      display: none; } }

.SearchControl {
  display: flex;
  position: relative;
  padding-right: 30px; }
  .SearchControl .SearchType {
    width: auto; }
    .SearchControl .SearchType:checked {
      background-color: #f56400;
      color: #fff; }
  .SearchControl:after {
    position: absolute;
    width: 35px;
    height: 8px;
    background-image: radial-gradient(circle 5px, #eee 30%, transparent 30%);
    background-size: 4px 7px;
    content: '';
    right: -17px;
    top: 12px;
    border-bottom: none;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }

.SearchGuide .popover-header {
  display: block !important; }

.SearchGuide .popover-body {
  padding: 10px; }

@media (max-width: 1024px) {
  body .SearchControl {
    margin-bottom: 10px; }
    body .SearchControl:after {
      content: none; } }

@media all and (max-width: 480px) {
  body .SearchControl {
    padding-right: 0;
    width: 100%; }
    body .SearchControl .SearchTerm {
      width: 100%; }
      body .SearchControl .SearchTerm .IconSearch {
        display: none; }
    body .SearchControl .SearchExcludedKeyword {
      display: none; } }

.SearchExcludedKeyword {
  margin-left: 7px; }
  .SearchExcludedKeyword .Input {
    display: none; }
  .SearchExcludedKeyword.isOpen .Input {
    display: block; }
  .SearchExcludedKeyword.isOpen button.Toggle {
    display: none; }
  .SearchExcludedKeyword button.Toggle {
    color: #bbb;
    background-color: #fff;
    height: 35px;
    width: 35px;
    border: 1px solid #eee;
    position: relative;
    cursor: pointer;
    border-radius: 5px; }
    .SearchExcludedKeyword button.Toggle > i {
      font-size: 18px; }
    .SearchExcludedKeyword button.Toggle:before {
      content: "";
      position: absolute;
      height: 1px;
      width: 47px;
      background-color: #eee;
      left: -8px;
      bottom: 16px;
      -webkit-transform: rotateZ(-45deg);
              transform: rotateZ(-45deg); }

.AnalyticActions .Dropdown {
  padding: 0;
  min-width: 400px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto; }
  .AnalyticActions .Dropdown .FormSearchQuery .SearchName {
    width: 100%;
    border: 1px solid #eee;
    border-left: none;
    border-right: none;
    outline: none;
    border-radius: 0;
    padding: 20px 10px; }
    .AnalyticActions .Dropdown .FormSearchQuery .SearchName:focus {
      border-color: #eee; }
  .AnalyticActions .Dropdown .ListQueries {
    padding: 0;
    list-style: none;
    margin: 0; }
    .AnalyticActions .Dropdown .ListQueries .Query {
      padding: 10px;
      cursor: pointer; }
      .AnalyticActions .Dropdown .ListQueries .Query .Content {
        display: inline-flex;
        align-items: center; }
        .AnalyticActions .Dropdown .ListQueries .Query .Content .Icon {
          margin-right: 5px;
          transition: 200ms all ease-out;
          color: #aaa; }
          .AnalyticActions .Dropdown .ListQueries .Query .Content .Icon:hover {
            color: #333; }
      .AnalyticActions .Dropdown .ListQueries .Query:hover {
        background-color: #eee; }
        .AnalyticActions .Dropdown .ListQueries .Query:hover .Remove {
          visibility: visible; }
      .AnalyticActions .Dropdown .ListQueries .Query .Remove {
        cursor: pointer;
        visibility: hidden; }
        .AnalyticActions .Dropdown .ListQueries .Query .Remove:hover {
          color: #ff443e; }
        .AnalyticActions .Dropdown .ListQueries .Query .Remove:after {
          content: "\F12A";
          font-family: "Ionicons", serif; }
  .AnalyticActions .Dropdown .PaginationQueries {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 10px; }
    .AnalyticActions .Dropdown .PaginationQueries .pagination {
      margin: 0; }
      .AnalyticActions .Dropdown .PaginationQueries .pagination .page-item:not(:last-child) {
        margin-right: 5px; }
      .AnalyticActions .Dropdown .PaginationQueries .pagination .page-item .page-link {
        padding: 5px 10px;
        border-radius: 5px; }

@media (max-width: 1450px) and (min-width: 480px) {
  .AnalyticActions {
    margin-top: 10px; } }

@media all and (max-width: 480px) {
  .AnalyticActions {
    width: 100%; }
    .AnalyticActions .Dropdown {
      min-width: 300px; }
      .AnalyticActions .Dropdown .ListQueries .Query .Remove {
        visibility: visible; }
    .AnalyticActions > div {
      display: inline-block; } }

.FilterControl {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  padding-left: 30px; }
  .FilterControl > div {
    display: flex;
    align-items: center; }
    .FilterControl > div:not(:last-child) {
      margin-right: 5px; }
  .FilterControl i {
    color: #ccc;
    position: relative;
    top: 1px; }
  .FilterControl .Content {
    padding: 0 10px;
    transition: all 200ms ease-out; }

@media (max-width: 1024px) {
  body .FilterControl {
    padding-left: 0; } }

@media (max-width: 768px) {
  body .FilterControl {
    margin-bottom: 10px; } }

@media all and (max-width: 480px) {
  body .FilterControl {
    flex-wrap: wrap; }
    body .FilterControl > div {
      min-height: 34px;
      margin-bottom: 10px; }
    body .FilterControl .FilterBrandType {
      margin-right: 0; }
    body .FilterControl .FilterType {
      display: none; }
    body .FilterControl .FilterPrice {
      display: none; }
  .FormFilterRank button:last-child {
    margin-bottom: 10px; } }

.SearchResult {
  color: #808080;
  padding-left: 20px;
  position: relative; }
  .SearchResult:before {
    position: absolute;
    width: 30px;
    height: 1px;
    background-color: #eee;
    content: '';
    left: -15px;
    top: 10px;
    -webkit-transform: rotateZ(90deg);
            transform: rotateZ(90deg); }
  .SearchResult.ShopResult {
    padding: 0; }
    .SearchResult.ShopResult:before {
      content: none; }

@media (max-width: 1024px) {
  body .SearchResult {
    display: inline-block;
    vertical-align: bottom;
    line-height: 30px; }
    body .SearchResult:before {
      top: 14px; } }

@media all and (max-width: 480px) {
  .SearchResult {
    padding-left: 0; }
    .SearchResult:before {
      display: none; } }

.SearchType {
  border-radius: 5px 0 0 5px; }

.SelectWrapper {
  background-color: #fafafa;
  margin-right: -2px;
  outline: none; }
  .SelectWrapper select.SearchType {
    background-color: transparent; }
    .SelectWrapper select.SearchType:focus {
      border-color: #eee; }

.NoItems {
  text-align: center;
  font-size: 12px; }
  .NoItems h4 {
    margin: 20px auto; }

@media all and (max-width: 480px) {
  .NoItems {
    margin-left: 15px; }
    .NoItems h4 {
      font-size: 1rem; } }

.SearchItemsPage {
  position: relative;
  height: 100%; }

.TableListItems {
  padding-top: 140px;
  height: calc(100% - 75px);
  overflow: hidden; }
  .TableListItems .container {
    position: relative;
    height: 100%;
    overflow-y: auto; }
  .TableListItems.list .TableItemsWrapper .ProductInfo > a {
    display: block;
    margin-bottom: 7px; }
  .TableListItems .TableItemsWrapper {
    width: 100%;
    padding: 0;
    border-collapse: collapse;
    text-align: center;
    list-style: none;
    margin-bottom: 40px; }
    .TableListItems .TableItemsWrapper.row {
      margin-left: -9px;
      margin-right: -9px; }
    .TableListItems .TableItemsWrapper .TableHead {
      background-color: #f2fbff;
      color: #808080;
      height: 40px;
      line-height: 40px;
      font-size: 1rem; }
      .TableListItems .TableItemsWrapper .TableHead.head-sticky .TableCell {
        background-color: #f56400;
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        z-index: 1;
        color: #fff;
        transition: all .3s ease 0s; }
      .TableListItems .TableItemsWrapper .TableHead .TableCell {
        font-weight: 500;
        padding: 0 10px; }
    .TableListItems .TableItemsWrapper .TableBody {
      padding-top: 15px;
      border-style: solid;
      border-color: #f4f4f4;
      border-width: 0 1px 0 1px;
      transition: background-color .3s; }
      .TableListItems .TableItemsWrapper .TableBody:last-child {
        border-width: 0 1px 1px 1px; }
      .TableListItems .TableItemsWrapper .TableBody:nth-child(2n + 1) {
        background-color: #fafafa; }
      .TableListItems .TableItemsWrapper .TableBody:hover {
        background-color: rgba(0, 0, 0, 0.075); }
    .TableListItems .TableItemsWrapper .TableCell {
      border: unset;
      vertical-align: top;
      padding: 15px 10px;
      text-align: left; }
      .TableListItems .TableItemsWrapper .TableCell:first-child {
        font-size: 0.9375rem; }
      .TableListItems .TableItemsWrapper .TableCell a {
        padding: 0;
        color: #000000;
        margin-right: 10px;
        text-decoration: none;
        transition: all 0.3s ease 0s; }
        .TableListItems .TableItemsWrapper .TableCell a:hover {
          color: #f56400; }
    .TableListItems .TableItemsWrapper.grid {
      margin-top: 18px;
      width: auto; }
      .TableListItems .TableItemsWrapper.grid .TableHead {
        display: none !important; }
    .TableListItems .TableItemsWrapper.tableShopWrapper {
      display: table; }

.TableItemsFooter {
  padding: 15px 0; }

@media all and (max-width: 480px) {
  body .TableItemsFooter .Left {
    width: 100%;
    float: none !important;
    display: flex;
    justify-content: center; }
  body .TableItemsFooter .ShowPerPage {
    display: flex;
    align-items: center;
    float: none !important; }
    body .TableItemsFooter .ShowPerPage select {
      width: auto; }
  body .TableItemsFooter .PaginationItems {
    display: flex;
    justify-content: center; }
    body .TableItemsFooter .PaginationItems .pagination {
      display: inline-flex; } }

.ShowPerPage {
  font-size: 0.9375rem;
  color: #808080;
  padding-right: 5px; }
  .ShowPerPage > span {
    min-width: 50px; }
  .ShowPerPage select.form-control {
    color: #333;
    margin: 0 9px; }

.SwitchLayout {
  margin-right: 20px;
  font-size: 1.5rem;
  color: #aaa; }
  .SwitchLayout .Option {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 31px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s; }
    .SwitchLayout .Option.active {
      background-color: #f56400;
      color: #fff; }
    .SwitchLayout .Option.List {
      margin-left: 2px; }
      .SwitchLayout .Option.List:before {
        position: absolute;
        content: "";
        width: 2px;
        height: 16px;
        background-color: #fff;
        left: 10px;
        top: 6px; }
      .SwitchLayout .Option.List.active:before {
        background-color: #f56400; }

@media (max-width: 1024px) {
  body .SwitchLayout {
    display: inline-block;
    margin-right: 28px; } }

@media all and (max-width: 480px) {
  body .SwitchLayout {
    display: none; } }

@media (max-width: 768px) {
  body .SearchItemsPage .TableItemsWrapper .ProductInfo > a {
    font-size: 1rem; } }

.FormCreateQuery .popover-body {
  padding: 20px; }
  .FormCreateQuery .popover-body form input {
    margin-bottom: 10px;
    padding: 5px 10px;
    line-height: 26px;
    height: auto; }
  .FormCreateQuery .popover-body form button {
    display: block;
    width: 100%; }

.ProductReviews {
  display: flex;
  align-items: center; }
  .ProductReviews .Icon {
    line-height: 1;
    position: relative;
    top: -1px;
    margin-right: 3px; }
  .ProductReviews .Star {
    font-size: 14px;
    line-height: 1; }
  .ProductReviews .Number {
    color: #595959;
    font-size: 12px;
    line-height: 1.6;
    font-weight: 300; }

.TrademarkPage {
  margin-bottom: 20px; }

.ItemTrademark {
  text-align: center; }
  .ItemTrademark:hover .Actions .Read {
    color: #333; }
  .ItemTrademark:hover .Actions .Refresh {
    color: #37af56; }
  .ItemTrademark:hover .Actions .Remove {
    color: #ff443e; }
  .ItemTrademark.completed .Text, .ItemTrademark.completed .Warnings {
    color: #37af56; }
  .ItemTrademark.completed.notSafe .Text, .ItemTrademark.completed.notSafe .Warnings {
    cursor: pointer;
    color: #ff443e; }
  .ItemTrademark.refreshing .Actions .Refresh {
    -webkit-animation: _spinner 1s linear infinite;
            animation: _spinner 1s linear infinite; }
  .ItemTrademark:nth-child(odd) {
    background-color: #fafafa; }
  .ItemTrademark .Text {
    text-transform: uppercase; }
  .ItemTrademark .Status {
    color: #808080; }
  .ItemTrademark .Updated {
    color: #808080; }
  .ItemTrademark .Actions {
    color: #eee; }
    .ItemTrademark .Actions .Read, .ItemTrademark .Actions .Refresh, .ItemTrademark .Actions .Remove {
      display: inline-block;
      cursor: pointer;
      padding: 0 10px;
      font-size: 20px;
      border: none;
      background-color: transparent;
      outline: none; }
      .ItemTrademark .Actions .Read:disabled, .ItemTrademark .Actions .Refresh:disabled, .ItemTrademark .Actions .Remove:disabled {
        color: #eee; }

.FormCreateTrademark {
  margin-right: 20px;
  display: inline-block;
  min-width: 295px; }
  .FormCreateTrademark input {
    border-radius: 5px;
    border: 1px solid #eee;
    font-size: 15px;
    padding: 6px 20px;
    line-height: 21px; }
    .FormCreateTrademark input::-webkit-input-placeholder {
      color: #aaa; }
    .FormCreateTrademark input::-ms-input-placeholder {
      color: #aaa; }
    .FormCreateTrademark input::placeholder {
      color: #aaa; }
  .FormCreateTrademark .Add {
    content: '';
    width: 39px;
    height: 100%;
    background-color: #03a9f4;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    outline: none; }
    .FormCreateTrademark .Add:disabled {
      background-color: #aaa; }
    .FormCreateTrademark .Add:before {
      content: '';
      display: inline-block;
      width: 100%;
      height: 100%;
      background: url("/assets/icons/add-tm.svg") no-repeat center center;
      z-index: 9; }
  .FormCreateTrademark form {
    max-width: 500px;
    position: relative; }

.TrademarkPage .ListTradeMarks {
  display: inline-block;
  font-size: 16px; }
  .TrademarkPage .ListTradeMarks .Header {
    text-align: center;
    font-weight: 500;
    color: #808080;
    background-color: #f2fbff;
    border-radius: 5px; }
    .TrademarkPage .ListTradeMarks .Header th {
      font-weight: 400; }
  .TrademarkPage .ListTradeMarks tbody td {
    padding: 12px 0 5px 0;
    border: none;
    border-top: 1px solid #eeeeee; }
  .TrademarkPage .ListTradeMarks tbody tr:hover {
    border: solid 1px red; }
    .TrademarkPage .ListTradeMarks tbody tr:hover td {
      border-top: none;
      border-bottom: none; }

.TrademarkPage .TrademarkList .Table {
  width: 100%;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 0;
  border-collapse: collapse; }
  .TrademarkPage .TrademarkList .Table .TableRow:hover {
    background-color: #eee; }
  .TrademarkPage .TrademarkList .Table .TableRow:first-child {
    height: 40px;
    line-height: 40px;
    background-color: #f2fbff; }
    .TrademarkPage .TrademarkList .Table .TableRow:first-child .TableCell:first-child {
      width: 60px;
      background-color: #d9f3ff; }
    .TrademarkPage .TrademarkList .Table .TableRow:first-child .TableCell.Keywords {
      width: 50%; }
  .TrademarkPage .TrademarkList .Table .TableRow .TableCell {
    border-style: dotted;
    border-color: #eee;
    border-width: 0 1px 0 1px; }
  .TrademarkPage .TrademarkList .Table .TableRow:not(:first-child) {
    height: 50px;
    line-height: 50px; }

@media all and (min-width: 1280px) {
  .TrademarkPage .ListTradeMarks .Header .Warnings {
    min-width: 110px; }
  .TrademarkPage .ListTradeMarks .Header .Order {
    min-width: 50px; }
  .TrademarkPage .ListTradeMarks .Header .Keyword {
    min-width: 309px; }
  .TrademarkPage .ListTradeMarks .Header .Status {
    min-width: 130px; }
  .TrademarkPage .ListTradeMarks .Header .Updated {
    min-width: 130px; }
  .TrademarkPage .ListTradeMarks .Header .Actions {
    min-width: 240px; } }

@media (max-width: 480px) {
  body .TrademarkPage .TMHeader {
    flex-wrap: wrap; }
  body .TrademarkPage .FormCreateTrademark {
    margin-bottom: 20px; }
  body .TrademarkPage .TrademarkList .Table {
    border: none; }
    body .TrademarkPage .TrademarkList .Table .TableRow {
      display: block !important;
      border-top: 4px solid #f56400;
      margin-bottom: 20px;
      height: auto !important;
      line-height: unset !important; }
      body .TrademarkPage .TrademarkList .Table .TableRow.TableHeading {
        display: none !important; }
      body .TrademarkPage .TrademarkList .Table .TableRow:not(.TableHeading) .TableCell:before {
        content: attr(data-label);
        color: #808080;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 500;
        margin-right: 10px;
        line-height: 1;
        position: absolute;
        left: 11px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      body .TrademarkPage .TrademarkList .Table .TableRow .TableCell {
        display: block !important;
        text-align: right;
        border-bottom: 1px solid #ddd;
        padding: 10px;
        font-size: 1rem;
        border-color: #ddd;
        position: relative; }
        body .TrademarkPage .TrademarkList .Table .TableRow .TableCell.Text {
          font-size: 14px; }
        body .TrademarkPage .TrademarkList .Table .TableRow .TableCell.Order {
          background-color: #d9f3ff; }
    body .TrademarkPage .TrademarkList .Table .TextView {
      display: block;
      padding-left: 75px; } }

.TMSortControl {
  margin-right: 20px; }
  .TMSortControl .Label {
    display: inline-block;
    margin-right: 10px;
    color: #808080; }
  .TMSortControl .ButtonSelect {
    border: 1px solid #eee;
    border-radius: 5px;
    font-size: 15px;
    line-height: 25px;
    color: #333;
    background-color: #fff;
    margin-right: 5px;
    outline: none;
    padding: 5px 12px;
    cursor: pointer; }
    .TMSortControl .ButtonSelect.Active {
      background-color: #4cbff5;
      color: #fff;
      border: none; }

@media all and (max-width: 480px) {
  .TMSortControl {
    margin-bottom: 20px; }
    .TMSortControl .ButtonSelect {
      padding: 5px 8px; }
      .TMSortControl .ButtonSelect:last-child {
        margin-right: 0; } }

.TMHeader {
  margin-bottom: 20px;
  align-items: center;
  font-size: 0.9375rem; }
  .TMHeader .Controls {
    margin-bottom: 20px; }

@media all and (max-width: 480px) {
  .TMHeader .TMStatistic {
    margin-left: 0 !important; } }

.TMStatistic {
  font-size: 15px;
  color: #808080; }

.TMResultsPreview .TMResultsHeader tr th {
  border: none; }

@media all and (max-width: 480px) {
  .TMResultsPreview table thead tr th:last-child, .TMResultsPreview table thead tr th:nth-child(4) {
    display: none; }
  .TMResultsPreview table tbody tr td:last-child, .TMResultsPreview table tbody tr td:nth-child(4) {
    display: none; } }

.TextView {
  margin: 0 .4rem; }
  .TextView .noTrade {
    color: #37af56; }
  .TextView span:last-child {
    margin-right: 0; }

.MyAnalytics .Title {
  margin: 1rem 0 2rem; }

.MyAnalytics .MyAnalytic {
  margin-bottom: 1rem; }
  .MyAnalytics .MyAnalytic .card-title {
    margin-bottom: 0;
    font-size: 16px; }
  .MyAnalytics .MyAnalytic .card-body > div {
    margin-bottom: 10px; }
    .MyAnalytics .MyAnalytic .card-body > div > strong {
      display: inline-block;
      min-width: 90px; }
  .MyAnalytics .MyAnalytic .card-footer .btn {
    margin-left: 10px; }
  .MyAnalytics .MyAnalytic .Term span:last-child {
    font-weight: 500; }
  .MyAnalytics .MyAnalytic .TagItem {
    display: inline-block;
    margin-right: 30px; }
    .MyAnalytics .MyAnalytic .TagItem .Label {
      display: block;
      font-size: 12px;
      color: #999;
      text-align: left; }

.ItemsStatistic {
  margin-top: 30px; }

.StatisticItemTypes.card {
  box-shadow: none;
  border: 1px solid #f4f4f4;
  overflow: hidden; }

.StatisticItemTypes .card-header {
  font-weight: 500;
  background-color: #f2fbff;
  border-bottom: none;
  padding: 0 20px;
  height: 60px;
  line-height: 60px; }

.StatisticOverview {
  position: relative; }
  .StatisticOverview .card {
    box-shadow: none;
    border: 1px solid #f4f4f4;
    overflow: hidden; }
  .StatisticOverview .card-body {
    padding: 0; }
  .StatisticOverview .ShowDays {
    padding: 0;
    justify-content: center; }
  .StatisticOverview .nav-tabs {
    background-color: #f2fbff;
    height: 60px;
    line-height: 60px;
    border-bottom: 0;
    margin-bottom: 30px; }
    .StatisticOverview .nav-tabs .nav-item .nav-link {
      padding: 0 20px;
      color: #999;
      font-weight: 500;
      cursor: pointer; }
      .StatisticOverview .nav-tabs .nav-item .nav-link.active {
        color: #333;
        border-width: 0 1px;
        border-color: #f4f4f4; }
  .StatisticOverview .card-footer {
    background-color: #fff;
    border-top: none;
    padding: 24px 0 40px; }

.StatisticProduct {
  margin-bottom: 40px; }

@media all and (max-width: 480px) {
  .StatisticOverview {
    margin-bottom: 30px; } }

.StatisticRankRanges.card {
  box-shadow: none;
  border: 1px solid #f4f4f4;
  overflow: hidden; }

.StatisticRankRanges .card-header {
  background-color: #f2fbff;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  font-weight: 500;
  border-bottom: none; }

.StatisticRankRanges table {
  min-height: 200px;
  margin-bottom: 0; }
  .StatisticRankRanges table tbody {
    font-size: 1rem;
    color: #808080; }
  .StatisticRankRanges table tr:nth-child(even) {
    background-color: #fafafa; }
  .StatisticRankRanges table td {
    padding: 16px 20px;
    border-top: none; }

@media all and (max-width: 480px) {
  .StatisticRankRanges {
    margin-bottom: 30px; } }

.StatisticUpdate .Count {
  color: #fff;
  margin-bottom: 47px; }
  .StatisticUpdate .Count .card {
    height: 100%;
    box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08); }
  .StatisticUpdate .Count .Box {
    margin-bottom: 15px; }
  .StatisticUpdate .Count .card-body {
    padding: 26px 30px 17px 30px; }
    .StatisticUpdate .Count .card-body .Label {
      position: relative;
      font-size: 1rem;
      margin-bottom: 3px;
      padding-left: 18px; }
      .StatisticUpdate .Count .card-body .Label:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 3px;
        height: 18px;
        background-color: #fff;
        opacity: .3; }
    .StatisticUpdate .Count .card-body .Number {
      font-size: 2.0625rem;
      font-weight: 700; }
    .StatisticUpdate .Count .card-body .Percent {
      font-size: 1rem;
      font-style: italic;
      font-weight: 400;
      display: block;
      margin-top: -5px; }
  .StatisticUpdate .Count .progress {
    background-color: #fff; }
  .StatisticUpdate .Count .Total.card {
    background-color: #2ecc71; }
  .StatisticUpdate .Count .Deleted.card {
    background-color: #e74c3c; }
  .StatisticUpdate .Count .Rank.card {
    background-color: #16a085; }
    .StatisticUpdate .Count .Rank.card .progress-bar {
      background-color: #26b291; }
  .StatisticUpdate .Count .Updated.card {
    background-color: #3498db; }
    .StatisticUpdate .Count .Updated.card .progress-bar {
      background-color: #2677b2; }
  .StatisticUpdate .Count .New.card {
    background-color: #2980b9; }
  .StatisticUpdate .Count .Added.card {
    background-color: #f58625; }

.StatisticUpdate .Number-small {
  font-size: 1rem;
  font-style: italic;
  margin-top: -5px; }

@media (max-width: 1440px) {
  .StatisticUpdate .Count .card-body {
    padding: 21px 10px 7px; }
    .StatisticUpdate .Count .card-body .Number {
      font-size: 1.75rem; } }

@media all and (max-width: 1280px) {
  .StatisticUpdate .Count .card-body .Number {
    font-size: 1.45rem; } }

.FeaturedItems.card {
  box-shadow: none;
  margin-bottom: 20px; }

.FeaturedItems .Title {
  background-color: #f2fbff;
  font-weight: 500;
  color: #333;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border: 1px solid #f2fbff;
  border-radius: 5px 5px 0 0; }

.FeaturedItems .Items {
  border-width: 0 1px;
  border-style: solid;
  border-color: #f4f4f4;
  padding: 0; }
  .FeaturedItems .Items .FeaturedItem {
    color: #333;
    text-decoration: none;
    padding: 20px;
    transition: background-color .3s; }
    .FeaturedItems .Items .FeaturedItem .ButtonAddFavorites .Text {
      display: none; }
    .FeaturedItems .Items .FeaturedItem:nth-child(even) {
      background-color: #fafafa; }
    .FeaturedItems .Items .FeaturedItem:hover {
      background-color: rgba(0, 0, 0, 0.075); }
      .FeaturedItems .Items .FeaturedItem:hover .ButtonAddFavorites {
        opacity: 1; }
    .FeaturedItems .Items .FeaturedItem:not(.isCropped) .Thumbnail .Image img {
      max-width: none;
      width: 185px;
      height: auto;
      position: relative;
      top: -34px;
      left: -48px; }
    .FeaturedItems .Items .FeaturedItem:not(.isCropped) .Thumbnail .Hover {
      width: 300px;
      height: 360px;
      overflow: hidden; }
      .FeaturedItems .Items .FeaturedItem:not(.isCropped) .Thumbnail .Hover img {
        height: auto;
        width: 659px;
        max-width: unset;
        position: relative;
        top: -105px;
        left: -172px; }
    .FeaturedItems .Items .FeaturedItem .Order {
      min-width: 50px; }
    .FeaturedItems .Items .FeaturedItem:last-child .Thumbnail .Hover {
      top: auto;
      bottom: 0; }
    .FeaturedItems .Items .FeaturedItem .Thumbnail {
      position: relative;
      display: inline-block; }
      .FeaturedItems .Items .FeaturedItem .Thumbnail .Image {
        width: 85px;
        height: 100px;
        background-color: #eee;
        overflow: hidden; }
      .FeaturedItems .Items .FeaturedItem .Thumbnail:hover .Hover {
        display: inline-block; }
      .FeaturedItems .Items .FeaturedItem .Thumbnail .Hover {
        display: none;
        position: absolute;
        top: 0;
        left: 80px;
        z-index: 9;
        width: 300px; }
        .FeaturedItems .Items .FeaturedItem .Thumbnail .Hover img {
          max-width: 100%;
          height: auto; }
      .FeaturedItems .Items .FeaturedItem .Thumbnail img {
        max-width: 100%;
        height: auto; }
    .FeaturedItems .Items .FeaturedItem .Detail {
      margin-left: 20px;
      color: #aaa;
      font-size: 0.9375rem;
      max-width: calc(100% - 100px); }
      .FeaturedItems .Items .FeaturedItem .Detail .Name {
        display: block;
        color: #03A9F4;
        font-size: 1rem;
        line-height: 1.5;
        margin-top: -6px;
        margin-bottom: 8px; }
      .FeaturedItems .Items .FeaturedItem .Detail a {
        color: #333; }
      .FeaturedItems .Items .FeaturedItem .Detail .Rank span {
        color: #333;
        margin-left: 10px; }
      .FeaturedItems .Items .FeaturedItem .Detail .Brand a {
        word-break: break-word;
        font-weight: 700;
        margin-left: 10px; }
    .FeaturedItems .Items .FeaturedItem.c-popsockets .Thumbnail .Image img {
      width: 85px;
      height: 85px;
      top: 7px;
      left: 0; }

.FeaturedItems .More {
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f4f4f4;
  border-radius: 0 0 5px 5px;
  height: 52px;
  line-height: 52px;
  padding-left: 20px; }
  .FeaturedItems .More a {
    padding: 0; }
    .FeaturedItems .More a:after {
      content: "\E944";
      font-family: LinearIcons, serif;
      margin-left: 9px; }
    .FeaturedItems .More a:hover:after {
      -webkit-animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
              animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      -webkit-perspective: 1000px;
              perspective: 1000px;
      display: inline-block; }

@media all and (max-width: 480px) {
  .FeaturedItems .Items .FeaturedItem .Thumbnail:hover .Hover {
    display: none; } }

.ItemsRandom.loading .Title .Reload .Icon {
  -webkit-animation: _spinner 1s linear infinite;
          animation: _spinner 1s linear infinite; }

.ItemsRandom .Title {
  position: relative; }
  .ItemsRandom .Title .Reload {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0; }
    .ItemsRandom .Title .Reload .Icon {
      display: inline-block;
      cursor: pointer; }

.ListFeaturedProduct {
  margin-bottom: 36px; }

.PreloadBox {
  width: 100%; }
  .PreloadBox .Item {
    width: 100%;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    background: #f6f7f9;
    background-image: linear-gradient(to right, #f6f7f9 0%, #e9ebee 20%, #f6f7f9 40%, #f6f7f9 100%);
    background-repeat: no-repeat;
    background-size: 800px auto;
    background-position: 100px 0; }
  .PreloadBox .Item:not(:last-child) {
    margin-bottom: 10px; }

.Countdown {
  margin: 10px auto;
  padding-bottom: 20px;
  position: relative;
  z-index: 2; }
  .Countdown .countdown-column {
    display: inline-block; }
    .Countdown .countdown-column-element {
      display: flex;
      flex-direction: column;
      margin: 0 20px; }
      .Countdown .countdown-column-element-number {
        font-size: 28px; }

.ViewMoreProducts {
  transition: all 200ms ease-out;
  position: relative;
  z-index: 2; }
  .ViewMoreProducts a {
    color: #333; }
    .ViewMoreProducts a:hover {
      text-decoration: none; }

.EventItem {
  margin-bottom: 30px;
  transition: all 200ms ease-out;
  position: relative; }
  .EventItem .FlipContainer {
    -webkit-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
    -ms-transform: perspective(1000px);
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d; }
    .EventItem .FlipContainer.open .Front {
      -webkit-transform: rotateY(180deg);
      transform: rotateY(180deg); }
    .EventItem .FlipContainer.open .Back {
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg); }
  .EventItem .Flipper {
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 0.6s;
    -moz-transition: 0.6s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
    height: 100%; }
  .EventItem .Front, .EventItem .Back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 0.6s;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: rotateY(0deg);
    -moz-transition: 0.6s;
    -moz-transform-style: preserve-3d;
    -moz-transform: rotateY(0deg);
    -o-transition: 0.6s;
    -o-transform-style: preserve-3d;
    -o-transform: rotateY(0deg);
    -ms-transition: 0.6s;
    -ms-transform-style: preserve-3d;
    -ms-transform: rotateY(0deg);
    transition: 0.6s;
    transform-style: preserve-3d;
    transform: rotateY(0deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .EventItem .Front {
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    background: lightgreen;
    z-index: 2; }
  .EventItem .Back {
    background: lightblue;
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg); }
  .EventItem.inactive {
    opacity: 0.5; }
    .EventItem.inactive:after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10; }
  .EventItem .Details {
    position: relative;
    border: 1px solid #eee;
    height: 100%;
    transition: all 200ms ease-out;
    /*&:before {
            background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.5) 80%) repeat-x;
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC111111', endColorstr='#00111111', GradientType=0);
            padding: 1em;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            z-index: 1;
        }*/ }
    .EventItem .Details:hover {
      box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06); }
    .EventItem .Details .Preview {
      height: 100%;
      position: relative;
      z-index: 2;
      background: transparent center center no-repeat;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      will-change: transform, opacity;
      background-size: cover;
      color: #fff;
      min-height: 300px;
      max-width: 100%; }
      .EventItem .Details .Preview:before {
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(17, 17, 17, 0.5) 80%) repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#CC111111', endColorstr='#00111111', GradientType=0);
        padding: 1em;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        z-index: 1; }
    .EventItem .Details .Front .Name {
      font-size: 35px;
      position: relative;
      z-index: 2;
      min-height: 100px;
      padding: 20px; }

@media screen and (max-width: 480px) {
  body .EventItem .Details {
    height: 300px;
    margin-bottom: 20px; } }

.EventDetails {
  position: relative;
  text-align: left; }
  .EventDetails .Close {
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 5px 12px;
    cursor: pointer;
    color: #ff443e;
    z-index: 2; }
  .EventDetails .Body {
    max-height: 100%;
    overflow: auto;
    padding: 30px;
    color: #333;
    font-size: 0.9375rem; }
    .EventDetails .Body .Name {
      font-size: 1.375rem;
      color: #f56400;
      margin-bottom: 20px; }
    .EventDetails .Body .Meta {
      margin-bottom: 10px; }
      .EventDetails .Body .Meta .Icon {
        display: inline-block;
        margin-right: 5px;
        min-width: 16px; }
    .EventDetails .Body .Description {
      color: #666; }

.EventActions {
  background-color: #fff;
  color: #333;
  position: relative;
  z-index: 10;
  padding: 10px 10px; }
  .EventActions .ShowDetails {
    cursor: pointer; }
  .EventActions .TimeLeft {
    position: absolute;
    top: -37px;
    right: 0;
    background-color: #444;
    color: #fff;
    padding: 5px 10px;
    font-size: 0.875rem;
    line-height: 27px; }
    .EventActions .TimeLeft .Icon {
      margin-right: 7px;
      position: relative;
      top: 1px; }
  .EventActions .Area .Icon {
    margin-right: 2px; }

.ProductItem {
  margin-bottom: 0;
  font-size: 1rem; }
  .ProductItem .Bottom .rank {
    padding-bottom: 3px; }
  .ProductItem .Bottom .Name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 22px;
    max-height: 44px;
    -webkit-line-clamp: 2;
    margin-bottom: 5px; }
  .ProductItem .Bottom .Brand .Title {
    color: #666; }
  .ProductItem .Bottom .Brand .Value a:first-child:after {
    display: none; }
  .ProductItem .Bottom .Brand .Value a:last-child {
    color: #333;
    margin-left: 5px;
    font-size: 0.875rem; }

.ProductPlaceholder .Thumbnail {
  background-size: 230%;
  background-position: center;
  height: 0;
  position: relative;
  padding-bottom: 120.45%; }

.ProductPlaceholder .Rank, .ProductPlaceholder .Meta {
  height: 15px; }

.ProductPlaceholder .Name {
  height: 30px; }

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.EventItemPlaceholder .Wrapper .Details .WrapperDetails {
  min-height: 100%; }
  .EventItemPlaceholder .Wrapper .Details .WrapperDetails .PreloadBox {
    min-height: 100%; }

.EventItemPlaceholder .Wrapper .Details .FeaturedImage {
  min-height: 100%;
  height: 370px; }

@media all and (max-width: 480px) {
  .EventItemPlaceholder .Wrapper .Details {
    margin-bottom: 20px; }
    .EventItemPlaceholder .Wrapper .Details .FeaturedImage {
      height: 200px; } }

.NicheEventsPage {
  margin-bottom: 50px; }
  .NicheEventsPage .PageTitle {
    font-size: 20px;
    color: #333;
    margin: 0; }

@media screen and (max-width: 480px) {
  body .NicheEventsPage .PageTitle {
    margin: 0;
    padding: 15px 0 10px 0; }
  body .NicheEventsPage .ProductItem {
    margin-bottom: 20px; } }

.ResizeImagesPage {
  background-color: #fff;
  min-height: 100vh; }
  .ResizeImagesPage .Guide {
    margin-bottom: 20px;
    white-space: pre-line; }
  .ResizeImagesPage .Main {
    position: relative;
    margin-bottom: 50px; }
    .ResizeImagesPage .Main .Iframes .Iframe {
      width: 100%;
      min-height: 202px;
      display: none; }
      .ResizeImagesPage .Main .Iframes .Iframe.active {
        display: block; }
  .ResizeImagesPage .Footer {
    white-space: pre-line; }

.ResizeTabs {
  margin-bottom: 20px; }
  .ResizeTabs .Navs {
    display: inline-block; }
    .ResizeTabs .Navs .nav-item a {
      cursor: pointer; }
      .ResizeTabs .Navs .nav-item a.active {
        cursor: auto; }
        .ResizeTabs .Navs .nav-item a.active:hover {
          color: #fff; }

.FavoriteProducts .TableItemsMeta {
  margin: 20px 0; }
  .FavoriteProducts .TableItemsMeta .TotalItems {
    font-size: 0.875rem;
    font-weight: 400;
    color: #444; }
  .FavoriteProducts .TableItemsMeta .Details {
    min-width: 50%; }
    .FavoriteProducts .TableItemsMeta .Details .Show .PageTitle {
      padding-bottom: 0; }
    .FavoriteProducts .TableItemsMeta .Details .Show .Edit {
      font-size: 1rem;
      cursor: pointer;
      margin-right: 5px; }
    .FavoriteProducts .TableItemsMeta .Details .Show .Description {
      color: #666;
      line-height: 28px;
      min-height: 28px;
      font-size: 0.875rem; }
    .FavoriteProducts .TableItemsMeta .Details .Editor .Title {
      margin-bottom: 10px; }
    .FavoriteProducts .TableItemsMeta .Details .Editor .Description {
      height: auto;
      resize: none;
      margin-bottom: 10px;
      font-size: 0.875rem; }
    .FavoriteProducts .TableItemsMeta .Details .Editor .Right button {
      padding-right: 0; }

@media all and (max-width: 480px) {
  .FavoriteProducts .TableItemsMeta {
    margin: 0; }
    .FavoriteProducts .TableItemsMeta .Details {
      flex: 1 1; }
      .FavoriteProducts .TableItemsMeta .Details .Editor {
        margin-top: 20px; } }

.ListProductsByCategory {
  display: flex;
  margin: 0 -5px 10px; }
  .ListProductsByCategory .Product {
    padding: 0 5px; }
    .ListProductsByCategory .Product.noBackground .Preview:after {
      content: '';
      display: inline-block;
      max-width: 100%;
      min-height: 100px; }
    .ListProductsByCategory .Product .Preview {
      background-repeat: no-repeat;
      background-color: #eee;
      background-position: center;
      background-size: cover;
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 100%; }
      .ListProductsByCategory .Product .Preview img {
        max-width: 100%;
        height: auto;
        opacity: 0; }
    .ListProductsByCategory .Product.c-popsockets .Preview {
      background-size: 100%; }

.FavoriteCategory {
  margin-bottom: 40px; }
  .FavoriteCategory .Wrapper {
    background-color: #fff;
    border: 1px solid #eee;
    padding: 20px 20px 15px;
    transition: all 200ms ease-out;
    cursor: pointer;
    display: block;
    color: #333;
    text-decoration: none;
    border-radius: 5px;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.01); }
    .FavoriteCategory .Wrapper:hover {
      box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06); }
    .FavoriteCategory .Wrapper .Header .Title {
      font-weight: 500;
      font-size: 1.2rem;
      color: #555; }
    .FavoriteCategory .Wrapper .Header .Meta {
      color: #b5b5b5;
      font-size: 0.875rem; }
      .FavoriteCategory .Wrapper .Header .Meta .Total {
        color: #555; }

.SimilarProducts h3 {
  margin-bottom: 20px; }

.SimilarProducts .Title .Stats {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  font-size: 0.875rem;
  padding: 5px 10px;
  position: relative;
  top: -4px; }
  .SimilarProducts .Title .Stats .Number {
    margin-right: 5px; }

.PopupSimilarProducts .modal-content .modal-body {
  position: relative;
  display: block;
  width: 100%; }
  .PopupSimilarProducts .modal-content .modal-body .Close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #aaa;
    transition: all 200ms ease-out;
    padding: 5px 10px; }
    .PopupSimilarProducts .modal-content .modal-body .Close:hover {
      color: #666; }

@media all and (min-width: 480px) {
  .PopupSimilarProducts.modal-dialog {
    max-width: 90%; } }

.SimilarProductsLink {
  cursor: pointer;
  color: #aaa;
  transition: all 200ms ease-out; }
  .SimilarProductsLink:hover {
    color: #666; }

.ListSimilarProducts ul {
  padding: 0;
  margin-bottom: 0; }

.PaginationSimilarProducts {
  text-align: center;
  margin-top: -15px; }
  .PaginationSimilarProducts .pagination {
    display: inline-flex; }
    .PaginationSimilarProducts .pagination .page-item.Previous {
      display: inline-block; }
    .PaginationSimilarProducts .pagination .page-item.Next {
      display: inline-block; }

.TrademarkPreviewResults .TMResultsHeader tr th {
  border: none; }

@media all and (max-width: 480px) {
  .TrademarkPreviewResults .TMResults tr td:nth-child(4), .TrademarkPreviewResults .TMResults tr td:nth-child(5), .TrademarkPreviewResults .TMResults tr th:nth-child(4), .TrademarkPreviewResults .TMResults tr th:nth-child(5) {
    display: none; } }

.SettingsPage {
  display: flex;
  flex-grow: 1;
  min-height: 100vh; }
  .SettingsPage .Wrapper {
    width: 870px;
    max-width: 100%;
    margin: auto; }

@media all and (max-width: 480px) {
  .SettingsPage {
    display: block; }
    .SettingsPage .SettingsMobileMenu .Menu {
      margin-top: 20px; }
    .SettingsPage .MainSettings {
      overflow: visible;
      padding: 20px 15px 50px;
      height: auto; }
      .SettingsPage .MainSettings .Wrapper {
        width: auto;
        max-width: 100%; } }

.SideBarSettings {
  width: 281px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #eee; }
  .SideBarSettings .TopSidebar {
    text-align: center; }
    .SideBarSettings .TopSidebar .Logo {
      display: block;
      background: url(/assets/images/logo.png) no-repeat center center;
      width: 145px;
      height: 80px;
      margin: 32px auto 48px;
      background-size: contain; }
    .SideBarSettings .TopSidebar .nav {
      margin: 0 20px; }
      .SideBarSettings .TopSidebar .nav .nav-item {
        height: 55px;
        line-height: 55px;
        transition: all .3s; }
        .SideBarSettings .TopSidebar .nav .nav-item:not(:last-child) {
          margin-bottom: 18px; }
        .SideBarSettings .TopSidebar .nav .nav-item .nav-link {
          position: relative;
          padding: 0; }
          .SideBarSettings .TopSidebar .nav .nav-item .nav-link:before {
            content: "\E90B";
            position: absolute;
            left: 45px;
            top: 0;
            font-family: LinearIcons;
            font-size: 1.875rem; }
          .SideBarSettings .TopSidebar .nav .nav-item .nav-link[href*="/settings/billing"]:before {
            content: "\E75E"; }
          .SideBarSettings .TopSidebar .nav .nav-item .nav-link[href*="/settings/profile"]:before {
            content: "\E71B"; }
          .SideBarSettings .TopSidebar .nav .nav-item .nav-link[href*="/settings/affiliate"]:before {
            content: "\E726"; }
          .SideBarSettings .TopSidebar .nav .nav-item .nav-link[href*="/settings/dashboard"]:before {
            content: "\E90B"; }
        .SideBarSettings .TopSidebar .nav .nav-item.active, .SideBarSettings .TopSidebar .nav .nav-item:hover {
          background-color: #f56400;
          border-radius: 5px;
          box-shadow: 4.2px 4.2px 16px 0 rgba(0, 0, 0, 0.08); }
          .SideBarSettings .TopSidebar .nav .nav-item.active .nav-link, .SideBarSettings .TopSidebar .nav .nav-item:hover .nav-link {
            color: #fff; }
  .SideBarSettings .UserMenu {
    padding-left: 30px;
    padding-bottom: 40px;
    /*.dropdown-menu{
            left: calc(100% - 8px) !important;
            transform: none !important;
            bottom: 14px !important;
            top: unset !important;
            border-radius: 5px;
            box-shadow: 4.2px 4.2px 18px 0 rgba(0, 0, 0, 0.06);
            border: solid 1px #eeeeee;
        }*/ }
    .SideBarSettings .UserMenu img.avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%; }
    .SideBarSettings .UserMenu .dropdown-toggle:after {
      border-top: 0.25em solid transparent;
      border-left: 0.3em solid #333;
      border-bottom: 0.25em solid transparent;
      vertical-align: baseline;
      margin-left: 8px; }
    .SideBarSettings .UserMenu .dropdown {
      cursor: pointer;
      display: inline-block; }
    .SideBarSettings .UserMenu .dropdown-menu {
      left: 100% !important;
      -webkit-transform: none !important;
              transform: none !important;
      top: unset !important;
      bottom: 10px !important;
      border-radius: 5px;
      box-shadow: 4.2px 4.2px 18px 0 rgba(0, 0, 0, 0.06);
      border: solid 1px #eeeeee; }

.MainSettings {
  flex-grow: 1;
  padding-top: 44px;
  height: 100vh; }

.BillingSettings .title {
  font-size: 1.625rem;
  text-align: center;
  margin-bottom: 60px; }

.BillingSettings .Title {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 19px; }

.BillingSettings .Message, .BillingSettings .CurrentPlan {
  font-size: 1rem;
  color: #808080;
  margin-bottom: 22px; }
  .BillingSettings .Message strong, .BillingSettings .CurrentPlan strong {
    color: #333;
    font-weight: 500; }

.BillingSettings .CurrentPlan > div:not(:last-child) {
  margin-bottom: 7px; }

.BillingSettings .BillingPlan {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 46px 40px; }
  .BillingSettings .BillingPlan .Guide {
    font-size: 1rem;
    color: #808080; }
  .BillingSettings .BillingPlan .ChangePlanWrapper {
    margin-bottom: 20px; }
    .BillingSettings .BillingPlan .ChangePlanWrapper .ChangePlan {
      font-size: 0.9375rem;
      padding: 8px 20px;
      background-color: #f56400; }
      .BillingSettings .BillingPlan .ChangePlanWrapper .ChangePlan:hover {
        background-color: #f56400;
        border-color: #f56400;
        box-shadow: 5.7px 5.7px 16px 0 rgba(0, 0, 0, 0.1); }

.BillingSettings .BillingHistory {
  margin-top: 46px; }
  .BillingSettings .BillingHistory .Title, .BillingSettings .BillingHistory .Message {
    padding-left: 40px; }
  .BillingSettings .BillingHistory .Histories {
    font-size: 1rem;
    border: 1px solid #eee;
    border-radius: 5px;
    overflow: hidden; }
    .BillingSettings .BillingHistory .Histories .Name .Text {
      margin-right: 10px; }
    .BillingSettings .BillingHistory .Histories .Name .Coupon {
      font-size: 0.8rem;
      font-weight: 500;
      background-color: #666;
      color: #fff;
      padding: 3px 10px;
      border-radius: 5px; }
    .BillingSettings .BillingHistory .Histories table {
      text-align: center;
      color: #808080;
      margin-bottom: 0; }
      .BillingSettings .BillingHistory .Histories table th, .BillingSettings .BillingHistory .Histories table td {
        border: unset;
        vertical-align: middle; }
      .BillingSettings .BillingHistory .Histories table th {
        font-weight: 500;
        color: #333;
        padding: 7px 12px 8px; }
      .BillingSettings .BillingHistory .Histories table td {
        padding: 13px; }
      .BillingSettings .BillingHistory .Histories table thead {
        background-color: #f2fbff; }
      .BillingSettings .BillingHistory .Histories table tbody > tr:nth-child(even) {
        background-color: #fafafa; }

@media all and (max-width: 480px) {
  .BillingSettings .title {
    margin-bottom: 30px; }
  .BillingSettings .BillingHistory .Histories table thead tr th:nth-child(1), .BillingSettings .BillingHistory .Histories table thead tr th:nth-child(3) {
    display: none; }
  .BillingSettings .BillingHistory .Histories table tbody tr td:first-child, .BillingSettings .BillingHistory .Histories table tbody tr td.Status {
    display: none; } }

.ProfileSettings .title {
  font-size: 1.625rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 60px; }

.ProfileSettings .Info {
  display: flex;
  width: 614px;
  max-width: 100%;
  margin: 0 auto 30px;
  justify-content: space-between;
  padding: 40px;
  box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #eee;
  border-radius: 5px; }

.ProfileSettings .Settings {
  width: 614px;
  max-width: 100%;
  margin: auto;
  padding: 40px;
  box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #eee;
  border-radius: 5px; }
  .ProfileSettings .Settings h3 {
    margin-bottom: 2rem; }
  .ProfileSettings .Settings form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }
  .ProfileSettings .Settings .Save {
    position: relative;
    height: 30px; }
    .ProfileSettings .Settings .Save button {
      position: absolute;
      right: 0; }
  .ProfileSettings .Settings .alert {
    font-size: 14px;
    color: #42b72a; }

.ProfileSettings .Basic .form-group label {
  font-size: 0.9375rem;
  color: #808080; }

.ProfileSettings .Basic .form-group input {
  width: 280px;
  height: 50px;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 20px; }

.ProfileSettings .Avatar {
  width: 200px;
  border-radius: 5px;
  overflow: hidden; }

@media all and (max-width: 480px) {
  .ProfileSettings .Info .Avatar {
    display: none; }
  .ProfileSettings .Info .Basic {
    width: 100%; }
    .ProfileSettings .Info .Basic .form-group {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .ProfileSettings .Info .Basic .form-group input {
        width: auto; }
  .ProfileSettings .Settings form {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .ProfileSettings .Settings form select {
      width: auto; } }

.ReferralLink {
  color: #A0A0A0;
  margin-bottom: 40px;
  font-size: 17px; }
  .ReferralLink .card {
    border: 2px solid #F1F1F1;
    border-radius: 5px;
    box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06); }
    .ReferralLink .card .card-body .card-title {
      color: #535353; }
    .ReferralLink .card .card-body .Sharing .card-title {
      color: #535353;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500; }
    .ReferralLink .card .card-body .Sharing .Link {
      border: 1px solid #dfdfdf;
      padding: 0.5rem 10px;
      line-height: 1.25;
      border-radius: 0.25rem;
      background-color: white; }
      .ReferralLink .card .card-body .Sharing .Link:after {
        content: '';
        display: inline-block; }
    .ReferralLink .card .card-body .Sharing .Copy {
      cursor: pointer; }
    .ReferralLink .card .card-body .Assets {
      margin-top: 20px;
      font-size: 0.9rem; }
      .ReferralLink .card .card-body .Assets p {
        margin-bottom: 0; }

.PendingPage .card {
  box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  border-radius: 5px;
  color: #A0A0A0; }
  .PendingPage .card .status {
    color: #535353;
    font-weight: bold; }

.ReferralStatistic {
  color: #A0A0A0;
  font-size: 17px;
  margin-bottom: 40px; }
  .ReferralStatistic .card-title {
    color: #000;
    color: initial; }
  .ReferralStatistic .card {
    box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
    border: 2px solid #F1F1F1; }
    .ReferralStatistic .card .table {
      margin: 0; }
      .ReferralStatistic .card .table .referral-row {
        border-bottom: 1px solid #eeeeee; }
        .ReferralStatistic .card .table .referral-row:last-child {
          border: none; }
        .ReferralStatistic .card .table .referral-row td {
          border-top: none; }
          .ReferralStatistic .card .table .referral-row td.row-title {
            color: #535353; }
          .ReferralStatistic .card .table .referral-row td:last-child {
            color: #333; }

.RejectedPage.card .card {
  box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  border-radius: 5px;
  color: #A0A0A0; }
  .RejectedPage.card .card .status {
    color: #dc3545;
    font-weight: bold; }

.JoinReferralProgramPage .card {
  box-shadow: 5.7px 5.7px 18px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #eeeeee;
  border-radius: 5px;
  margin-bottom: 40px; }
  .JoinReferralProgramPage .card .card-body .row .card-title {
    margin: 8px 0 8px; }
  .JoinReferralProgramPage .card .card-body .row .join-now .loader {
    display: inline-block;
    margin-right: 8px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    font-family: LinearIcons, serif; }
    .JoinReferralProgramPage .card .card-body .row .join-now .loader::before {
      box-sizing: border-box;
      content: "\E8D0"; }
  .JoinReferralProgramPage .card .card-body .row .join-now:hover {
    background-color: #03A9F4;
    border-color: #03A9F4;
    box-shadow: 5.7px 5.7px 16px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow .15s ease-in-out; }
  .JoinReferralProgramPage .card .card-body .error {
    color: #dc3545; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.PaypalEmail {
  margin-bottom: 40px; }
  .PaypalEmail .title {
    color: #535353; }
  .PaypalEmail .updateMessage {
    font-size: medium;
    margin-top: 10px; }
    .PaypalEmail .updateMessage.green-text {
      color: #5cb85c; }
    .PaypalEmail .updateMessage.red-text {
      color: #d9534f; }
  .PaypalEmail .row .payment-title {
    color: #535353;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500; }
  .PaypalEmail .row .loader {
    display: none;
    margin-right: 8px;
    -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
    text-align: center;
    line-height: 0;
    font-family: LinearIcons; }
    .PaypalEmail .row .loader.visible {
      display: inline-block; }

.ReferralLinkEditor .edit-canceler {
  font-family: LinearIcons, sans-serif; }

.ReferralLinkEditor .loader {
  display: none;
  margin-right: 8px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  text-align: center;
  line-height: 0;
  font-family: LinearIcons; }
  .ReferralLinkEditor .loader.visible {
    display: inline-block; }

.ReferralsPage .title {
  font-size: 1.625rem;
  text-align: center;
  margin-bottom: 60px; }

.PolicyPage {
  margin-bottom: 40px; }

.LicenceCode {
  padding: 16px 32px 32px;
  border-top: 1px solid #e4e4e7;
  background-color: #f4f4f5; }
  .LicenceCode .Welcome {
    margin-bottom: 16px; }
    .LicenceCode .Welcome span {
      color: #0f766e;
      text-decoration: underline; }
  .LicenceCode .LicenceCodeBody {
    background-color: #f4f4f500;
    display: flex; }
    .LicenceCode .LicenceCodeBody .title {
      flex: 1 1; }
      .LicenceCode .LicenceCodeBody .title h3 {
        font-size: 1.125rem; }
    .LicenceCode .LicenceCodeBody .CreateCode {
      background-color: #fff;
      flex: 2 1;
      border-radius: 6px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
      overflow: hidden; }
      .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeInput {
        padding: 24px; }
        .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeInput label {
          font-size: 14px;
          color: #3f3f46;
          line-height: 20px;
          margin: 0; }
        .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeInput input {
          width: 70%;
          border-radius: 6px;
          padding: 8px 12px;
          background-color: #fff;
          border: 1px solid #71717a;
          font-size: 16px;
          margin-top: 6px;
          display: block;
          outline: none; }
        .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeInput .RequiredText {
          font-size: 14px;
          color: #DC2626;
          margin-top: 8px;
          line-height: 20px; }
      .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeSubmit {
        padding: 12px 24px;
        text-align: right;
        background-color: #fafafa; }
        .LicenceCode .LicenceCodeBody .CreateCode .CreateCodeSubmit .BtnSubmit {
          padding: 8px 16px;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 1.6px;
          background-color: #27272a;
          text-transform: uppercase; }

.ManageLicence {
  padding: 32px;
  border-top: 1px solid #e4e4e7;
  background-color: #f4f4f5;
  display: flex; }
  .ManageLicence .left {
    flex: 1 1; }
    .ManageLicence .left .title {
      font-size: 18px;
      color: #18181B; }
    .ManageLicence .left .content {
      padding-right: 20px;
      margin-top: 4px;
      font-size: 14px;
      color: #52525B; }
  .ManageLicence .right {
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    flex: 2 1; }
    .ManageLicence .right .LicenseName {
      font-size: 16px;
      padding: 24px;
      display: flex;
      justify-content: space-between; }
      .ManageLicence .right .LicenseName .BtnDelete {
        font-size: 14px;
        cursor: pointer; }

.ModalLicenceCodeBody {
  padding: 16px 24px; }
  .ModalLicenceCodeBody .note {
    padding-top: 16px;
    font-size: 16px; }
  .ModalLicenceCodeBody .LicenseCode {
    font-size: 14px;
    color: #71717A;
    background-color: #f4f4f5;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .ModalLicenceCodeBody .LicenseCode .icon {
      cursor: pointer; }

.ModalLicenceCodeFooter {
  padding: 16px 24px;
  background-color: #F4F4F5;
  text-align: right; }

@media (min-width: 1200px) {
  .container {
    min-width: 100%; } }

.btn {
  cursor: pointer; }

.Fade {
  transition: all 200ms ease-out; }

.form-control:hover {
  border-color: #79d4fd; }

select {
  cursor: pointer; }

.card {
  border: none;
  border-radius: 5px;
  box-shadow: rgba(67, 90, 111, 0.416) 0 0 1px, rgba(67, 90, 111, 0.3) 0 2px 4px -2px; }

@-webkit-keyframes _spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes _spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes _buttonSpinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes _buttonSpinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.fa {
  font-weight: 400; }

select.form-control:not([size]):not([multiple]) {
  height: 35px !important; }

.form-control {
  border-color: #eee;
  font-size: 0.9375rem;
  height: 35px; }
  .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-style: italic;
    color: #bbb;
    font-weight: 300; }
  .form-control::-moz-placeholder {
    /* Firefox 19+ */
    font-style: italic;
    color: #bbb;
    font-weight: 300; }
  .form-control:-ms-input-placeholder {
    /* IE 10+ */
    font-style: italic;
    color: #bbb;
    font-weight: 300; }
  .form-control:-moz-placeholder {
    /* Firefox 18- */
    font-style: italic;
    color: #bbb;
    font-weight: 300; }
  .form-control:hover {
    border-color: #eee; }
  .form-control:focus {
    box-shadow: unset;
    border-color: #f56400; }

.popover {
  max-width: 100%;
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #eee; }

.popover-header {
  display: none; }

.popover-body {
  padding: 0;
  color: #333; }

.ButtonOptions {
  display: flex;
  list-style: none;
  font-size: 0.9375rem;
  margin: 0; }
  .ButtonOptions .Option {
    padding: 10px 10px;
    line-height: 1;
    border: 1px solid #eee;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff; }
    .ButtonOptions .Option:not(:last-child) {
      margin-right: 5px; }
    .ButtonOptions .Option:hover {
      background-color: #eee;
      border-color: #eee; }
    .ButtonOptions .Option.active {
      color: #fff;
      background-color: #f56400;
      border-color: #f56400; }

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 5px; }

::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5; }

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #c6c6c6; }

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%, 80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%, 60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@media all and (min-width: 480px) {
  #app {
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    overflow-x: auto;
    overflow-y: hidden;
    position: fixed;
    right: 0;
    top: 0; } }

.grecaptcha-badge {
  display: none !important; }

a {
  color: #f56400; }

.dropdown .dropdown-item.active, .dropdown .dropdown-item:active {
  color: #fff;
  background-color: #f56400; }

