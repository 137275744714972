.FilterBrandType {
    .SelectWrapper {
        height: 100%;

        select.form-control.selectBrandType {
            height: 100% !important;
        }
    }

    &.isActive {
        .SelectWrapper {
            background-color: $tamz_primary_color;
            border-radius: 5px;

            select {
                background-color: transparent;
                color: #fff;
                border: none;

                option {
                    color: #808080;
                }
            }
        }
    }
}