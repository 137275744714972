.SimilarProducts {
    h3 {
        margin-bottom: 20px;
    }

    .Title {
        .Stats {
            color: #fff;
            background-color: #212529;
            border-radius: .2rem;
            font-size: 0.875rem;
            padding: 5px 10px;
            position: relative;
            top: -4px;

            .Number {
                margin-right: 5px;
            }
        }
    }
}