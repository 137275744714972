.ResizeTabs {
    margin-bottom: 20px;

    .Navs {
        display: inline-block;

        .nav-item {
            a {
                cursor: pointer;

                &.active {
                    cursor: auto;

                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }
}