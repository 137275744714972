.ViewMoreProducts {
    transition: all 200ms ease-out;
    position: relative;
    z-index: 2;

    a {
        color: #333;

        &:hover {
            text-decoration: none;
        }
    }
}