.TMSortControl {
    margin-right: 20px;

    .Label {
        display: inline-block;
        margin-right: 10px;
        color: #808080;
    }

    .ButtonSelect {
        border: 1px solid #eee;
        border-radius: 5px;
        font-size: 15px;
        line-height: 25px;
        color: #333;
        background-color: #fff;
        margin-right: 5px;
        outline: none;
        padding: 5px 12px;
        cursor: pointer;

        &.Active {
            background-color: #4cbff5;
            color: #fff;
            border: none;
        }
    }
}

@media all and (max-width: 480px) {
    .TMSortControl {
        margin-bottom: 20px;

        .ButtonSelect {
            padding: 5px 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}