$primary-text-color: #303238;
$secondary-text-color: #65676b;
$third-text-color: #005bbb;
$white-text-color: #ffffff;

.Wrapper {
  .AdsAnnouncement {
    display: none;
  }
}
.CopyLicenseCode{
  position: relative;
  i{
    transition: all 0.2s ease;
  }
  &:hover{
    i{
   scale: 1.2;}
  }
  .CopiedNoti{
    padding:5px;
    position: absolute;
    top:-150%;
    left:-100%;
    height: fit-content;
    width: fit-content;
    border-radius: 5px;
    color:$third-text-color;
    display:none;
    &.Active{
      display:block;
    }
  }
}
.TableItemsFooter {
  margin-bottom: 40px;
}

.SearchItemsPage {
  height: unset !important;
  .ItemsExplorerHeader {
    position: sticky;
    top: 72px;
    background-color: white;
    padding-bottom: 10px;
    .Top {
      &::before {
        border-top: 20px solid white;
      }
    }
  }
  .TableListItems {
    padding-top: 0;
  }
}
.DashboardIcon {
  display: inline-block;
  position: relative;
}
.MobileMenu {
  .bm-menu-wrap {
    .bm-menu {
      .bm-item-list {
        .Menu {
          .nav-item {
            &:nth-child(1) {
              line-height: unset !important;
              span {
                line-height: 55px;
              }
              img {
                margin-top: -5px;
                margin-left: -50px;
              }
              .nav-link {
                .DashboardIcon {
                  &.Blue {
                    display: none;
                  }
                }
                &:hover {
                  .DashboardIcon {
                    &.Blue {
                      display: inline-block !important;
                    }
                    &.Orange {
                      display: none !important;
                    }
                  }
                }
              }
            }
            .Text.NewDashboard {
              margin-left: 25px;
            }
          }
        }
      }
    }
  }
}
.SidebarDashboard .nav .Text.NewDashboard {
  margin-left: 20px;
}
.SidebarDashboard .nav .nav-item {
  &:nth-child(2) {
    line-height: unset !important;
    span {
      line-height: 55px;
    }
    img {
      margin-top: -5px;
    }
    .nav-link {
      &:hover {
        .DashboardIcon {
          &:nth-child(1) {
            display: inline-block !important;
          }
          &:nth-child(2) {
            display: none !important;
          }
        }
      }
    }
  }
}
.NewHomePageHeader {
  position: sticky;
  top: 0;
  z-index: 99999;
  p {
    margin-bottom: 0;
  }
  padding: 0 24px;
  height: 72px;
  background-color: #f56400;
  .NewHomePageMenuWrap {
    gap: 40px;
    .NewHomePageMenuIcon {
      height: 100%;
      line-height: 72px;
      transform: rotate(180deg);
      cursor: pointer;
      transform: all 0.5s ease;
      &.Active {
        transform: rotate(0);
      }
    }
    .NewHomePageDeTails {
      gap: 30px;
      .NewHomePageDeTailsText {
        padding-top: 10px;
        p {
          margin: 0;
          color: $white-text-color;
          font-weight: 600;
          font-size: 14px;
        }
        span {
          color: #ffbcaf;
          font-size: 24px;
          font-weight: 600;
        }
      }
      .NewHomePageDeTailsBtn {
        background-color: #ffffff;
        width: fit-content;
        height: 32px;
        padding: 0 20px;
        line-height: 32px;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;
        background-color: rgb(255, 235, 198);
        border: none;
        transition: all 0.2s ease;
        &:hover {
          background-color: white;
          color: #005bbb;
        }
      }
    }
  }
  .NewHomePageUser {
    gap: 24px;
    color: $white-text-color;
    .AdsAnnouncement {
      .dropdown {
        .btn {
          .linear-alarm {
            color: white;
          }
        }
      }
    }
    .NewHomePageUserInfoWrap {
      position: relative;
      .NewHomePageUserInfo {
        cursor: pointer;
        gap: 10px;
        .NewHomePageAccount {
          gap: 10px;
          img {
            display: block;
            width: auto;
            height: 40px;
            object-fit: cover;
            border-radius: 50%;
          }
          .NewHomePageAccountName {
            p {
              margin: auto 0;
            }
          }
        }
      }
      .UserInfoMenu {
        display: none;
        height: fit-content;

        &.Active {
          display: block;
          position: absolute;
          top: calc(100% + 4px);
          width: 360px;
          right: 0;
          color: $primary-text-color;
          background-color: #ffffff;
          box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.08),
            0px 6px 16px rgba(0, 0, 0, 0.06),
            0px 9px 32px 8px rgba(0, 0, 0, 0.04);
          padding: 16px;
          border-radius: 8px;
          z-index: 999999;
          .UserInfoNameEmail {
            position: relative;
            h4 {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
            }
            .UserInfoEmail {
              display: flex;
              gap: 4px;
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $secondary-text-color;
              }
              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #f56400;
                text-decoration: underline;
                cursor: pointer;
                transition: all 0.2s ease;
                &:hover {
                  color: $third-text-color;
                }
              }
            }
            &::after {
              content: "";
              position: absolute;
              width: 100%;
              top: calc(100% + 16px);
              left: 0;
              height: 1px;
              background-color: #b0b7bf;
            }
          }
          .Subscription {
            margin-top: 32px;
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              p{
                margin-left: 20px;
                font-weight: 400;
                display: inline;
                color:$secondary-text-color
              }
              span {
                display: inline-block;
                padding: 0 8px;
                width: fit-content;
                height: 32px;
                text-align: center;
                line-height: 32px;
                font-weight: 500;
                font-size: 14px;
                color: #f56400;
                background-color: #e5f0fd;
              }
            }
            .Upgrade {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .TimeToRenews {
                p {
                  display: inline-block;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  color: $secondary-text-color;
                }
              }
              .UpgradePlanLink {
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 4px;

                &:hover {
                  .ImageUpgradeOne {
                    display: block;
                  }
                  .ImageUpgradeTwo {
                    display: none;
                  }
                  p {
                    color: #005bbb;
                  }
                }
                .ImageUpgradeOne {
                  display: none;
                }
                p {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: #f56400;
                }
                img {
                  width: 16px;
                  height: 16px;
                  object-fit: cover;
                }
              }
            }
            .TimeToRenewsProgress {
              margin-top: 8px;
              width: 100%;
              height: 8px;
              border-radius: 8px;
              background-color: #dee2e6;
              position: relative;
              .Progress {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: #f56400;
                border-radius: 8px;
              }
            }
            .UserInfoPageList {
              margin-top: 16px;
              .UserInfoPageItem {
                color: inherit;
                text-decoration: none;
                display: flex;
                margin-bottom: 16px;
                gap: 8px;
                align-items: center;
                transition: all 0.3s ease;
                &:hover {
                  color: #f56400;
                }
                &:nth-last-child(1) {
                  margin-bottom: unset;
                }
                img{width: 20px;
                height: 20px;}
                p {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }
          }
        }
      }
      .UserMenuOverlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 99999;
        &.Active {
          display: block;
        }
      }
    }
  }
}
.NewDashboard {
  height: 100%;

  .NewDashboardContainer {
    margin: 50px 24px;
    height: 100%;
    background-color: white;
    display: flex;

    gap: 50px;
    .LicenseCodeContainer {
      background-color: white;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      height: fit-content;
      border: 3px solid rgba(210, 105, 30, 0.719);
      border-radius: 10px;
      padding: 30px;
      .LicenseHeader {
        padding: 20px;
        padding-top: 50px;
        text-align: center;
        p {
          font-size: 20px;
          font-weight: 600;
        }
        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        .LicenseNoti {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          span {
            display: inline-block;
            color: $third-text-color;
            font-size: 24px;
            line-height: 40px;
            height: 100%;
            font-weight: 500;
            &.ColorRed{
              color:rgb(226, 23, 23);
            }
          }

        }
      }

      .NewLicenseCode {
        border-radius: 10px;
        .NewLicenseCodeBody {
          .CreateCode {
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            padding: 0 30px;
            .CreateCodeLeft {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              gap: 5px;
              flex: 1;
              .RequiredText{
                display:block;
                margin-top: 10px;
                color:red;
              }
              .CreateCodeInput {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .CreateCodeInputLabel {
                  display: flex;
                  label {
                    font-weight: 600;
                    margin-right: 5px;
                    margin-bottom: 0;
                    opacity: 0.8;
                  }
                  .MoreInfo {
                    position: relative;
                    margin: 0 5px;
                    img {
                      width: 20px;
                      height: 20px;
                      object-fit: cover;
                      opacity: 0.6;
                      cursor: pointer;
                    }
                    .MoreInfoPopUp {
                      display: none;
                      position: absolute;
                      bottom: 100%;
                      height: fit-content;
                      width: 220px;
                      right: 0;
                      font-size: 14px;
                      border: 2px solid #83d5f5;
                      padding: 5px 20px;
                      border-radius: 8px;
                      background-color: white;
                    }
                    &:hover {
                      .MoreInfoPopUp {
                        display: block;
                      }
                    }
                  }
                }
                .CreateCodeInputContentSubmit {
                  flex: 1;
                  display: flex;
                  gap: 5px;

                  .CreateCodeInputContent {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    input {
                      flex: 1;
                      outline: none;
                      border: none;
                      padding: 5px;
                      border-radius: 5px;
                      border: 2px solid rgba(0, 0, 0, 0.137);
                    }
                  }
                  .CreateCodeSubmit {
                    height: 100%;
                    button {
                      min-width: 100px;
                      height: 41px;
                      background-color: rgba(0, 0, 255, 0.726);
                      border: none;
                      opacity: 0.8;
                      font-weight: 500;
                      transition: all 0.2s ease;
                      &:hover {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
            .CreateMoreCode {
              width: 50px;
              height: 50px;
              line-height: 50px;
              text-align: center;

              border-radius: 5px 5px 0 0;
              position: relative;
              transition: all 0.2s ease;
              &:hover {
                background-color: rgba(0, 0, 0, 0.164);
                border-radius: 5px;
              }
              &.Active {
                background-color: rgba(0, 0, 0, 0.164);
              }
              img {
                width: 35px;
                height: 35px;
                object-fit: contain;
                cursor: pointer;
              }
            }
          }
          .ManageKeysWrap {
            position: relative;
            top: 100%;
            min-height: 400px;
            margin: 0 auto;
            margin-bottom: 60px;
            margin-top: 30px;
            border-radius: 10px 10px 10px 10px;
            background-color: #f4f4f5;
            transition: all 0.2s ease;
            padding: 0 10px;
            &.Active {
              margin-top: -10px;
              .ManageKeys {
                opacity: 1;
              }
            }
            .ManageKeys {
              padding: 20px;
              width: 100%;
              background-color: #f4f4f5;
               opacity: 0.6;
              &.Active {
                opacity: 1;
              }
              p {
                font-weight: 600;
                color: blue;
              }
              .CustomKey {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
                label {
                  margin-bottom: 0;
                  font-weight: 600;
                  opacity: 0.8;
                  display: block;
                }
                .CustomKeyInput {
                  flex: 1;
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  flex-wrap: wrap;

                  input {
                    flex: 1;
                    outline: none;
                    border: none;
                    padding: 5px;
                    border-radius: 5px;
                    border: 2px solid rgba(0, 0, 0, 0.137);
                  }
                }
                .CustomKeyBtn {
                  button {
                    min-width: 100px;
                    height: 40px;
                    background-color: #007bff;
                    border: none;
                    opacity: 0.6;
                    font-weight: 600;
                    transition: all 0.2s ease;
                    &:hover {
                      opacity: 1;
                    }
                  }
                }
              }
              .RequiredText{
                display:block;
                margin-top: 10px;
                color: rgb(255, 0, 0);
              }
              .CustomKeyList {
                padding: 50px 30px 30px 30px;
                .CustomKeyItem {
                  display: flex;
                  gap: 5px;
                  align-items: center;

                  img {
                    margin-top: 5px;
                    width: 20px;
                    height: 20px;
                  }
                  .CustomKeyInfoWrap {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .CustomKeyInfo {
                      display: -webkit-box;
                      -webkit-line-clamp: 1;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
                      text-overflow: ellipsis;

                      .CustomKeyName {
                        margin-top: 5px;
                        max-width: 100%;

                        font-size: 15px;
                        font-weight: 600;
                        opacity: 0.8;
                        color: #333;
                        margin-bottom: 0;

                        // p {
                        //   font-size: 16px;
                        //   font-weight: 600;
                        //   opacity: 0.8;
                        //   color: #333;
                        //   margin-bottom: 0;
                        // }
                      }
                      .CustomKeyTimeCreated {
                        p {
                          font-size: 14px;
                          font-weight: 600;
                          opacity: 0.6;
                          color: #333;
                          margin-bottom: 0;
                        }
                      }
                    }
                    .CustomKeyInfoDelete {
                      display: flex;
                      align-items: center;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
            .ManageKeysOverlay {
              position: absolute;
              bottom: 0;
              right: 0;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.315);
              border-radius: 10px 10px 10px 10px;
              .ManageKeysOverlayContent {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                padding: 20px 40px;
                background-color: white;
                text-align: center;
                border-radius: 10px;
                p {
                  margin-bottom: 0;
                  font-weight: 500;
                }
                a {
                  text-decoration: underline;
                  font-weight: 500;
                  &:hover {
                    color: blue;
                  }
                }
              }
            }
          }
        }
      }
      .ManageLicence {
        width: 70%;
        margin: 0 auto;
        border: none;
        border-radius: 10px;
        .right {
          .LicenseName {
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
      .LicenseGuide {
        margin: 0 auto;

        color: white;
        position: relative;
        border-radius: 10px;

        margin-top: 50px;

        input {
          display: none;
          &:checked ~ .LicenseGuideHeader {
            border-radius: 10px 10px 0 0;
            border: 4px solid gray;
            padding: 0 20px;
            label {
              .plus {
                display: none;
              }
              .minus {
                display: block;
              }
            }
          }
          &:checked ~ .LicenseGuideContent {
            display: block;
          }
        }
        .LicenseGuideHeader {
          background-color: rgba(0, 0, 255, 0.5);
          height: 60px;
          line-height: 60px;
          padding: 0 24px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          z-index: 999;
          label {
            margin-bottom: 0;
            .minus {
              display: none;
            }
          }
          img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            cursor: pointer;
          }
          span {
            margin-left: 20px;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-weight: 600;
            color: white;
            b {
              color: orange;
            }
          }
        }
        .LicenseGuideContent {
          display: none;
          position: relative;
          z-index: 1;
          padding: 20px;

          background-color: rgba(0, 0, 255, 0.664);

          font-size: 14px;
          font-weight: 600;
          border-radius: 0 0 10px 10px;
          p {
            line-height: 20px;
            a {
              color: inherit;
              text-decoration: underline;
              &:hover {
                color: orange;
              }
            }
          }

          img {
            width: 15px;
            height: 15px;
            object-fit: cover;
          }
        }
      }
    }
    .TrialDownload {
      width: 40%;
      padding: 50px;
      a {
        color: #333;
        text-decoration: underline;
        font-size: 24px;
        font-weight: 500;
        transition: all 0.2s ease;
        &:hover {
          color: #f56400;
        }
      }
      p {
        margin-top: 30px;
        opacity: 0.6;
        font-weight: 500;
        transition: all 0.2s ease;
        &:hover {
          opacity: 1;
        }
      }
      h3 {
        margin-top: 100px;
        font-size: 24px;
        font-weight: 500;
      }
      .DownloadList {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        .DownloadItem {
          opacity: 0.4;
          cursor: not-allowed;
          transition: all 0.2s ease;
          &:nth-child(1) {
            opacity: 1;
            cursor: pointer;
            &:hover {
              scale: 1.1;
            }
          }

          img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}

@media (max-width: 479px) {
  .NewHomePageHeader {
    display: none !important;
  }
  .Wrapper {
    .AdsAnnouncement {
      display: block;
    }
  }
}

@media (max-width: 990px) {
  .NewHomePageHeader {
    .NewHomePageMenuWrap {
      .NewHomePageDeTails {
        display: none !important;
      }
    }
  }
  .NewDashboard {
    .NewDashboardContainer {
      flex-wrap: wrap;
      .LicenseCodeContainer {
        order: 2;
        width: 100%;
        .NewLicenseCode {
          .NewLicenseCodeBody {
            .CreateCode {
              .CreateCodeLeft {
                .CreateCodeInput {
                  .CreateCodeInputContentSubmit {
                    padding: 0;
                    flex-wrap: wrap;
                  }
                }
              }
            }
          }
        }
      }
      .TrialDownload {
        width: 100%;
        order: 1;
      }
    }
  }
}
@media (max-width: 777px) {
  .NewDashboard {
    .NewDashboardContainer {
      .LicenseCodeContainer {
        .NewLicenseCode {
          .NewLicenseCodeBody {
            .CreateCode {
              .CreateCodeLeft {
                .CreateCodeInput {
                  flex-direction: column;
                  align-items: flex-start;
                  .CreateCodeInputContentSubmit {
                    width: 100%;
                    justify-content: center;
                  }
                }
              }
            }
            .ManageKeysWrap {
              .ManageKeys {
                .CustomKey {
                  flex-direction: column;
                  align-items: flex-start;
                  .CustomKeyInput {
                    width: 100%;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
