.SearchType {
    border-radius: 5px 0 0 5px;
}

.SelectWrapper {
    background-color: #fafafa;
    margin-right: -2px;
    outline: none;

    select.SearchType {
        background-color: transparent;
        &:focus{
            border-color: #eee;
        }
    }
}