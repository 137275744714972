.LoadingComponent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    display: none;
    opacity: 0;
    transition: all 200ms ease-out;
    z-index: 9;

    .Middle {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }

    &.loading {
        display: block;
        opacity: 1;
    }
}