@media (min-width: 1200px) {
    .container {
        min-width: 100%;
    }
}

.btn {
    cursor: pointer;
}

.Fade {
    transition: all 200ms ease-out;
}

.form-control {
    &:hover {
        border-color: $input-focus-border-color;
    }
}

select {
    cursor: pointer;
}

.card {
    border: none;
    border-radius: 5px;
    box-shadow: rgba(67, 90, 111, 0.416) 0 0 1px, rgba(67, 90, 111, 0.3) 0 2px 4px -2px;
}

@keyframes _spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes _buttonSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.fa {
    font-weight: 400;
}

select.form-control:not([size]):not([multiple]) {
    height: 35px !important;
}

.form-control {
    border-color: #eee;
    font-size: 0.9375rem;
    height: 35px;

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        font-style: italic;
        color: #bbb;
        font-weight: 300;
    }
    &::-moz-placeholder { /* Firefox 19+ */
        font-style: italic;
        color: #bbb;
        font-weight: 300;
    }
    &:-ms-input-placeholder { /* IE 10+ */
        font-style: italic;
        color: #bbb;
        font-weight: 300;
    }
    &:-moz-placeholder { /* Firefox 18- */
        font-style: italic;
        color: #bbb;
        font-weight: 300;
    }

    &:hover {
        border-color: #eee;
    }

    &:focus {
        box-shadow: unset;
        border-color: $tamz_primary_color;
    }
}

// Popover
.popover {
    max-width: 100%;
    box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.06);
    border: 1px solid #eee;
}

.popover-header {
    display: none;
}

.popover-body {
    padding: 0;
    color: $tamz_font_color;
}

.ButtonOptions {
    display: flex;
    list-style: none;
    font-size: 0.9375rem;
    margin: 0;

    .Option {
        padding: 10px 10px;
        line-height: 1;
        border: 1px solid #eee;
        border-radius: 5px;
        cursor: pointer;
        background-color: #fff;

        &:not(:last-child) {
            margin-right: 5px;
        }

        &:hover {
            background-color: #eee;
            border-color: #eee;
        }

        &.active {
            color: #fff;
            background-color: $tamz_primary_color;
            border-color: $tamz_primary_color;
        }
    }
}

// Style Scrollbar - only work on Chrome
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 5px;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #c6c6c6;
}

// Animation
@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

@media all and (min-width: 480px) {
    #app {
        display: flex;
        flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        bottom: 0;
        left: 0;
        overflow-x: auto;
        overflow-y: hidden;
        position: fixed;
        right: 0;
        top: 0;
    }
}

.grecaptcha-badge {
    display: none !important;
}

a {
    color: $tamz_primary_color
}

.dropdown {
    .dropdown-item.active, .dropdown-item:active {
        color: #fff;
        background-color: $tamz_primary_color;
    }
}
