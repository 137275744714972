@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import url('https://use.fontawesome.com/releases/v5.3.1/css/all.css');
@import url('/assets/css/ionicons.css');
@import url('/assets/css/linearicons.css');

body {
    font-family: $tamz_font_family;
    color: $tamz_font_color;
    font-weight: 400;
    font-size: 1.125rem;
    padding-bottom: 0 !important;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

.overflow {
    overflow: auto;
}

.btn-primary {
    border-color: rgba($tamz_primary_color, .8);
    background-color: rgba($tamz_primary_color, .8);

    &:not(:disabled):not(.disabled) {
        &:active, .active {
            border-color: $tamz_primary_color;
            background-color: $tamz_primary_color;
        }

        &:active:focus {
            box-shadow: 0 0 0 0.2rem rgba($tamz_primary_color, .5);
        }
    }

    &:hover, &:focus, &:active {
        border-color: $tamz_primary_color;
        background-color: $tamz_primary_color;
    }

    &:disabled {
        border-color: rgba($tamz_primary_color, .5);
        background-color: rgba($tamz_primary_color, .5);
    }
}