.LoginPage {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;

    &.noError {
        .Header {
            margin-bottom: 30px;
        }
    }

    .Background {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -10;
        background: url("/assets/demos/screen-desktop.jpg") top center no-repeat;
        background-size: cover;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 2;
        }

        video {
            min-width: 100%;
            min-height: 100%;
            width: 100%;
            height: auto;
            position: relative;
            z-index: 1;
        }
    }

    .WrapperBtnSocial {
        .ButtonSocial {
            position: relative;
            color: #fff;
            border-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;

            &:before {
                font-family: Font Awesome\ 5 Brands, serif;
                position: absolute;
                left: 0;
                top: 8px;
                width: 36px;
                height: 25px;
                line-height: 25px;
                font-size: 0.9375rem;
                border-right: 1px solid rgba(255, 255, 255, .1);
            }
        }

        .facebook {
            background-color: #3b5998;

            &:before {
                content: "\f39e";
            }
        }

        .google {
            background-color: #dd4b39;

            &:before {
                content: "\f1a0";
            }
        }
    }

    .WrapperBtnFb{
        border-top: 1px solid #eee;
        width: 60%;
        margin: 20px auto;
        padding-top: 20px;
        a {
            display: inline-block;
            color: #03A9F4;
            font-size: 16px;
            &:not(:last-child) {
                margin-right: 40px;
            }
        }
    }

    .Wrapper {
        width: 610px;
        max-width: 100%;
        text-align: center;
        margin: auto;
        transition: 200ms all ease-out;
        position: relative;
    }

    .loginTool{
        border: 1px solid #eee;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 5px;
    }

    .Header {
        font-size: 1.625rem;
        font-weight: 400;
        margin-bottom: 20px;
        padding-top: 30px;
    }

    .LoginMethod {
        padding-bottom: 40px;
        border-radius: 5px;

        .Error {
            font-size: 0.875rem;
            color: #ff443e;
            background-color: rgba(255, 68, 62, 0.2);
            padding: 10px 20px;
            margin-bottom: 20px;
        }

        .Description {
            font-size: 1rem;
            color: #808080;
        }

        .WrapperBtnSocial {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .ButtonSocial {
                flex: 0 0 164px;
                margin-top: 0;
                font-size: 0.9375rem;
                padding: 9px;
                border: unset;
                border-radius: 5px;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    .WrapperOtherTools{
        width: 60%;
        margin: 20px auto;
        padding-top: 20px;
        text-align: center;

        .tool-item{
            display: inline-block;
            margin: 0 15px;
            width: 120px;

            img{
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 480px) {
    .LoginPage {
        .Background {
            background: url("/assets/demos/screen-mobile.jpg") top center no-repeat;
        }

        .Wrapper {
            .LoginMethod {
                .WrapperBtnSocial {
                    display: block;
                    text-align: center;
                    margin: 0 30px;

                    .ButtonSocial {
                        margin-right: 0;
                        margin-bottom: 10px;
                        display: inline-block;
                        max-width: 200px;
                    }
                }
                .WrapperBtnFb {
                    margin: 0 auto;
                    a {
                        &:not(:last-child){
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}